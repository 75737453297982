const baseOnboarding = '/onboarding/';
const baseProfile = '/profile/';

export const loggedOutSubroutesPaths = {
    login: '/login',
    createAccount: '/create-account',
    openMyAccount: '/sign-up',
    emailSent: '/email-sent',
    forgotPassword: '/forgot-password',
    replacePassword: '/change-password',
    generatePassword: '/generate-password',
};

export const onboardingRoutesPaths = {
    home: `${baseOnboarding}home`,
    blog: '/blog',
    validatePerson: `${baseOnboarding}validate-person`,
    personalData: `${baseOnboarding}personal-data`,
    contactInfo: `${baseOnboarding}contact-info`,
    complementarydata: `${baseOnboarding}complementary-data`,
    uploadDocument: `${baseOnboarding}upload-document`,
    investmentTestQuestions: `${baseOnboarding}investment-test/questions`,
    investmentTestResult: `${baseOnboarding}investment-test/result`,
    proofOfIncome: `${baseOnboarding}proof-of-income`,
    contract: `${baseOnboarding}contract`,
    profile: `${baseProfile}`,
    deleteAccount: `${baseProfile}delete-account`,
    replacePassword: `${baseProfile}replace-password`,
};

export const mainRoutesPaths = {
    home: '/home',
    homeRecommendedWallet: '/home#recommended-wallet',
    briefcase: '/wallet/briefcase',
    deposit: '/wallet/deposit',
    withdrawal: '/wallet/withdrawal',
    equity: '/market/equity',
    bonds: '/market/bonds',
    funds: '/market/funds',
    cedears: '/market/cedears',
    preferred: '/market/preferred',
    others: '/market/others',
    instrumentDetail: '/market/instrument/:ticker',
    fundDetail: '/market/fund/:ticker',
    orders: '/operations/orders',
    referralFciRequest: '/operations/referral-fci-request',
    treasury: '/treasury',
    commercial: '/commercial',
    reports: '/reports',
    consolidatedAccount: '/reports/consolidated-account',
    receiptsInvoices: '/reports/receipts-and-invoices',
    tickets: '/reports/tickets',
    holding: '/reports/historical-holding',
    blog: '/blog',
    profile: `${baseProfile}`,
    profilePersonalData: `${baseProfile}personal-data`,
    bankAccounts: `${baseProfile}bank-accounts`,
    investmentTest: `${baseProfile}investment-test`,
    investmentTestQuestions: `${baseProfile}investment-test/questions`,
    investmentTestResult: `${baseProfile}investment-test/result`,
    testExpired: `${baseProfile}test-expired`,
    preferences: `${baseProfile}preferences`,
    deleteAccount: `${baseProfile}delete-account`,
    replacePassword: `${baseProfile}replace-password`,
};

export const channelRoutesPaths = {
    home: '/home',
    clients: '/clients',
    wallet: '/clients/wallet',
    equity: '/market/equity',
    bonds: '/market/bonds',
    funds: '/market/funds',
    cedears: '/market/cedears',
    preferred: '/market/preferred',
    exchange: '/market/exchange',
    collaterals: '/market/collaterals',
    instrumentDetail: '/market/instrument/:ticker',
    fundDetail: '/market/fund/:ticker',
    orders: '/operations/orders',
    referralFciRequest: '/operations/referral-fci-request',
    treasuryOrders: '/treasury/cashflow',
    treasuryBankAccounts: '/treasury/bank-accounts',
    commercialMaterial: '/commercial/material',
    commercialCorporate: '/commercial/corporate',
    commercialInternational: '/commercial/international',
    commercialDocumentation: '/commercial/documentation',
    commercialDetail: '/commercial/:id',
    consolidatedAccount: '/reports/consolidated-account',
    receiptsInvoices: '/reports/receipts-and-invoices',
    tickets: '/reports/tickets',
    historicalHolding: '/reports/historical-holding',
    reportsCollaterals: '/reports-clients/collaterals',
    dailyReports: '/reports-clients/daily',
    coinPosition: '/reports-clients/coin-position',
    blog: '/blog',
    profile: `${baseProfile}`,
    profilePersonalData: `${baseProfile}personal-data`,
    bankAccounts: `${baseProfile}bank-accounts`,
    investmentTest: `${baseProfile}investment-test`,
    deleteAccount: `${baseProfile}delete-account`,
    replacePassword: `${baseProfile}replace-password`,
};

export const publicSubroutesPaths = {
    help: '/help',
    noPermissions: '/no-permissions',
};
