import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import HelmetLayout from '../HelmetLayout';
import { Outlet } from 'react-router-dom';
import HeadLogo from 'components/common/HeadLogo';
import NavBarLayout from '../NavBarLayout';
import { SubRouteInfo } from 'utils/helpers/routesHelper';
import St from './OnboardingLayout.styled';
import OnboardingContextProvider from 'context/onboarding.context';
import FooterLayout from '../FooterLayout';
import HelpTooltip from 'components/common/HelpTooltip';
import MobileLayout from '../MobileLayout';
import { AletsObj } from 'types/api/alerts.types';
import AlertsNotificationModal from 'components/common/AlertNotificationsModal';

const OnboardingLayout = (props: Props) => {
    const {
        enableNavbar,
        routes,
        isMobile,
        alertNotification,
        setOpenAlertModal,
        openAlertModal,
        isLoadingAlert,
        errorMessageAlert,
    } = props;
    return (
        <OnboardingContextProvider>
            <HelmetLayout withOutlet={false} />
            <St.MainWrapper component="main">
                {enableNavbar &&
                    (isMobile ? (
                        <MobileLayout routes={routes} />
                    ) : (
                        <NavBarLayout routes={routes} />
                    ))}
                <St.ContentWrapper component="section">
                    {!enableNavbar && <HeadLogo />}
                    <St.PageWrapper>
                        {alertNotification && alertNotification?.length > 0 ? (
                            <AlertsNotificationModal
                                open={openAlertModal}
                                setOpenModal={setOpenAlertModal}
                                filteredAlert={alertNotification}
                                errorMessage={errorMessageAlert}
                                isLoading={isLoadingAlert}
                            />
                        ) : (
                            <></>
                        )}
                        <Outlet />
                    </St.PageWrapper>
                </St.ContentWrapper>
                <FooterLayout />
                <HelpTooltip />
            </St.MainWrapper>
        </OnboardingContextProvider>
    );
};

const propTypes = {};

interface extraProps {
    isMobile: boolean;
    enableNavbar: boolean;
    routes: Array<SubRouteInfo>;
    setOpenAlertModal: (x: boolean) => void;
    openAlertModal: boolean;
    alertNotification: AletsObj[] | undefined;
    errorMessageAlert: string | null;
    isLoadingAlert: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OnboardingLayout.propTypes = propTypes;

export default OnboardingLayout;
