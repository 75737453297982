import i18n from 'utils/i18n';

export const INSTRUMENTS_BASE_URL = '/instruments/v1_0';
export const ORDERS_BASE_URL = '/orders/v1_0';
export const MARKET_ACCOUNT_BASE_URL = '/market-account/v1_0';
export const DATES_BASE_URL = '/dates/v1_0';
export const DECLARATIONS_BASE_URL = '/declarations/v1_0';
export const RULES_BASE_URL = '/rules/v1_0';

export const tCommon = { ns: 'common' };
export const tCommonGrid = { ns: 'commonGrid' };
export const tErrorsContext = { ns: 'errors' };
export const tRequiredFieldError = i18n.t('required_field', { ns: 'errors' });
export const FACEBOOK_ANCHOR = 'https://www.facebook.com/consultatiofs';
export const INSTAGRAM_ANCHOR = 'https://www.instagram.com/consultatio_plus/';
export const LINKEDIN_ANCHOR = 'https://ar.linkedin.com/company/consultatioplus';
export const X_ANCHOR = 'https://x.com/ConsultatioFS';
export const WHATSAPP_ANCHOR = 'https://api.whatsapp.com/send?phone=5491136150264';
export const CNV_ANCHOR = 'https://www.argentina.gob.ar/cnv';
export const CLICK_HERE_ANCHOR = 'http://www.cnv.gov.ar/sitioweb/registrospublicos/agentes';
export const FATCA_ANCHOR = 'https://consultatioinvestments.com/fatca';
export const PEP_ANCHOR =
    'https://api.consultatiofinancialservices.com/wp-content/uploads/2023/08/PEP-Nomina-actualizada.pdf';
export const OBLIGATED_SUBJECT_ANCHOR =
    'https://api.consultatiofinancialservices.com/wp-content/uploads/2023/08/Sujetos-Obligados-actualizada.pdf';
export const FEE_ANCHOR = 'https://consultatioinvestments.com/honorarios';
export const CODE_OF_CONDUCT_ANCHOR =
    'https://api.consultatiofinancialservices.com/wp-content/uploads/2018/09/CódigodeConductaconPLDCISA.pdf';
export const TERMS_AND_CONDITIONS_ANCHOR =
    'https://consultatioinvestments.com/terminos-y-condiciones-de-uso-del-sitio-web';
export const PRIVACY_POLICY_ANCHOR = 'https://consultatioinvestments.com/politica-de-privacidad';
export const APP_STORE_ANCHOR =
    'https://apps.apple.com/ar/app/consultatio-investments/id1276735441?l=es&ls=1';
export const PLAY_STORE_ANCHOR =
    'https://play.google.com/store/apps/details?id=com.consultatio.plus';

export const HELP_EDUCATION_ANCHOR = 'https://consultatioinvestments.com/educacion';
export const HELP_FAQ_ANCHOR = 'https://consultatioinvestments.com/educacion/preguntas-frecuentes';
export const HELP_GLOSSARY_ANCHOR = 'https://consultatioinvestments.com/educacion/glosario';
export const HELP_SEND_EMAIL_ANCHOR =
    'mailto:asesores@consultatioinvestments.com?subject=Consulta vía Consultatio Investments WEB';
export const HELP_CHAT_WITH_US_ANCHOR = 'https://api.whatsapp.com/send?phone=5491136150264';
export const BLOCKED_TICKETS = ['CSHVO'];

export const TERMS = [
    { id: 1, name: '24hs' },
    { id: 2, name: 'CI' },
];

export const CURRENCIES = [
    { id: 1, name: 'ARS' },
    { id: 2, name: 'USD' },
    { id: 3, name: 'USD-C' },
];

export const CONSULTATIO_ACCOUNTS = [
    { id: 1, name: '3220001805005529160012 BANCO INDUSTRIAL S.A. (ARS)' },
    { id: 2, name: '3220001812005529160023 BANCO INDUSTRIAL S.A. (USD)' },
];

export const CONSULTATIO_SOURCE_ACCOUNTS = [
    { id: 1, name: '1980001730000001197915 BANCO DE VALORES S.A. (ARS)' },
    { id: 2, name: '1980001790000001197771 BANCO DE VALORES S.A. (USD)' },
    { id: 3, name: '2990000000002056890008 BANCO COMAFI S.A. (ARS)' },
    { id: 4, name: '2990000000014630710217 BANCO COMAFI S.A. (USD)' },
    { id: 5, name: '0170493220000000123002 BANCO BBVA ARGENTINA S.A. (ARS)' },
    { id: 6, name: '3220001805005529160012 BANCO INDUSTRIAL S.A. (ARS)' },
    { id: 7, name: '3220001812005529160023 BANCO INDUSTRIAL S.A. (USD)' },
    { id: 8, name: '2850302630094159651151 BANCO MACRO S.A. (ARS)' },
];

// helpers
export const ROWS_PER_PAGE = 10;
export const ICON_CELL_SIZE = { width: 15, height: 15 };

// possible order states
export const PENDING = 'PENDING';
export const SENT = 'SENT';
export const PARTIALLY_FILLED = 'PARTIALLY_FILLED';
export const COMPLETED = 'COMPLETED';
export const APPROVED_API = 'APPROVED_API';
export const APPROVED_MANUALLY = 'APPROVED_MANUALLY';
export const CONFIRMED = 'CONFIRMED';
export const CANCELLED = 'CANCELLED';
export const CANCELLED_PARTIALLY = 'CANCELLED_PARTIALLY';
export const EXPIRED = 'EXPIRED';
export const EXPIRED_PARTIALLY = 'EXPIRED_PARTIALLY';
export const REJECTED = 'REJECTED';
export const ASSIGNED = 'ASSIGNED';
export const PENDING_CANCELLATION = 'PENDING_CANCELLATION';
export const ERROR = 'ERROR';
export const SCHEDULED = 'SCHEDULED';
export const UNASSIGNED = 'UNASSIGNED';

// Currency format
export const ARS_LOCALE = 'es-AR';
export const ARS = 'ARS';
export const USD_LOCALE = 'en-US';
export const USD = 'USD';

// Amount notifications

export const NOTIFICATIONS_TO_SHOW_OR_ADD = 10;

// Exchange
export const exchangeCurrencyStartEndOptions = [
    { id: 'MEP - ARS', name: 'MEP - ARS' },
    { id: 'MEP - CABLE', name: 'MEP - CABLE' },
    { id: 'CABLE - ARS', name: 'CABLE - ARS' },
    { id: 'CABLE - MEP', name: 'CABLE - MEP' },
];

export const exchangeTerms = [
    { id: 0, name: 'Inmediato' },
    { id: 1, name: '24hs' },
];

// Client bank account status
export const PENDING_ACCEPT = 'PENDING_ACCEPT';
export const PENDING_DELETE = 'PENDING_DELETE';
export const ACTIVE = 'ACTIVE';
export const DELETED = 'DELETED';

export const LOCAL = 'LOCAL';
export const DESTINATION = 'DESTINATION';
export const BROKER = 'BROKER';
export const INTERMEDIARY = 'INTERMEDIARY';
export const SAVINGS = 'SAVINGS';
export const CHECKING = 'CHECKING';
export const EXTERIOR = 'EXTERIOR';

export const LOCAL_ACCOUNTS = [SAVINGS, CHECKING];

// wallet instrument order

export const INSTRUMENTS_ORDERS = [
    'CURRENCY',
    'EQUITY',
    'BOND',
    'CERTIFICATE',
    'FUND',
    'ADR',
    'EXTERIOR',
    'EXTERIOR_BOND',
    'EXTERIOR_FUND',
    'OTHER',
];

// Blog categories orders
export const CATEGORY_ORDER = [
    'Informe Diario',
    'Informe Semanal',
    'Idea de Inversión',
    'Mercado',
    'Cartera Recomendada',
    'Scoring de deuda provincial',
    'Educación',
    'Investment Case',
];

// Mapped chart constants

export const mappedInstrumentTypesValues: Record<string, string> = {
    DIVISAS: 'CURRENCY',
    ACCIONES: 'EQUITY',
    BONOS: 'BOND',
    'BONOS EN EL EXTERIOR': 'EXTERIOR_BOND',
    'FONDOS EN EL EXTERIOR': 'EXTERIOR_FUND',
    'OBLIGACIONES NEGOCIABLES EXTERIORES': 'EXTERIOR_ON',
    EXTERIOR: 'EXTERIOR',
    CEDEARS: 'CERTIFICATE',
    FONDOS: 'FUND',
    LETRAS: 'LETTER',
    ADRS: 'ADR',
    OTROS: 'OTHER',
};
// Pages allowed to have channelLayout

export const GROUPS_WITH_CHANNEL_LAYOUT = ['clients', 'market', 'treasury', 'reports'];

// commercial
export const VALID_VIDEO_EXTENSIONS = ['.mp4', '.mov', '.avi', '.wmv', '.mkv', '.flv', '.webm'];

// instruments allowed to access to detail
export const VALID_INSTRUMENT_TYPES = ['CURRENCY', 'EQUITY', 'BOND', 'CERTIFICATE', 'FUND'];
