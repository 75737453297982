import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import TableLayout from 'components/layouts/TableLayout';
import { CommonColumn, CommonFilter } from 'types/common/tableLayout.types';
import { ExtraQueryFiltersProps } from 'types/common/CommonGrid/CommonGridFormModal.types';
import { CurrencyPosition } from 'types/pages/reports.types';
import { useGetCurrencyPosition } from 'hooks/api/marketAccount.hooks';
import { today } from 'utils/helpers/dateHelper';

const ReportsCurrencyPositionPage = (props: Props) => {
    const { columns, filters, t, defaultFilters } = props;

    return (
        <TableLayout
            title={t('channel_currency_position_title')}
            fetchData={useGetCurrencyPosition}
            columns={columns}
            filters={filters}
            canExport
            exportTitle={`${t('currency_position_doc_title')} ${today}`}
            defaultFilters={defaultFilters}
        />
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    columns: CommonColumn<CurrencyPosition>[];
    filters: CommonFilter[];
    defaultFilters: ExtraQueryFiltersProps[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ReportsCurrencyPositionPage.propTypes = propTypes;

export default ReportsCurrencyPositionPage;
