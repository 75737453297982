import { axiosDataInstance } from 'api/axiosInstances';
import { withFakeData } from 'api/fakedata';
import {
    DeleteNotificationsRequest,
    GetAlertsResponse,
    GetNotificationsResponse,
    ReadNotificationsRequest,
} from 'types/api/alerts.types';

const apiName = 'alerts';
const ALERTS_BASE_URL = '/alerts/v1_0';

export default {
    getNotifications: () =>
        withFakeData<GetNotificationsResponse>(
            () => axiosDataInstance.get(`${ALERTS_BASE_URL}/notifications/`),
            apiName,
            'notifications',
            false,
            500,
        ),
    readNotifications: (req: ReadNotificationsRequest) =>
        withFakeData(
            () => axiosDataInstance.patch(`${ALERTS_BASE_URL}/notifications/`, req),
            apiName,
            'notifications',
            false,
            500,
        ),
    deleteNotifications: (req: DeleteNotificationsRequest) =>
        withFakeData(
            () => axiosDataInstance.delete(`${ALERTS_BASE_URL}/notifications/`, { data: req }),
            apiName,
            'notifications',
            false,
            500,
        ),
    getAlerts: () =>
        withFakeData<GetAlertsResponse>(
            () => axiosDataInstance.get(`${ALERTS_BASE_URL}/alerts/`),
            apiName,
            'alerts',
            false,
            500,
        ),
};
