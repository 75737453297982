import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import ActionButton from 'components/common/ActionButton';
import St from './AlertsNotificationModal.styled';
import { AletsObj } from 'types/api/alerts.types';
import ErrorText from '../ErrorText';

const HtmlRenderer = ({ htmlContent }: { htmlContent: string | undefined }) => {
    return <div dangerouslySetInnerHTML={{ __html: htmlContent || '' }} />;
};

const AlertsNotificationModal = (props: Props) => {
    const { open, t, handleAccept, errorMessage, isLoading, filteredAlert } = props;

    return (
        <Dialog open={open} disableEscapeKeyDown fullWidth>
            <St.DialogTitle typography="h6">{filteredAlert[0].name}</St.DialogTitle>
            <DialogContent>
                <DialogContent>
                    <HtmlRenderer htmlContent={filteredAlert[0].message} />
                </DialogContent>
            </DialogContent>
            <DialogActions>
                <ActionButton
                    onClick={() => handleAccept()}
                    variant="contained"
                    size="large"
                    type="button"
                    sx={{ width: '100%' }}
                    disabled={isLoading}
                    loading={isLoading}>
                    {t('understood')}
                </ActionButton>
            </DialogActions>
            {errorMessage && <ErrorText text={errorMessage} />}
        </Dialog>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    open: boolean;
    handleAccept: () => void;
    errorMessage: string | null;
    isLoading: boolean;
    setOpenModal: (state: boolean) => void;
    filteredAlert: AletsObj[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
AlertsNotificationModal.propTypes = propTypes;

export default AlertsNotificationModal;
