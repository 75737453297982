import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import OnboardingValidatePersonQuestionsSection from './OnboardingValidatePersonQuestionsSection';
import * as Yup from 'yup';
import { FormikConfig, FormikValues, useFormik } from 'formik';
import { tErrorsContext } from 'constants/appConstants';
import { useNavigate } from 'react-router-dom';
import QuestionsAnswers from './QuestionsAnswers';
import { useTranslation } from 'react-i18next';
import i18n from 'utils/i18n';
import {
    Option,
    Question,
    QuestionsNosisById,
    RenderListProps,
} from 'types/pages/onboardingValidatePerson.types';
import {
    useGetNosisQuestions,
    useGetOnboardingLegalData,
    useGetValidateNosisAnswers,
} from 'hooks/api/onboarding.hooks';
import { questionAdapterToValidateAAnswers } from 'adapters/api/onboarding.adapter';
import { GetValidateNosisAnswers } from 'types/api/onboarding.types';
import { getFormalName, scrollToTop } from 'utils/helpers/commonHelper';
import { OnboardingContext } from 'context/onboarding.context';
import { onboardingRoutesPaths } from 'router/routesPaths';

// ---------------------------------------------//
// ------------------- FORMIK ------------------//
// ---------------------------------------------//

const getInitialValues = (data?: QuestionsNosisById) => ({
    questions: data?.questions,
});

const getValidationSchema = () =>
    Yup.object().shape({
        questions: Yup.object().test(
            'questions',
            i18n.t('select_an_answer', tErrorsContext) as string,
            value => {
                const questionsKeys = Object.keys(value ?? {});

                const questionAnswers: Array<boolean> = questionsKeys.map(el => {
                    const obj = value[el] as Question;
                    let optionSelection = false;

                    for (const key in obj.questionOptions) {
                        if (obj.questionOptions[key].userChoice === true) {
                            optionSelection = true;
                            break;
                        }
                    }
                    return optionSelection;
                });
                return !questionAnswers.includes(false);
            },
        ),
    });

// ---------------------------------------------//
// ---------------------------------------------//
// ---------------------------------------------//

const RenderList = (props: RenderListProps) => {
    return (
        <>
            {Object.entries(props.values.questions ?? []).map(([key, value], index) =>
                value ? (
                    <React.Fragment key={key}>
                        <QuestionsAnswers
                            {...value}
                            handleCheck={props.handleCheck}
                            number={index + 1}
                            questionKey={key}
                        />
                    </React.Fragment>
                ) : null,
            )}
        </>
    );
};

const OnboardingValidatePersonQuestionsSectionContainer = (props: Props) => {
    // const {} = props;
    const { setLegalData } = React.useContext(OnboardingContext);
    const { mutate: getLegalData, isLoading: isLoadingLegalData } = useGetOnboardingLegalData();

    const navigate = useNavigate();
    const { t } = useTranslation('onboarding');
    const [validationFailedErrorMessage, setValidationFailedErrorMessage] = React.useState<
        string | null
    >(null);
    const [legalName, setLegalName] = React.useState<string | null>(null);
    const { data, isLoading, refetch } = useGetNosisQuestions(setValidationFailedErrorMessage);
    const {
        mutate: validateNosisAnswers,
        isLoading: isLoadingValidate,
        errorMessage,
    } = useGetValidateNosisAnswers();
    const handleSubmit = React.useCallback(
        async (values: FormikValues) => {
            const formData = {
                requestId: data?.requestId ?? '',
                ...questionAdapterToValidateAAnswers(values.questions),
            };
            const config = {
                onSuccess: ({ data }: { data: GetValidateNosisAnswers }) => {
                    scrollToTop();
                    switch (data.validationStep) {
                        case 'VALIDATED':
                            navigate(onboardingRoutesPaths.home);
                            break;
                        case 'VALIDATION_RETRY':
                            refetch();
                            break;
                        case 'VALIDATION_FAILED':
                            setValidationFailedErrorMessage(
                                t('blocked_user_error', tErrorsContext),
                            );
                            break;

                        default:
                            break;
                    }
                },
            };
            validateNosisAnswers(formData, config);
        },
        [validateNosisAnswers, navigate, data, refetch, t],
    );

    const formikInitProps = React.useMemo(
        () =>
            ({
                initialValues: getInitialValues(data),
                validateOnChange: true,
                validateOnBlur: false,
                validateOnMount: true,
                validationSchema: getValidationSchema(),
                enableReinitialize: true,
                onSubmit: handleSubmit,
            }) as FormikConfig<FormikValues>,
        [handleSubmit, data],
    );

    React.useEffect(() => {
        getLegalData(
            {},
            {
                onSuccess: data => {
                    setLegalData(data.data);
                    setLegalName(getFormalName(data.data));
                },
            },
        );
    }, []);

    const formik = useFormik(formikInitProps);
    const { setFieldValue, values } = formik;

    const handleCheck = (field: string, questionKey: string) => {
        const questionOptions = values.questions?.[questionKey]?.questionOptions as Option;

        if (questionOptions?.[field]?.userChoice) {
            return setFieldValue(
                `questions.${questionKey}.questionOptions.${field}.userChoice`,
                false,
            );
        }
        let currentKeyWithTrue;
        Object.keys(values.questions?.[questionKey]?.questionOptions ?? {}).forEach(key => {
            if (questionOptions?.[key]?.userChoice) currentKeyWithTrue = key;
        });
        if (currentKeyWithTrue)
            setFieldValue(
                `questions.${questionKey}.questionOptions.${currentKeyWithTrue}.userChoice`,
                false,
            );
        setFieldValue(`questions.${questionKey}.questionOptions.${field}.userChoice`, true);
    };

    const childProps = {
        ...props,
        handleCheck,
        RenderList,
        isLoading: isLoadingLegalData || isLoading,
        isLoadingValidate,
        errorMessage,
        validationFailedErrorMessage,
        formik,
        incorrectResponse: data?.retry ? t('error_in_answers', tErrorsContext) : null,
        t,
        name: legalName,
    };

    return <OnboardingValidatePersonQuestionsSection {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OnboardingValidatePersonQuestionsSectionContainer.propTypes = propTypes;

export default OnboardingValidatePersonQuestionsSectionContainer;
