import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './WalletWithdrawalPage.styled';
import { Box, FormLabel, Typography } from '@mui/material';
import iconWithDrawal from '../../../assets/img/general/icon-withdrawal.png';
import CustomAutoComplete from 'components/common/CustomAutoComplete';
import ActionButton from 'components/common/ActionButton';
import { FormikContextType, FormikProvider } from 'formik';
import { MultiCurrencyObj } from 'types/api/marketAccount.types';
import CustomModalAuth from 'components/common/CustomModalAuth';
import CurrencyInput from 'components/common/CurrencyInput';
import WithdrawalSuccessModal from './WithdrawalSuccessModal';
import { convertCurrencyFormat } from 'utils/helpers/commonHelper';
import { USD } from 'constants/appConstants';
import { Currency } from 'types/common/general.types';
import { pxToRem } from 'utils/helpers/stylesHelper';
import CustomLoader from 'components/common/CustomLoader/CustomLoader';

const WalletWithdrawalPage = (props: Props) => {
    const {
        t,
        formik,
        bankAccountsOptions,
        availableExtraction,
        SnackBar,
        functionUseEffectDisable,
        open,
        handleClose,
        isLoading,
        isLoadingWithdrawal,
        errorSubmit,
        isLoadingRequestOtp,
        handleWithdrawal,
        lockedMessage,
    } = props;
    const { errors, values, setFieldValue, resetForm } = formik;

    return (
        <>
            <St.ContainerHeader>
                <Typography variant="h3" color={'primary'} mb={4}>
                    {t('amount_to_extract_title')}
                </Typography>
                <img
                    src={iconWithDrawal}
                    alt="icon-withdrawal"
                    style={{
                        width: '100px',
                        height: '100px',
                    }}
                />
                <Typography variant="h5" color="#2e4665">
                    {t('amount_to_extract')}
                </Typography>
                <Typography variant="body1" color="#2e4665">
                    {t('amount_to_extract_legend')}
                </Typography>
                {isLoading ? (
                    <CustomLoader />
                ) : (
                    <St.ContainerItems>
                        <St.ContainerTexts>
                            <Typography color="#2e4665" variant="body1">
                                {t('avaiable_in')}
                            </Typography>
                            <Typography variant="body1" color="primary">
                                {t('available_in_ars')} &nbsp;
                                {convertCurrencyFormat(availableExtraction?.ars || 0)}
                            </Typography>
                        </St.ContainerTexts>
                        <St.ContainerTexts>
                            <Typography color="#2e4665" variant="body1">
                                {t('avaiable_in')}
                            </Typography>
                            <Typography variant="body1" color="primary">
                                {t('available_in_usd')} &nbsp;
                                {convertCurrencyFormat(availableExtraction?.usd || 0, USD)}
                            </Typography>
                        </St.ContainerTexts>
                        {availableExtraction && availableExtraction?.usdcT > 0 && (
                            <>
                                <St.ContainerTexts sx={{ display: 'flex', gap: '5px' }}>
                                    <Typography color="#2e4665" variant="body1">
                                        {t('avaiable_in')}
                                    </Typography>
                                    <Typography variant="body1" color="primary">
                                        {t('available_in_usdc')} &nbsp;
                                        {convertCurrencyFormat(
                                            availableExtraction?.usdcT || 0,
                                            USD,
                                        )}
                                    </Typography>
                                </St.ContainerTexts>
                                <Typography color="#2e4665" variant="subtitle1">
                                    ({t('usdc_title')})
                                </Typography>
                            </>
                        )}
                        {availableExtraction && availableExtraction?.usdm > 0 && (
                            <>
                                <St.ContainerTexts sx={{ display: 'flex', gap: '5px' }}>
                                    <Typography color="#2e4665" variant="body1">
                                        {t('avaiable_in')}
                                    </Typography>
                                    <Typography variant="body1" color="primary">
                                        {t('available_in_usdm')} &nbsp;
                                        {convertCurrencyFormat(availableExtraction?.usdm || 0, USD)}
                                    </Typography>
                                </St.ContainerTexts>
                                <Typography color="#2e4665" variant="subtitle1">
                                    ({t('usd_mep_title')})
                                </Typography>
                            </>
                        )}
                        <Typography variant="body2" color="primary" textAlign={'center'} m={2}>
                            {t('extract_time')}
                        </Typography>
                    </St.ContainerItems>
                )}
                <FormikProvider value={formik}>
                    <St.Form>
                        <FormLabel>{t('account_input_label')}</FormLabel>
                        <CustomAutoComplete
                            isLoading={isLoading}
                            id="destinationAccount"
                            value={values?.destinationAccount ?? null}
                            onChange={(e, value) => {
                                setFieldValue('destinationAccount', value);
                            }}
                            options={bankAccountsOptions ?? []}
                            label={t('label_input_accounts')}
                            getOptionLabel={option =>
                                `${option.description ? `(${option.description}) ` : ''} ${option.accountNumber} ${option.name} (${option.currency})`
                            }
                            renderOption={(props, option) => (
                                <Box {...props} key={option.id}>
                                    {option.description ? `(${option.description})` : ''}{' '}
                                    {option.accountNumber} {option.name} ({option.currency})
                                </Box>
                            )}
                            helperText={
                                errors.destinationAccount && errors.destinationAccount.toString()
                            }
                            error={
                                errors.destinationAccount && errors.destinationAccount.toString()
                            }
                        />
                        <St.Link to="/profile/bank-accounts">{t('handle_bank_account')}</St.Link>
                        <CurrencyInput
                            value={values.amountToExtract}
                            currency={
                                values.destinationAccount
                                    ? (values.destinationAccount?.currency as Currency)
                                    : 'ARS'
                            }
                            decimalScale={2}
                            error={errors.amountToExtract as string | null}
                            handleChange={value => setFieldValue('amountToExtract', value)}
                            disabled={!values.destinationAccount}
                            helperText={errors.amountToExtract && errors.amountToExtract.toString()}
                        />
                        <ActionButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            loading={isLoadingWithdrawal || isLoadingRequestOtp}
                            disabled={
                                !values.destinationAccount ||
                                !values.amountToExtract ||
                                lockedMessage !== null
                            }
                            sx={{
                                width: '100%',
                                fontSize: '1rem',
                            }}>
                            {t('withdrawal_button')}
                        </ActionButton>
                        {lockedMessage !== null && (
                            <Typography
                                component="p"
                                fontSize={pxToRem(13)}
                                color="error"
                                dangerouslySetInnerHTML={{
                                    __html: lockedMessage || '',
                                }}
                            />
                        )}
                    </St.Form>
                </FormikProvider>
            </St.ContainerHeader>
            {open === 'OTP' && (
                <CustomModalAuth
                    handleClose={handleClose}
                    open={open === 'OTP'}
                    sendEmailCode={functionUseEffectDisable}
                    handleSubmit={otp => handleWithdrawal(otp)}
                    isLoadingSubmit={isLoadingWithdrawal}
                    apiError={errorSubmit}
                />
            )}
            <SnackBar />
            {open === 'SUCCESS' && (
                <WithdrawalSuccessModal
                    open={open === 'SUCCESS'}
                    close={handleClose}
                    resetForm={resetForm}
                />
            )}
        </>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    isLoadingRequestOtp: PropTypes.bool.isRequired,
};

interface extraProps {
    formik: FormikContextType<any>;
    bankAccountsOptions: {
        name: string;
        id: string;
        description: string;
        currency: string;
    }[];
    availableExtraction: MultiCurrencyObj | undefined;
    SnackBar: () => JSX.Element;
    functionUseEffectDisable: () => void;
    open: 'OTP' | 'SUCCESS' | null;
    handleClose: () => void;
    handleWithdrawal: (otp: string) => void;
    isLoading: boolean;
    isLoadingWithdrawal: boolean;
    errorSubmit: string | null;
    lockedMessage: string | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
WalletWithdrawalPage.propTypes = propTypes;

export default WalletWithdrawalPage;
