import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import FileDownloader from './FileDownloader';
import PdfIcon from 'assets/img/general/pdfFile.svg?react';
import TXTFileIcon from 'assets/img/general/txtFile.svg?react';
import { useTranslation } from 'react-i18next';
import { useDownloadFile } from 'hooks/api/utils.hooks';

const FileDownloaderContainer = (props: Props) => {
    const { fileUrl, label } = props;
    const { t } = useTranslation();
    const { SnackBar, downloadFile, isLoading, error } = useDownloadFile();

    const extensionsMapper: { [key: string]: string } = {
        'text/csv': 'csv',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
        'application/pdf': 'pdf',
        'text/plain': 'txt',
        'image/*': 'jpg, png',
    };

    const fileExtension = React.useMemo(() => {
        if (fileUrl) {
            const route = fileUrl?.split('?')[0];
            const key = route.split('/').pop() ?? '';
            const res = key?.split('.').pop() ?? 'default';
            if (extensionsMapper['image/*'].includes(res)) return 'image';
            return res;
        }
        return 'default';
    }, [fileUrl]);

    const fileIconMapper = {
        pdf: PdfIcon,
        txt: TXTFileIcon,
        image: PdfIcon,
        default: PdfIcon,
    };

    const childProps = {
        ...props,
        t,
        error,
        label,
        fileUrl,
        isLoading,
        downloadFile,
        fileExtension,
        fileIconMapper,
        SnackBar,
    };

    return <FileDownloader {...childProps} />;
};

const propTypes = {
    fileUrl: PropTypes.string,
    label: PropTypes.string.isRequired,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
FileDownloaderContainer.propTypes = propTypes;

export default FileDownloaderContainer;
