import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { useTranslation } from 'react-i18next';
import { CommonAction, CommonColumn, CommonFilter } from 'types/common/tableLayout.types';
import { Chip, Typography } from '@mui/material';
import {
    convertCurrencyFormat,
    getColorByStatus,
    orderStatusOptions,
    statusToShowDelete,
} from 'utils/helpers/commonHelper';
import ChannelsDDJJCellInfo from 'components/common/ChannelsDDJJCellInfo';
import { INSTRUMENTS_BASE_URL } from 'constants/appConstants';
import { formatDateWithHour } from 'utils/helpers/dateHelper';
import OperationsReferralOrdersPage from './OperationsReferralOrdersPage';
import { GetMarketOrderItem } from 'types/api/orders.types';
import CustomIconCreator from 'components/common/CustomIconCreator';
import CloseIcon from 'assets/icons/close-line.svg?react';
import DeleteOrderModal from './DeleteOrderModal';
import OrderDetail from './OrderDetail';
import EyeIcon from 'assets/icons/eye-icon.svg?react';
import moment from 'moment';

const OperationsReferralOrdersPageContainer = (props: Props) => {
    const { t } = useTranslation();

    const columns: CommonColumn<GetMarketOrderItem>[] = [
        {
            id: 'createdAt',
            name: t('date'),
            selector: row => formatDateWithHour(row.createdAt),
            sortable: true,
        },
        {
            id: 'code',
            name: t('code'),
            selector: row => row.code || '-',
            sortable: true,
        },
        {
            id: 'buySell',
            name: t('type_filter'),
            selector: row => <Chip color="secondary" variant="outlined" label={t(row.buySell)} />,
            sortable: true,
        },
        {
            id: 'instrument',
            name: t('instrument'),
            selector: row => (
                <Typography fontSize={14} fontWeight={700}>
                    {row?.ticker.ticker || '-'}
                </Typography>
            ),
        },
        {
            id: 'term',
            name: t('term'),
            selector: row => t(`term_${row.term}`),
            sortable: true,
        },
        {
            id: 'currency',
            name: t('currency'),
            selector: row => row.ticker.currency,
        },
        {
            id: 'quantity',
            name: t('quantity'),
            sortable: true,
        },
        {
            id: 'executed',
            name: t('quantity_executed'),
            selector: row => row.executed || '-',
            sortable: true,
        },
        {
            id: 'price',
            name: t('price'),
            selector: ({ price, ticker }) => convertCurrencyFormat(price, ticker.currency),
        },
        {
            id: 'ddjjRequired',
            name: t('ddjj'),
            selector: row => <ChannelsDDJJCellInfo {...row} orderStatus={row.status} />,
        },
        {
            id: 'status',
            name: t('status'),
            selector: row => (
                <Chip sx={{ ...getColorByStatus(row.status) }} label={t(row.status)} />
            ),
            sortable: true,
        },
    ];

    const actions: CommonAction<GetMarketOrderItem>[] = [
        {
            id: 'detail',
            type: 'modal',
            Icon: () => <CustomIconCreator icon={EyeIcon} />,
            onClick: props => <OrderDetail {...props} />,
        },
        {
            id: 'delete_order',
            Icon: () => <CustomIconCreator icon={CloseIcon} />,
            type: 'modal',
            isHidden: row => !statusToShowDelete.includes(row.status),
            onClick: props => <DeleteOrderModal {...props} />,
        },
    ];

    const filters: CommonFilter[] = [
        {
            id: 'dateFrom',
            label: t('from'),
            type: 'date',
            query: 'dateFrom',
        },
        {
            id: 'dateTo',
            label: t('to'),
            type: 'date',
            query: 'dateTo',
        },
        {
            id: 'buySell',
            label: t('type_filter'),
            type: 'select',
            query: 'buySell',
            options: [
                { id: 'BUY', name: t('BUY') },
                { id: 'SELL', name: t('SELL') },
            ],
        },
        {
            id: 'status',
            label: t('status_filter'),
            type: 'select',
            query: 'status',
            options: orderStatusOptions,
        },
        {
            id: 'ticker',
            label: t('instrument'),
            type: 'requestAutocomplete',
            query: 'ticker',
            requestAutocomplete: {
                requestUrl: `${INSTRUMENTS_BASE_URL}/instruments/`,
                param: 'q',
                optionLabel: option => option.tickers[0].ticker,
                keyTofilter: 'tickers[0].ticker',
                extraFilter: '&type=BOND&type=CERTIFICATE&type=EQUITY',
            },
        },
    ];

    const defaultFilters = [
        { query: 'dateFrom', queryValue: moment().format('YYYY-MM-DD') },
        { query: 'dateTo', queryValue: moment().format('YYYY-MM-DD') },
    ];

    const childProps = {
        ...props,
        columns,
        filters,
        actions,
        defaultFilters,
        t,
    };
    return <OperationsReferralOrdersPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OperationsReferralOrdersPageContainer.propTypes = propTypes;

export default OperationsReferralOrdersPageContainer;
