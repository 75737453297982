import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './DepositStepTwo.styled';
import { Typography, Box } from '@mui/material';
import { CONSULTATIO_ACCOUNTS, tCommon } from 'constants/appConstants';
import DepositIcon from 'assets/img/general/icon-deposit.png';
import { mainRoutesPaths } from 'router/routesPaths';
import CustomAutoComplete from 'components/common/CustomAutoComplete';
import { Form, FormikContextType, FormikProvider } from 'formik';
import { GetBankAccountsItem, GetBankAccountsResponse } from 'types/api/cashflow.types';
import CustomLoader from 'components/common/CustomLoader';
import FileUploader from 'components/common/FileUploader';
import CurrencyInput from 'components/common/CurrencyInput';
import ActionButton from 'components/common/ActionButton';

const DepositStepTwo = (props: Props) => {
    const {
        t,
        handleSourceAccount,
        bankAccounts,
        formik,
        isLoading,
        handleNext,
        handleBack,
        SnackBar,
        createIsLoading,
    } = props;
    const { errors, values, setFieldValue } = formik;

    return (
        <>
            <FormikProvider value={formik}>
                <Form>
                    <St.IconWrapper>
                        <St.StyledImage
                            style={{
                                marginTop: '2rem',
                                marginBottom: '2rem',
                            }}
                            src={DepositIcon}
                            alt="depositIcon"
                        />
                    </St.IconWrapper>
                    <Typography variant="h5" gutterBottom>
                        {t('deposit_title_step_2')}
                    </Typography>
                    <St.BodyText variant="body2" paragraph>
                        {t('deposit_account')}
                    </St.BodyText>
                    <Box flex={1} marginBottom={'30px'}>
                        <St.CustomAutoComplete
                            id="destinationAccount"
                            value={values.destinationAccount || null}
                            onChange={(event, value) => setFieldValue('destinationAccount', value)}
                            options={CONSULTATIO_ACCOUNTS ?? []}
                            label={(t('deposit_destination_account') as string) + '*'}
                            placeholder={t('deposit_destination_account') as string}
                            renderOption={(props, option) => (
                                <Box {...props} key={option.id}>
                                    {option.name}
                                </Box>
                            )}
                        />
                    </Box>

                    {isLoading ? (
                        <CustomLoader />
                    ) : (
                        bankAccounts && (
                            <>
                                <St.BodyText variant="body2" paragraph>
                                    {t('deposit_from_which_account')}
                                </St.BodyText>
                                <Box flex={1} marginBottom={'25px'}>
                                    <CustomAutoComplete
                                        id="sourceAccount"
                                        value={values.sourceAccount || null}
                                        onChange={(event, newValue) => {
                                            handleSourceAccount(newValue);
                                        }}
                                        options={bankAccounts.map(
                                            (account: GetBankAccountsItem) => ({
                                                id: account.id,
                                                name: `${account.description ? `(${account.description}) ` : ''} ${account.accountNumber} ${account.bankName} (${account.currency})`,
                                            }),
                                        )}
                                        label={(t('deposit_source_account') as string) + '*'}
                                        placeholder={t('deposit_source_account') as string}
                                        renderOption={(props, option) => (
                                            <Box {...props} key={option.id}>
                                                {option.name}
                                            </Box>
                                        )}
                                    />
                                </Box>
                                {errors.currencyMatch && (
                                    <Typography color="error" variant="body2">
                                        {errors.currencyMatch.toString()}
                                    </Typography>
                                )}
                            </>
                        )
                    )}
                    <St.StyledLink variant="body2" paragraph href={mainRoutesPaths.bankAccounts}>
                        {t('deposit_manage_my_bank_accounts')}
                    </St.StyledLink>
                    <St.BodyText variant="body2" paragraph>
                        {t('deposit_source_and_destination_same_currency')}
                    </St.BodyText>
                    <St.InputContainer>
                        <CurrencyInput
                            currency={
                                values.destinationAccount
                                    ? (values.destinationAccount?.name.match(
                                          /\((USD|ARS)\)$/,
                                      )?.[1] ?? 'ARS')
                                    : 'USD'
                            }
                            handleChange={value => setFieldValue('amount', value)}
                            sx={{ width: '50%' }}
                            value={values.amount}
                            decimalScale={2}
                            disabled={!values.destinationAccount || !values.sourceAccount}
                            error={errors.amount as string | null}
                            helperText={errors.amount && errors.amount.toString()}
                        />
                    </St.InputContainer>
                    <Box flex={1} marginTop={'25px'} textAlign={'center'}>
                        <St.BodyText variant="body2" paragraph>
                            {t('deposit_attach_receipt')}
                        </St.BodyText>
                        <Box width="100%" display="flex" justifyContent="center">
                            <Box display="flex" justifyContent="center" width="fit-content">
                                <FileUploader
                                    values={values}
                                    errors={errors}
                                    setFieldValue={setFieldValue as any}
                                    label={''}
                                    field="document"
                                />
                            </Box>
                        </Box>
                    </Box>
                    <SnackBar />
                </Form>
            </FormikProvider>
            <Box display="flex" justifyContent="space-between" mt={2}>
                <ActionButton variant="outlined" onClick={handleBack}>
                    {t('deposit_btn_back')}
                </ActionButton>
                <ActionButton
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    loading={createIsLoading}
                    disabled={createIsLoading}>
                    {t('next', tCommon)}
                </ActionButton>
            </Box>
        </>
    );
};
const propTypes = {
    t: PropTypes.func.isRequired,
    handleSourceAccount: PropTypes.func.isRequired,
    handleNext: PropTypes.func.isRequired,
    handleBack: PropTypes.func.isRequired,
};

interface extraProps {
    bankAccounts: GetBankAccountsItem[];
    formik: FormikContextType<any>;
    isLoading: boolean;
    SnackBar: () => JSX.Element;
    createIsLoading: boolean;
}
interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
DepositStepTwo.propTypes = propTypes;
DepositStepTwo;
export default DepositStepTwo;
