import { Typography, Tooltip, IconButton, Box } from '@mui/material';
import PropTypes from 'prop-types';
import FilterOnIcon from 'assets/icons/filter.svg?react';
import FilterOffIcon from 'assets/icons/filter-off.svg?react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CustomIconCreator from '../CustomIconCreator';
import HeaderFilters from '../HeaderFilters';
import { CommonAction, CommonColumn, CommonFilter } from 'types/common/tableLayout.types';
import CommonModal from '../CommonModal';
import { GridHookParams } from 'types/common/CommonGrid/CommonGridFormModal.types';
import ExportButton from '../ExportButton';

const TableLayoutHeader = ({
    title,
    filters,
    createAction,
    showFilters,
    filterHeaderString,
    setFilterHeaderString,
    toggleFilters,
    canExport,
    columns,
    customFilterValidation,
    fetchData,
    t,
    total,
    exportTitle,
}: Props) => (
    <>
        <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1.5}>
            <Box display="flex" flexDirection="row">
                {title && (
                    <Typography fontSize={34} fontWeight={500} mr={1.5} color="primary">
                        {title}
                    </Typography>
                )}
                {filters && (
                    <Tooltip title={t(showFilters ? 'hide_filters' : 'show_filters')}>
                        <IconButton onClick={toggleFilters}>
                            <CustomIconCreator
                                icon={showFilters ? FilterOffIcon : FilterOnIcon}
                                sx={{ color: 'primary.main' }}
                            />
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
            <Box display="flex" gap={2}>
                {canExport && fetchData && (
                    <ExportButton
                        columns={columns}
                        filterHeaderString={filterHeaderString}
                        total={total}
                        exportTitle={exportTitle}
                        fetchData={fetchData}
                    />
                )}
                {createAction &&
                    (createAction?.isHidden && createAction?.isHidden({}) ? (
                        <></>
                    ) : (
                        <CommonModal
                            createButton
                            {...createAction}
                            component={createAction.onClick}
                        />
                    ))}
            </Box>
        </Box>
        {filters && showFilters && (
            <HeaderFilters
                filters={filters}
                filterHeaderString={filterHeaderString}
                setFilterHeaderString={setFilterHeaderString}
                customFilterValidation={customFilterValidation}
            />
        )}
    </>
);
const propTypes = {
    t: PropTypes.func.isRequired,
    total: PropTypes.number.isRequired,
    exportTitle: PropTypes.string.isRequired,
};

interface extraProps {
    showFilters: boolean;
    filterHeaderString: string;
    setFilterHeaderString(filterHeaderString: string): void;
    toggleFilters(): void;
    canExport?: boolean;
    title?: string;
    filters?: CommonFilter[];
    createAction?: CommonAction<any>;
    customFilterValidation?: (value: string) => { result: boolean; error: string | undefined };
    fetchData?: (params: GridHookParams) => any;
    columns: CommonColumn<any>[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
TableLayoutHeader.propTypes = propTypes;

export default TableLayoutHeader;
