import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import NavbarItem from './NavbarItem';
import { useTranslation } from 'react-i18next';
import { SubRouteInfo } from 'utils/helpers/routesHelper';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material';
import useScreenWidth from 'hooks/common/screenWidth';
import { AuthContext } from 'context/auth.context';

const NavbarItemContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation();
    const { userType } = React.useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname;
    const theme = useTheme();
    const screenWidth = useScreenWidth();
    const isChannel = ['CHANNEL', 'ASSISTANT'].includes(userType || '');
    const childProps = {
        ...props,
        t,
        path,
        theme,
        navigate,
        screenWidth,
        minorLetter: isChannel && screenWidth < 1270,
    };

    return <NavbarItem {...childProps} />;
};

const propTypes = {};

interface extraProps {
    route: SubRouteInfo;
    handleHover: (menu: SubRouteInfo | null, hasSubroutes?: boolean) => void;
    openSubmenu: string | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
NavbarItemContainer.propTypes = propTypes;

export default NavbarItemContainer;
