import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ReportsReceiptsInvoicesPage from './ReportsReceiptsInvoicesPage';
import { useTranslation } from 'react-i18next';
import { CommonColumn, CommonFilter } from 'types/common/tableLayout.types';
import { Receipts } from 'types/api/cashflow.types';
import { tCommon, tErrorsContext } from 'constants/appConstants';
import { formatSingleDate } from 'utils/helpers/dateHelper';
import { AuthContext } from 'context/auth.context';
import moment from 'moment';
import { formatNumber } from 'utils/helpers/commonHelper';

const ReportsReceiptsInvoicesPageContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('reports');
    const tBase = 'reports_receips_page_';
    const { customerCode } = React.useContext(AuthContext);

    const columns: CommonColumn<Receipts>[] = [
        {
            id: 'description',
            name: t(`${tBase}column_description`),
            selector: row => row.description ?? '-',
            exportOption: row => row.description ?? '-',
        },
        {
            id: 'movementNumber',
            name: t(`${tBase}column_movement_number`),
            selector: row => row.treasuryMoveNumber ?? '-',
            exportOption: row => row.treasuryMoveNumber ?? '-',
        },
        {
            id: 'agreementDate',
            name: t(`reports_tickets_page_column_concertation_date`),
            selector: ({ concertationDate }) =>
                concertationDate ? formatSingleDate(concertationDate) : '-',
            exportOption: ({ concertationDate }) =>
                concertationDate ? formatSingleDate(concertationDate) : '-',
        },
        {
            id: 'liquidationDate',
            name: t(`reports_tickets_page_column_liquidation_date`),
            selector: ({ liquidationDate }) =>
                liquidationDate ? formatSingleDate(liquidationDate) : '-',
            exportOption: ({ liquidationDate }) =>
                liquidationDate ? formatSingleDate(liquidationDate) : '-',
        },
        {
            id: 'currency',
            name: t(`${tBase}currency`),
            selector: row => row.currency ?? '-',
            exportOption: row => row.currency ?? '-',
        },
        {
            id: 'accountDescription',
            name: t(`${tBase}column_origin_bank`),
            selector: ({ description, accountDescription }) =>
                description === 'Recibo de Cobro' ? '-' : (accountDescription ?? '-'),
            exportOption: ({ description, accountDescription }) =>
                description === 'Recibo de Cobro' ? '-' : (accountDescription ?? '-'),
        },
        {
            id: 'liquidationEntityDescription',
            name: t(`${tBase}column_destination_bank`),
            selector: ({
                description,
                accountDescription,
                liquidationEntityDescription,
                bankAccountNumber,
            }) =>
                description === 'Recibo de Cobro'
                    ? (accountDescription ?? '-')
                    : `${liquidationEntityDescription ?? ''} ${bankAccountNumber ?? ''}`.trim() ||
                      '-',
            exportOption: ({
                description,
                accountDescription,
                liquidationEntityDescription,
                bankAccountNumber,
            }) =>
                description === 'Recibo de Cobro'
                    ? (accountDescription ?? '-')
                    : `${liquidationEntityDescription ?? ''} ${bankAccountNumber ?? ''}`.trim() ||
                      '-',
        },
        {
            id: 'amount',
            name: t(`${tBase}column_amount`),
            selector: ({ currencySymbol, amount }) =>
                amount != null ? `${currencySymbol} ${formatNumber(amount)}` : '-',
            exportOption: ({ currencySymbol, amount }) =>
                amount != null ? `${currencySymbol}${formatNumber(amount)}` : '-',
        },
        {
            id: 'exchangeRate',
            name: t(`${tBase}column_exchange_rate`),
            selector: ({ exchangeRate }) =>
                exchangeRate != null ? `ARS ${formatNumber(exchangeRate)}` : '-',
        },
        {
            id: 'localAmount',
            name: t(`${tBase}column_local_amount`),
            selector: ({ localAmount }) =>
                localAmount != null ? `ARS ${formatNumber(localAmount)}` : '-',
        },
        {
            id: 'expenses',
            name: t(`${tBase}column_expenses`),
            selector: ({ currencySymbol, expenses }) =>
                expenses != null ? `${currencySymbol} ${formatNumber(expenses)}` : '-',
        },
    ];

    const filters: CommonFilter[] = [
        {
            id: 'dateType',
            label: t(`reports_tickets_page_date_type`, tCommon),
            type: 'select',
            query: 'byLiquidation',
            options: [
                { id: 'true', name: t(`reports_tickets_page_for_liquidation`) },
                { id: 'false', name: t(`reports_tickets_page_for_concertation`) },
            ],
        },
        {
            id: 'dateFrom',
            label: t('from', tCommon),
            type: 'date',
            query: 'dateFrom',
        },
        {
            id: 'dateTo',
            label: t('to', tCommon),
            type: 'date',
            query: 'dateTo',
        },
    ];

    const filterDateValidation = (
        filters: string,
    ): { result: boolean; error: string | undefined } => {
        if (
            ['', undefined].includes(filters) ||
            !filters.includes('dateFrom') ||
            !filters.includes('dateTo')
        )
            return {
                result: false,
                error: t('enter_date_from_and_to', tErrorsContext) as string,
            };

        return { result: true, error: undefined };
    };

    const current = moment();
    const lastWeek = moment().subtract(7, 'days');

    const defaultFilters = [
        { query: 'byLiquidation', queryValue: 'false' },
        { query: 'dateFrom', queryValue: lastWeek.format('YYYY-MM-DD') },
        { query: 'dateTo', queryValue: current.format('YYYY-MM-DD') },
    ];

    const childProps = {
        ...props,
        columns,
        filters,
        customerCode,
        defaultFilters,
        filterDateValidation,
        t,
    };
    return <ReportsReceiptsInvoicesPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ReportsReceiptsInvoicesPageContainer.propTypes = propTypes;

export default ReportsReceiptsInvoicesPageContainer;
