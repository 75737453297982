import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import OnboardingPersonalDataPage from './OnboardingPersonalDataPage';
import * as Yup from 'yup';
import { tRequiredFieldError } from 'constants/appConstants';
import React from 'react';
import { FormikConfig, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
    GetLegalDataReponse,
    Nationality,
    OnboardingPersonalDataPageRequest,
} from 'types/api/onboarding.types';
import { useGetNationalities, usePersonalDataStep } from 'hooks/api/onboarding.hooks';
import { AuthContext } from 'context/auth.context';
import { OnboardingContext } from 'context/onboarding.context';
import { useNavigate } from 'react-router-dom';
import useSnackBar from 'hooks/common/snackbar.hooks';
import { errorResponseHelper } from 'utils/helpers/errorHelper';
import { onboardingRoutesPaths } from 'router/routesPaths';

// ---------------------------------------------//
// ------------------- FORMIK ------------------//
// ---------------------------------------------//

const getInitialValues = (
    data: GetLegalDataReponse | null,
    nationality: { id: number; name: string } | null,
) => ({
    nationality: nationality ?? null,
    name: data?.name ?? '',
    lastName: data?.lastName ?? '',
    docNumber: data?.docNumber ?? '',
    taxDocNumber: data?.taxDocNumber ?? '',
    gender: data?.gender ?? '',
    birthDate: data?.birthDate ?? '',
});
const getValidationSchema = () =>
    Yup.lazy(() =>
        Yup.object().shape({
            nationality: Yup.object()
                .shape({
                    nationalityId: Yup.number(),
                    nationality: Yup.string(),
                })
                .required(tRequiredFieldError)
                .nullable(),
        }),
    );
// ---------------------------------------------//
// ---------------------------------------------//
// ---------------------------------------------//

export const OnboardingPersonalDataPageContainer = (props: Props) => {
    const { t } = useTranslation('onboarding');
    const { userData } = React.useContext(AuthContext);
    const {
        legalData: onboardingLegalData,
        isLoadingLegalData,
        errorMessageLegalData,
        isLoadingUserInformation,
        userInformationUploaded,
    } = React.useContext(OnboardingContext);
    const [openDisagreeModal, setOpenDisagreeModal] = React.useState<boolean>(false);
    const [defaultNationality, setDefaultNationality] = React.useState<{
        id: number;
        name: string;
    } | null>(null);
    const navigate = useNavigate();

    const { data: nationalitiesOptions, isLoading: isLoadingNationalities } = useGetNationalities();
    const { mutate, isLoading: isLoadingSubmit, error: errorSubmit } = usePersonalDataStep();
    const { SnackBar, setSnackBarMessage } = useSnackBar();

    const handlePersonalData = React.useCallback(
        (
            nationality: string,
            rejectPersonalInfo: boolean = false,
            rejectPersonalReason: string = '',
        ) => {
            const argument: OnboardingPersonalDataPageRequest = {
                name: onboardingLegalData?.name as string,
                lastName: onboardingLegalData?.lastName as string,
                docType: 'DNI',
                docNumber: onboardingLegalData?.docNumber as string,
                taxDocType: 'CUIL/CUIT',
                taxDocNumber: onboardingLegalData?.taxDocNumber as string,
                nationality,
                gender: onboardingLegalData?.gender as string,
                birthDate: onboardingLegalData?.birthDate as string,
                rejectPersonalInfo,
                rejectPersonalReason,
            };

            mutate(argument, {
                onSuccess: () => {
                    if (openDisagreeModal) setOpenDisagreeModal(false);
                    navigate(onboardingRoutesPaths.home);
                },
                onError(error) {
                    if (rejectPersonalInfo) {
                        setSnackBarMessage(errorResponseHelper(error));
                    }
                },
            });
        },
        [onboardingLegalData, mutate, navigate, openDisagreeModal],
    );

    const handleSubmit = React.useCallback(
        async (values: { nationality: Nationality }) => {
            handlePersonalData(values?.nationality?.name);
        },
        [handlePersonalData],
    );

    const formikInitProps = React.useMemo(
        () =>
            ({
                initialValues: getInitialValues(onboardingLegalData, defaultNationality),
                validateOnChange: false,
                validateOnBlur: false,
                validateOnMount: false,
                validationSchema: getValidationSchema(),
                enableReinitialize: true,
                onSubmit: handleSubmit,
            }) as FormikConfig<any>,
        [handleSubmit, onboardingLegalData],
    );

    const formik = useFormik(formikInitProps);

    React.useEffect(() => {
        if (userInformationUploaded && nationalitiesOptions)
            setDefaultNationality(
                nationalitiesOptions.find(
                    el => el.name === userInformationUploaded.personalInfo.nationality,
                ) || null,
            );
    }, [userInformationUploaded, nationalitiesOptions]);

    const childProps = {
        t,
        formik,
        openDisagreeModal,
        setOpenDisagreeModal,
        isLoadingLegalData: isLoadingLegalData || isLoadingUserInformation,
        email: `${userData?.email}`,
        isLoadingNationalities,
        nationalitiesOptions,
        handlePersonalData,
        errorMessageLegalData,
        isLoadingSubmit,
        errorSubmit,
        SnackBar,
        setSnackBarMessage,
    };
    return <OnboardingPersonalDataPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OnboardingPersonalDataPageContainer.propTypes = propTypes;

export default OnboardingPersonalDataPageContainer;
