import React from 'react';
import {
    ExtraQueryFiltersProps,
    GridHookParams,
} from 'types/common/CommonGrid/CommonGridFormModal.types';
import { useCommonGrid } from 'context/common/commonGrid.context';
import useBreakpoints from '../breakpoints.hooks';
import { SortInformation } from 'types/common/tableLayout.types';

const useCustomFetch = ({
    useGetData,
    canDisable,
    clientSidePagination,
    adjustHeight,
    canSearch,
    extraFilters,
    defaultFilters,
    defaultSortFieldId,
    defaultSortDirection,
    backTableId,
}: {
    useGetData: (params: GridHookParams) => any;
    canDisable?: boolean;
    clientSidePagination?: boolean;
    adjustHeight?: number;
    canSearch?: boolean;
    extraFilters?: Array<ExtraQueryFiltersProps>;
    defaultFilters?: Array<ExtraQueryFiltersProps>;
    defaultSortFieldId?: string;
    defaultSortDirection?: 'asc' | 'desc';
    backTableId?: number;
}) => {
    const { showDisabled, setNeedRefresh, needRefresh } = useCommonGrid();
    const { matchesSm } = useBreakpoints();
    const pageSize: number =
        Math.ceil((window.innerHeight - (adjustHeight || 0) - (matchesSm ? 350 : 260)) / 30) - 1;
    const [page, setPage] = React.useState<number>(1);
    const [searchText, setSearchText] = React.useState('');
    const [filterHeaderString, setFilterHeaderString] = React.useState<string>(
        defaultFilters
            ? `&${defaultFilters.map(filter => `${filter.query}=${filter.queryValue}`).join('&')}`
            : '',
    );
    const [sort, setSort] = React.useState({
        column: defaultSortFieldId || '',
        sortDirection: defaultSortDirection || 'desc',
    } as SortInformation);

    React.useEffect(() => {
        setPage(1);
    }, [filterHeaderString]);

    const filterQueryString = React.useMemo(() => {
        let extraQueryFilters = '';
        if (!clientSidePagination) extraQueryFilters = `&page=${page}&pageSize=${pageSize}`;
        if (sort.column)
            extraQueryFilters += `&sortField=${sort.column}&sort=${sort.sortDirection.toUpperCase()}`;
        if (canDisable) extraQueryFilters += `&showDisabled=${showDisabled}`;
        if (canSearch) extraQueryFilters += `&searchText=${searchText}`;
        extraQueryFilters += filterHeaderString;
        extraFilters?.forEach((element: ExtraQueryFiltersProps) => {
            extraQueryFilters += `&${element.query}=${element.queryValue}`;
        });
        return extraQueryFilters;
    }, [page, pageSize, sort, showDisabled, filterHeaderString, extraFilters, searchText]);

    const {
        data: dataFromQuery,
        isLoading: loading,
        refetch,
    } = useGetData({
        filterQueryString,
        extraFilters: extraFilters ?? [],
        forceDisable: false,
        backTableId,
    } as GridHookParams);

    const [data, totalRows] = React.useMemo(() => {
        if (dataFromQuery)
            return [dataFromQuery?.data ?? dataFromQuery, dataFromQuery?.total_count];
        else return [[], 0];
    }, [dataFromQuery]);

    const handlePageChange = (pag: number) => {
        setPage(pag);
    };

    const handleSort = (sortInfo: SortInformation) => setSort(sortInfo);

    React.useEffect(() => {
        if (needRefresh) {
            setNeedRefresh(false);
            refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needRefresh]);
    return {
        data,
        handlePageChange,
        handleSort,
        loading,
        pageSize,
        setSearchText,
        totalRows,
        refetch,
        sortInfo: sort,
        filterHeaderString,
        setFilterHeaderString,
    };
};

export default useCustomFetch;
