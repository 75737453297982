import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CurrencyPositionPage from './ReportsCurrencyPositionPage';
import { useTranslation } from 'react-i18next';
import { CommonColumn, CommonFilter } from 'types/common/tableLayout.types';
import { AuthContext } from 'context/auth.context';
import { CurrencyPosition } from 'types/pages/reports.types';
import { formatNumber, getFullName } from 'utils/helpers/commonHelper';
import { Box, Tooltip } from '@mui/material';
import { MARKET_ACCOUNT_BASE_URL } from 'constants/appConstants';

const ReportsCurrencyPositionPageContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('reports');
    const tBase = 'channel_currency_position_';
    const { customerCode } = React.useContext(AuthContext);

    const columns: CommonColumn<CurrencyPosition>[] = [
        {
            id: 'customerCode',
            name: t(`${tBase}column_customer_code`),
            selector: ({ user, customerCode }) => (
                <Tooltip title={getFullName(user)} placement="right">
                    <Box sx={{ width: 'fit-content' }}>{customerCode}</Box>
                </Tooltip>
            ),
            exportOption: row => row.customerCode ?? '-',
        },
        {
            id: 'abbreviation',
            name: t(`${tBase}column_abbreviation`),
            selector: row => row.abbreviation ?? '-',
            exportOption: row => row.abbreviation ?? '-',
        },
        {
            id: 'description',
            name: t(`${tBase}column_description`),
            selector: row => row.description ?? '-',
            exportOption: row => row.description ?? '-',
        },
        {
            id: 'amountDue',
            name: t(`${tBase}column_amount_due`),
            selector: row => (
                <span
                    style={{
                        color: row.amountDue > 0 ? 'green' : row.amountDue < 0 ? 'red' : 'inherit',
                    }}>
                    {row.amountDue ? formatNumber(row.amountDue) : '-'}
                </span>
            ),
            exportOption: row => (row.amountDue ? formatNumber(row.amountDue) : '-'),
        },
        {
            id: 'warrantyAmount',
            name: t(`${tBase}column_warranty_amount`),
            selector: row => formatNumber(row?.warrantyAmount || 0),
            exportOption: row => formatNumber(row?.warrantyAmount || 0),
        },
    ];

    const filters: CommonFilter[] = [
        {
            id: 'options',
            label: t(`reports_tickets_page_currency_value`),
            type: 'select',
            query: 'options',
            options: [
                { id: '', name: t(`filter_option_all`) },
                { id: 'POSITIVE', name: t(`filter_option_positive_position`) },
                { id: 'NEGATIVE', name: t(`filter_option_negative_position`) },
                { id: 'IN_WARRANTY', name: t(`filter_option_warranty`) },
            ],
        },
        {
            id: 'currency',
            label: t(`reports_tickets_page_currency_type`),
            type: 'select',
            query: 'currency',
            options: [
                { id: '', name: t(`filter_option_all`) },
                { id: 'ARS', name: 'ARS' },
                { id: 'USD', name: 'USD' },
                { id: 'USD CABLE', name: 'USD CABLE' },
                { id: 'USD 10000', name: 'USD 10000' },
                { id: 'USD 7000', name: 'USD 7000' },
                { id: 'ARS 8000', name: 'ARS 8000' },
                { id: 'DOLAR 20000', name: 'Dolar 20000' },
                { id: 'USDC OP', name: 'USDC OP' },
                { id: 'USDC EXT', name: 'USDC EXT' },
                { id: 'USDM OP', name: 'USDM OP' },
                { id: 'USDM T', name: 'USDM T' },
                { id: 'USDC T', name: 'USDC T' },
            ],
        },
        {
            id: 'customerCode',
            label: t('filter_by_customer_code'),
            type: 'requestAutocomplete',
            query: 'customerCodes',
            requestAutocomplete: {
                requestUrl: `${MARKET_ACCOUNT_BASE_URL}/market-accounts`,
                param: 'customerCode',
                optionLabel: option => option.customerCode,
                keyTofilter: 'customerCode',
            },
        },
        {
            id: 'client',
            label: t('filter_by_customer_name'),
            type: 'requestAutocomplete',
            query: 'customerCodes',
            requestAutocomplete: {
                requestUrl: `${MARKET_ACCOUNT_BASE_URL}/market-accounts`,
                param: 'customerFullName',
                optionLabel: option => `${option.name} ${option.last_name}`,
                keyTofilter: 'name',
                extraFilter: '&sortField=name&sort=ASC',
            },
        },
    ];

    const defaultFilters = [
        { query: 'options', queryValue: '' },
        { query: 'currency', queryValue: '' },
    ];

    const childProps = {
        ...props,
        columns,
        filters,
        customerCode,
        defaultFilters,
        t,
    };

    return <CurrencyPositionPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ReportsCurrencyPositionPageContainer.propTypes = propTypes;

export default ReportsCurrencyPositionPageContainer;
