import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
// import St from './MarketExchangePage.styled';
import TableLayout from 'components/layouts/TableLayout';
import { ExchangeOrderItem } from 'types/api/orders.types';
import { CommonAction, CommonColumn, CommonFilter } from 'types/common/tableLayout.types';
import { useGetExchangeOrders } from 'hooks/api/orders.hooks';
import { GetMarketTimesResponse } from 'types/api/instruments.types';
import CloseMarketModal from 'components/common/CloseMarketModal';
import { ExtraQueryFiltersProps } from 'types/common/CommonGrid/CommonGridFormModal.types';

const MarketExchangePage = (props: Props) => {
    const {
        t,
        columns,
        filters,
        actions,
        headAction,
        exchangeTimes,
        openCloseModal,
        isMarketClosed,
        defaultFilters,
        setOpenCloseModal,
    } = props;
    return (
        <>
            <TableLayout
                columns={columns}
                filters={filters}
                actions={actions}
                createAction={headAction}
                defaultFilters={defaultFilters}
                fetchData={useGetExchangeOrders}
                title={t('market_exchange_page_title')}
                defaultSortFieldId="createdAt"
            />
            {exchangeTimes != null &&
                isMarketClosed(
                    exchangeTimes?.t0OpenTime,
                    exchangeTimes?.t0CloseTime,
                    exchangeTimes.isMarketOpen,
                ) && (
                    <CloseMarketModal
                        open={openCloseModal}
                        close={() => setOpenCloseModal(false)}
                        from={exchangeTimes?.t0OpenTime}
                        to={exchangeTimes?.t0CloseTime}
                    />
                )}
        </>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    openCloseModal: PropTypes.bool.isRequired,
    setOpenCloseModal: PropTypes.func.isRequired,
};

interface extraProps {
    filters: CommonFilter[];
    columns: CommonColumn<ExchangeOrderItem>[];
    actions: CommonAction<ExchangeOrderItem>[];
    exchangeTimes: GetMarketTimesResponse | null;
    isMarketClosed: (openTime: string, closeTime: string, isMarketOpen: boolean) => boolean;
    defaultFilters: ExtraQueryFiltersProps[];
    headAction: CommonAction<ExchangeOrderItem>;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MarketExchangePage.propTypes = propTypes;

export default MarketExchangePage;
