import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './ProfilePreferencesPage.styled';
import CustomRadioButton from '../OnboardingComplementaryDataPage/ComponentLi/CustomRadioButton';
import CustomModalAuth from 'components/common/CustomModalAuth';
import EnableMfaModal from 'components/common/EnableMfaModal';

const ProfilePreferencesPage = (props: Props) => {
    const {
        t,
        handleClick,
        mfaType,
        pushNotifications,
        open,
        handleClose,
        functionUseEffectDisable,
        sendCodeDisableMfa,
        SnackBar,
        errorDisable,
        isLoadingDisable,
        setSnackBarMessage,
    } = props;

    return (
        <>
            <St.SectionWrapper component={'section'} data-testid="PreferencesPage-page">
                <St.Li>
                    <St.FirstWrapper>
                        <St.Box>
                            <St.Text>{t('preferences_mfa_title')}</St.Text>
                            <St.Tooltip title={t('preferences_mfa_tooltip')}>
                                <St.IconButton>
                                    <St.QuestionMarkRadiusIcon />
                                </St.IconButton>
                            </St.Tooltip>
                        </St.Box>
                    </St.FirstWrapper>

                    <St.SecondWrapper>
                        <CustomRadioButton
                            name={'mfa'}
                            value={mfaType === 'EMAIL' || mfaType === 'AUTHENTICATOR'}
                            handleClick={() => handleClick('mfa', true)}
                            text={t('yes')}
                        />
                        <CustomRadioButton
                            name={'mfa'}
                            value={mfaType === 'DISABLED'}
                            handleClick={() => handleClick('mfa', false)}
                            text="No"
                        />
                    </St.SecondWrapper>
                </St.Li>
                <St.Li>
                    <St.FirstWrapper>
                        <St.Box>
                            <St.Text>{t('preferences_notifications')}</St.Text>
                        </St.Box>
                    </St.FirstWrapper>

                    <St.SecondWrapper>
                        <CustomRadioButton
                            name={'notifications'}
                            value={pushNotifications !== null}
                            handleClick={() => handleClick('notifications', true)}
                            text={t('yes')}
                        />
                        <CustomRadioButton
                            name={'notifications'}
                            value={!pushNotifications}
                            handleClick={() => handleClick('notifications', false)}
                            text="No"
                        />
                    </St.SecondWrapper>
                </St.Li>
                {open === 'enableMfa' && (
                    <EnableMfaModal
                        isPreferences
                        close={handleClose}
                        open={open === 'enableMfa'}
                        setSnackBarMessage={setSnackBarMessage}
                    />
                )}
                {open === 'disableMfa' && (
                    <CustomModalAuth
                        handleClose={handleClose}
                        open={open === 'disableMfa'}
                        sendEmailCode={functionUseEffectDisable}
                        handleSubmit={sendCodeDisableMfa}
                        apiError={errorDisable}
                        isLoadingSubmit={isLoadingDisable}
                    />
                )}
            </St.SectionWrapper>
            <SnackBar />
        </>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    setSnackBarMessage: PropTypes.func.isRequired,
    isLoadingDisable: PropTypes.bool.isRequired,
};

interface extraProps {
    handleClick: (name: string, value: boolean) => void;
    mfaType: null | string;
    pushNotifications: null | boolean;
    open: string;
    handleClose: () => void;
    functionUseEffectDisable: () => void;
    sendCodeDisableMfa: (otp: string) => void;
    SnackBar: () => JSX.Element;
    errorDisable: string | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ProfilePreferencesPage.propTypes = propTypes;

export default ProfilePreferencesPage;
