import { Box, styled, Typography } from '@mui/material';

export default {
    CarouselItemContainer: styled(Box)({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        position: 'relative',
        width: '100%',
    }),
    ImageContainer: styled(Box)(() => ({
        position: 'relative',
        width: '100%',
        marginBottom: '40px',
    })),
    Image: styled('img')(({ theme }) =>
        theme.unstable_sx({
            width: '98%',
            height: '220px',
            objectFit: 'cover',
        }),
    ),
    TitleOverlay: styled(Box)(() => ({
        position: 'absolute',
        bottom: 0,
        width: '100%',
        padding: '10px',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
    })),
    AvailabeByText: styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            color: theme.palette.custom.darkblue,
            marginTop: '10px',
            textAlign: 'center',
        }),
    ),
};
