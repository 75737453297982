import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import OnboardingInvestmentTestResultPage from './OnboardingInvestmentTestResultPage';
import { useTranslation } from 'react-i18next';
import { AuthContext } from 'context/auth.context';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetInvestmentProfileFeatures } from 'hooks/common/users.hooks';
import { InvestmentTestValues } from 'types/common/users.types';
import { setUserDataStorage } from 'storage/auth.storage';
import { UserInfoLoginResponse } from 'types/api/auth.types';
import { mainRoutesPaths, onboardingRoutesPaths } from 'router/routesPaths';

const OnboardingInvestmentTestResultPageContainer = (props: Props) => {
    // const { prevTest } = props;
    const { t } = useTranslation('onboarding');
    const { userData, setUserData, isTestExpired, setIsTestExpired } =
        React.useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();
    const isOnboarding = userData?.onboardingPending ?? false;

    const { investorFeatures } = useGetInvestmentProfileFeatures(userData?.info?.investmentProfile);

    const onClickCompleteInvestmentTest = () => {
        const { response } = location.state;
        if (isOnboarding) return navigate(onboardingRoutesPaths.home);
        if (userData?.info) {
            userData.info.investmentProfile = response.investmentProfile;

            const today = new Date();
            const nextYear = new Date(today);
            nextYear.setFullYear(today.getFullYear() + 1);

            const updatedInfo: UserInfoLoginResponse = {
                ...userData.info,
                investmentProfile: response.investmentProfile,
                investorProfileUpdatedAt: today.toISOString(),
                investorProfileExpirationDate: nextYear.toISOString(),
            };

            setUserData({ ...userData, info: updatedInfo });
            setUserDataStorage({ ...userData, info: updatedInfo });
            setIsTestExpired(false);
            navigate(mainRoutesPaths.homeRecommendedWallet);
        }
    };

    const childProps = {
        ...props,
        t,
        userData,
        onClickCompleteInvestmentTest,
        isOnboarding,
        investorFeatures,
        investmentProfile:
            location?.state?.response.investmentProfile.toLowerCase() ??
            (userData?.info?.investmentProfile?.toLowerCase() as InvestmentTestValues) ??
            null,
        navigate,
        isTestExpired,
    };

    return <OnboardingInvestmentTestResultPage {...childProps} />;
};

const propTypes = {
    prevTest: PropTypes.bool,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OnboardingInvestmentTestResultPageContainer.propTypes = propTypes;

export default OnboardingInvestmentTestResultPageContainer;
