import { Box, Typography, styled } from '@mui/material';

export default {
    SectionWrapper: styled(Box)(() => ({
        color: 'white',
        flex: 1,
        margin: '0 auto',
        justifyItems: 'center',
        maxWidth: '90%',
        overflowX: 'hidden',
        '& .swiper-button-prev, & .swiper-button-next': {
            color: 'white',
            '&::after': {
                fontSize: '24px',
            },
        },
        '& .swiper-pagination-bullet': {
            backgroundColor: 'white',
            opacity: 0.5,
        },
        '& .swiper-pagination-bullet-active': {
            opacity: 1,
        },
    })) as typeof Box,

    LatestSection: styled(Box)(() => ({
        width: '100%',
        position: 'relative',
        margin: '0 auto 4rem auto',
        overflow: 'hidden',
        '& .swiper': {
            height: '70vh',
            '@media (max-width: 768px)': {
                height: '40vh',
            },
        },
    })),

    CarouselItemContainer: styled(Box)({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        position: 'relative',
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
    }),

    LatestImageContainer: styled(Box)(() => ({
        position: 'relative',
        width: '100%',
        height: '100%',
    })),

    Image: styled('img')({
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    }),

    TitleOverlay: styled(Box)(() => ({
        position: 'absolute',
        bottom: 0,
        width: '100%',
        padding: '10px',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
    })),

    LatestTitle: styled(Typography)(() => ({
        color: 'white',
        fontSize: '2rem',
        fontWeight: 'bold',
        marginBottom: '1rem',
        textAlign: 'left',
        '@media (max-width: 1024px)': {
            fontSize: '1.5rem',
        },
        '@media (max-width: 768px)': {
            fontSize: '1.2rem',
        },
    })),

    CategoryCarouselsSection: styled(Box)(() => ({
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
    })),

    CategoryTitle: styled(Typography)(() => ({
        color: 'primary',
        fontSize: '2rem',
        display: 'block',
        textAlign: 'left',
        padding: '10px 0',
        '@media (max-width: 768px)': {
            fontSize: '1.5rem',
        },
    })),

    CategorySection: styled(Box)<{ $matchessm?: boolean; $matchesmd?: boolean }>(
        ({ theme, $matchessm, $matchesmd }) =>
            theme.unstable_sx({
                '& .carousel .control-dots': {
                    display: $matchessm || $matchesmd ? 'none' : 'inline-block',
                },
                '& .carousel-root': {
                    marginTop: '-30px',
                    marginBottom: '-30px',
                },
            }),
    ),

    ArticleContainer: styled(Box)(() => ({
        position: 'relative',
        width: '100%',
        aspectRatio: '16/9',
        borderRadius: '8px',
        overflow: 'hidden',
        backgroundColor: '#1a1a1a',
    })),

    ArticleImage: styled('img')({
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    }),

    ArticleTitle: styled(Typography)(() => ({
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        padding: '10px',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        color: 'white',
        fontSize: '1rem',
        fontWeight: 'bold',
        '@media (max-width: 768px)': {
            fontSize: '0.9rem',
        },
    })),
};
