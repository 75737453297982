import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import AccountStatus from './AccountStatus';
import { useTranslation } from 'react-i18next';
import { tCommon } from 'constants/appConstants';
import { formatNumberMilis } from 'utils/helpers/commonHelper';
import { useEffect, useState } from 'react';
import { AuthContext } from 'context/auth.context';
import { GetAccountStatusResponse } from 'types/api/marketAccount.types';

const AccountStatusContainer = (props: Props) => {
    const {
        setSelectedInstrumentType = () => {},
        accountStatusResponse,
        isLoadingAccountStatusResponse,
        liquidation = false,
        setLiquidation = () => {},
    } = props;
    const { t } = useTranslation('home');
    const { userType, marketAccounts } = React.useContext(AuthContext);
    const isUserApAc = userType === 'CHANNEL' || userType === 'ASSISTANT';
    const [concertation, setConcertation] = useState<boolean>(true);
    const series = accountStatusResponse?.instruments.map(i => i.percentage) ?? [];
    const labels = accountStatusResponse?.instruments.map(i => t(i.instrumentType, tCommon)) ?? [];
    const total = React.useMemo(() => {
        if (!accountStatusResponse?.aum) return '0';
        return formatNumberMilis(accountStatusResponse.aum.total.ars);
    }, [accountStatusResponse]);
    const [openAvailableBalanceDetailModal, setOpenAvailableBalanceDetailModal] =
        React.useState<boolean>(false);

    const [openAumDetailModal, setOpenAumDetailModal] = React.useState<boolean>(false);

    useEffect(() => {
        setConcertation(!liquidation);
    }, [liquidation]);

    const childProps = {
        ...props,
        aum: accountStatusResponse?.aum,
        availableBalance: accountStatusResponse?.availableBalance,
        performance: accountStatusResponse?.performance,
        isLoading: isLoadingAccountStatusResponse,
        series,
        labels,
        total,
        concertation,
        setConcertation,
        liquidation,
        setLiquidation,
        setSelectedInstrumentType,
        data: accountStatusResponse,
        marketAccounts,
        isUserApAc,
        t,
        openAvailableBalanceDetailModal,
        setOpenAvailableBalanceDetailModal,
        openAumDetailModal,
        setOpenAumDetailModal,
        detailedAum: accountStatusResponse?.aum.detailed,
    };

    return <AccountStatus {...childProps} />;
};

const propTypes = {
    isWallet: PropTypes.bool,
};

interface extraProps {
    setSelectedInstrumentType?: React.Dispatch<React.SetStateAction<string>>;
    accountStatusResponse?: GetAccountStatusResponse;
    isLoadingAccountStatusResponse: boolean;
    liquidation?: boolean;
    setLiquidation?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
AccountStatusContainer.propTypes = propTypes;

export default AccountStatusContainer;
