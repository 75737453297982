import { Box, IconButton, styled, Tooltip, Typography } from '@mui/material';
import ErrorText from '../ErrorText';
import { LoadingButton } from '@mui/lab';

export default {
    FileUploaderWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            width: '100%',
            display: 'flex',
            alignItems: { xs: 'flex-start', sm: 'center' },
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between',
            gap: '1rem',
            paddingBottom: '1.75rem',
            borderBottom: `1px dashed ${theme.palette.custom.darkblue7}`,
            '.isDelete': {
                color: theme.palette.custom.darkblue3,
                background: 'transparent',
            },
        }),
    ),
    BoxWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            gap: '.5rem',
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: 'center',
            margin: { xs: 'auto', sm: '0' },
        }),
    ),
    LoadingButton: styled(LoadingButton)(({ theme }) =>
        theme.unstable_sx({
            fontSize: '0.75rem',
            fontWeight: '400',
            lineHeight: '14.06px',
            letterSpacing: '0.016em',
            color: theme.palette.background.default,
            background: theme.palette.custom.darkblue7,
            border: `1px solid ${theme.palette.custom.darkblue7}`,
            borderRadius: '28px',
            padding: '0 !important',
            overflow: 'hidden',
            width: { xs: '100%', sm: '104px' },
            height: '38px',
            transition: '0.2s',
            '&:hover': { background: theme.palette.custom.darkblue7, opacity: '0.8' },
        }),
    ) as typeof LoadingButton,
    Label: styled('label')(({ theme }) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        textTransform: 'uppercase',
        cursor: 'pointer',
    })),
    Tooltip: styled(Tooltip)(({ theme }) => ({
        padding: '0 .5rem',
        borderRadius: '2px',
        cursor: 'pointer',
    })),
    TooltipContentWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            gap: '1rem',
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: 'center',
        }),
    ),
    IconButtonSvg: styled(IconButton)(({ theme }) => ({
        display: 'flex',
        gap: '0.5rem',
        padding: '0',
        svg: {
            width: '48px',
            height: '48px',
            color: theme.palette.custom.vibrantblue,
        },
    })) as typeof IconButton,
    Typography: styled(Typography)(({ theme }) => ({
        fontSize: '18px',
        fontWeight: '500',
        lineHeight: '28.13px',
        color: theme.palette.custom.darkblue2,
    })),
    FileName: styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            fontSize: '1.125rem',
            fontWeight: '400',
            lineHeight: '21.09px',
            letterSpacing: '0.0015em',
            textAlign: { xs: 'center', sm: 'left' },
            color: theme.palette.custom.darkblue2,
        }),
    ) as typeof Typography,
    ErrorText: styled(ErrorText)(() => ({})),
};
