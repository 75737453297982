import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './BlogPage.styled';
import { Box, Grid } from '@mui/material';
import CircularSpinner from 'components/common/CircularSpinner';
import { BlogArticle } from 'types/api/wrapper.types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';

const BlogPage = (props: Props) => {
    const {
        groupedDataByCategory,
        isLoading,
        dataLatestArray,
        isLoadingLatest,
        matchesSm,
        matchesMd,
    } = props;

    return (
        <St.SectionWrapper component={'section'} data-testid="BlogPage-page">
            {isLoadingLatest && isLoading ? (
                <Box sx={{ marginTop: '10px', overflow: 'hidden' }}>
                    <CircularSpinner />
                </Box>
            ) : (
                <>
                    {dataLatestArray && (
                        <St.LatestSection>
                            <Swiper
                                modules={[Navigation, Pagination, Autoplay]}
                                navigation
                                pagination={{ clickable: true }}
                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: false,
                                }}
                                slidesPerView={1}
                                spaceBetween={0}
                                loop={true}
                                style={{ width: '100%' }}>
                                {dataLatestArray.map((article: BlogArticle, index: number) => (
                                    <SwiperSlide key={index}>
                                        <St.CarouselItemContainer>
                                            <St.LatestImageContainer
                                                key={article.id}
                                                onClick={() =>
                                                    window.open(article.mobUrl, '_blank')
                                                }
                                                sx={{ cursor: 'pointer' }}>
                                                <St.Image
                                                    src={article.mobImagenGrandeHeader}
                                                    alt={article.mobTitulo}
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'cover',
                                                    }}
                                                />
                                                <St.TitleOverlay>
                                                    <St.LatestTitle>
                                                        {article.mobTitulo}
                                                    </St.LatestTitle>
                                                </St.TitleOverlay>
                                            </St.LatestImageContainer>
                                        </St.CarouselItemContainer>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </St.LatestSection>
                    )}

                    {groupedDataByCategory && groupedDataByCategory.length > 0 && (
                        <>
                            {groupedDataByCategory.map((categoryGroup, categoryIndex) => (
                                <St.CategoryCarouselsSection key={categoryIndex}>
                                    <St.CategoryTitle variant="h5" color="primary">
                                        {categoryGroup.category}
                                    </St.CategoryTitle>
                                    <St.CategorySection
                                        $matchessm={matchesSm || undefined}
                                        $matchesmd={matchesMd || undefined}>
                                        <Swiper
                                            modules={[Navigation, Pagination]}
                                            navigation
                                            pagination={{
                                                clickable: true,
                                                el: `.pagination-${categoryIndex}`,
                                            }}
                                            slidesPerView={1}
                                            spaceBetween={20}
                                            loop={true}>
                                            {matchesSm || matchesMd
                                                ? categoryGroup.groups
                                                      .flat()
                                                      .map(
                                                          (
                                                              article: BlogArticle,
                                                              articleIndex: number,
                                                          ) => (
                                                              <SwiperSlide key={articleIndex}>
                                                                  <St.ArticleContainer
                                                                      onClick={() =>
                                                                          window.open(
                                                                              article.mobUrl,
                                                                              '_blank',
                                                                          )
                                                                      }
                                                                      sx={{ cursor: 'pointer' }}>
                                                                      <St.ArticleImage
                                                                          src={
                                                                              article.mobImagenGrandeHeader
                                                                          }
                                                                          alt={article.mobTitulo}
                                                                      />
                                                                      <St.ArticleTitle>
                                                                          {article.mobTitulo}
                                                                      </St.ArticleTitle>
                                                                  </St.ArticleContainer>
                                                              </SwiperSlide>
                                                          ),
                                                      )
                                                : categoryGroup.groups.map((group, groupIndex) => (
                                                      <SwiperSlide key={groupIndex}>
                                                          <Grid container spacing={2}>
                                                              {group.map((article, index) => (
                                                                  <Grid
                                                                      item
                                                                      xs={12}
                                                                      sm={6}
                                                                      md={3}
                                                                      key={index}>
                                                                      <St.ArticleContainer
                                                                          onClick={() =>
                                                                              window.open(
                                                                                  article.mobUrl,
                                                                                  '_blank',
                                                                              )
                                                                          }
                                                                          sx={{
                                                                              cursor: 'pointer',
                                                                          }}>
                                                                          <St.ArticleImage
                                                                              src={
                                                                                  article.mobImagenGrandeHeader
                                                                              }
                                                                              alt={
                                                                                  article.mobTitulo
                                                                              }
                                                                          />
                                                                          <St.ArticleTitle>
                                                                              {article.mobTitulo}
                                                                          </St.ArticleTitle>
                                                                      </St.ArticleContainer>
                                                                  </Grid>
                                                              ))}
                                                          </Grid>
                                                      </SwiperSlide>
                                                  ))}
                                        </Swiper>
                                        <div className={`pagination-${categoryIndex}`} />
                                    </St.CategorySection>
                                </St.CategoryCarouselsSection>
                            ))}
                        </>
                    )}
                </>
            )}
        </St.SectionWrapper>
    );
};

const propTypes = {
    isLoading: PropTypes.bool.isRequired,
    dataLatestArray: PropTypes.array as PropTypes.Requireable<BlogArticle[]>,
    groupedDataByCategory: PropTypes.arrayOf(
        PropTypes.shape({
            category: PropTypes.string.isRequired,
            groups: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)).isRequired,
        }),
    ),
    isLoadingLatest: PropTypes.bool.isRequired,
    matchesSm: PropTypes.bool.isRequired,
    matchesMd: PropTypes.bool.isRequired,
};

interface extraProps {
    groupedDataByCategory?: { category: string; groups: BlogArticle[][] }[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
BlogPage.propTypes = propTypes;

export default BlogPage;
