import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Outlet } from 'react-router-dom';
import HelmetLayout from '../HelmetLayout';
import NavBarLayout from '../NavBarLayout';
import { SubRouteInfo } from 'utils/helpers/routesHelper';
import FooterLayout from '../FooterLayout';
import HelpTooltip from 'components/common/HelpTooltip';
import ChannelLayout from '../ChannelLayout';
import TermsConditionsModal from 'components/common/TermsConditionsModal';
import MarketContextProvider from 'context/market.context';
import St from './MainLayout.styled';
import MobileLayout from '../MobileLayout';
import EnableMfaModal from 'components/common/EnableMfaModal';
import LockedFunctionalityModal from 'components/common/LockedFunctionalityModal';
import AlertsNotificationModal from 'components/common/AlertNotificationsModal';
import { AletsObj } from 'types/api/alerts.types';

const MainLayout = (props: Props) => {
    const {
        routes,
        forceMFA,
        isMobile,
        isChannel,
        isTestExpired,
        isTermsAccepted,
        hasAccessToChannelLayout,
        setOpenAlertModal,
        openAlertModal,
        alertNotification,
        isLoadingAlert,
        errorMessageAlert,
    } = props;

    return (
        <MarketContextProvider>
            <HelmetLayout withOutlet={false} />
            <St.MainWrapper component="main">
                {isMobile ? <MobileLayout routes={routes} /> : <NavBarLayout routes={routes} />}
                {isChannel && hasAccessToChannelLayout && <ChannelLayout isMobile={isMobile} />}
                <St.SectionWrapper component="section">
                    <St.PageWrapper>
                        <Outlet />
                    </St.PageWrapper>
                </St.SectionWrapper>
                <FooterLayout />
                {alertNotification && alertNotification?.length > 0 ? (
                    <AlertsNotificationModal
                        open={openAlertModal}
                        setOpenModal={setOpenAlertModal}
                        filteredAlert={alertNotification}
                        errorMessage={errorMessageAlert}
                        isLoading={isLoadingAlert}
                    />
                ) : (
                    <>
                        {!isTermsAccepted && <TermsConditionsModal open={!isTermsAccepted} />}
                        {!isTestExpired && <HelpTooltip />}
                    </>
                )}
                {forceMFA && isTermsAccepted && (
                    <EnableMfaModal open={forceMFA && isTermsAccepted} />
                )}
                <LockedFunctionalityModal />
            </St.MainWrapper>
        </MarketContextProvider>
    );
};

const propTypes = {
    isTestExpired: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    isChannel: PropTypes.bool,
    forceMFA: PropTypes.bool.isRequired,
    hasAccessToChannelLayout: PropTypes.bool.isRequired,
};

interface extraProps {
    routes: Array<SubRouteInfo>;
    isTermsAccepted: boolean;
    setOpenAlertModal: (x: boolean) => void;
    openAlertModal: boolean;
    alertNotification: AletsObj[] | undefined;
    errorMessageAlert: string | null;
    isLoadingAlert: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MainLayout.propTypes = propTypes;

export default MainLayout;
