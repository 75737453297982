import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import OnboardingComplementaryDataPage from './OnboardingComplementaryDataPage';
import * as Yup from 'yup';
import { tErrorsContext, tRequiredFieldError } from 'constants/appConstants';
import React from 'react';
import { FormikConfig, FormikValues, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
    useComplementaryDataPage,
    useComplementaryDataPost,
    useGetNationalities,
    useGetWorkActivities,
} from 'hooks/api/onboarding.hooks';
import { ComplementaryDataBody, PreloadedComplementaryData } from 'types/api/onboarding.types';
import i18n from 'utils/i18n';
import { useNavigate } from 'react-router-dom';
import { OnboardingContext } from 'context/onboarding.context';
import { onboardingRoutesPaths } from 'router/routesPaths';
// ---------------------------------------------//
// ------------------- FORMIK ------------------//
// ---------------------------------------------//
const getInitialValues = (data: PreloadedComplementaryData | null) => ({
    PEP: data?.PEP || false,
    isUIF: data?.isUIF || false,
    isUSA: data?.isUSA || false,
    OCDE: data?.OCDE || false,
    pepCharge: data?.pepCharge || '',
    ssn: data?.ssn || '',
    countryTaxResidence: data?.countryTaxResidence || null,
    taxCondition: data?.taxCondition || null,
    earningsRegistration: data?.earningsRegistration || null,
    activityId: data?.activityId || null,
});

const getValidationSchema = () =>
    Yup.lazy(() =>
        Yup.object().shape({
            PEP: Yup.boolean(),
            pepCharge: Yup.string().when('PEP', {
                is: true,
                then: Yup.string().required(tRequiredFieldError),
                otherwise: Yup.string(),
            }),
            ssn: Yup.string().when('isUSA', {
                is: true,
                then: Yup.string()
                    .required(tRequiredFieldError)
                    .matches(/^\d{9}$/, i18n.t('ssn_error_message', tErrorsContext) as string),
                otherwise: Yup.string(),
            }),
            activityId: Yup.object().required(tRequiredFieldError),
            earningsRegistration: Yup.object().required(tRequiredFieldError),
        }),
    );
// ---------------------------------------------//
// ---------------------------------------------//
// ---------------------------------------------//

export const OnboardingComplementaryDataPageContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('onboarding');
    const { isLoadingLegalData, isLoadingSteps, userInformationUploaded } =
        React.useContext(OnboardingContext);
    const [preloadeData, setPreloadeData] = React.useState<PreloadedComplementaryData | null>(null);
    const navigate = useNavigate();
    const { itemsLi, VATConditionArticles, incomeRegistrationArticles } =
        useComplementaryDataPage(t);
    const { data: countriesList, isLoading: isLoadingNationalities } = useGetNationalities();
    const { data: workActivities, isLoading: isLoadingWorkActivities } = useGetWorkActivities();
    const { mutate, isLoading: isLoadingSubmit, errorMessage } = useComplementaryDataPost();

    const handleSubmit = React.useCallback(
        async (values: FormikValues) => {
            const { OCDE, PEP, pepCharge, countryTaxResidence } = values;
            const formData = {
                ...values,
                customerType: `Persona Fisica ${
                    OCDE && countryTaxResidence.id === 7 ? 'Local' : 'Extranjera'
                }`,
                ssn: values.isUSA ? values.ssn : '',
                pepCharge: PEP ? pepCharge : '',
                countryTaxResidence: OCDE ? countryTaxResidence.name : '',
                taxCondition: values.taxCondition.name,
                earningsRegistration: values.earningsRegistration.name,
                activityId: values.activityId.id,
            } as ComplementaryDataBody;
            const config = {
                onSuccess: () => {
                    navigate(onboardingRoutesPaths.home);
                },
            };
            mutate(formData, config);
        },
        [mutate, navigate],
    );

    const formikInitProps = React.useMemo(
        () =>
            ({
                initialValues: getInitialValues(preloadeData),
                validateOnChange: false,
                validateOnBlur: false,
                validateOnMount: false,
                validationSchema: getValidationSchema(),
                enableReinitialize: true,
                onSubmit: handleSubmit,
            }) as FormikConfig<FormikValues>,
        [handleSubmit, preloadeData],
    );

    const handleDownload = () => {
        const fileUrl = '/assets/activities_detail.xlsx';
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = t('complementary_data_activities_detail');
        link.click();
    };

    const formik = useFormik(formikInitProps);
    const { values } = formik;
    const { activityId, taxCondition, earningsRegistration, OCDE, countryTaxResidence } = values;

    React.useEffect(() => {
        if (userInformationUploaded && workActivities && countriesList) {
            const {
                taxCondition,
                earningsRegistration,
                countryTaxResidence,
                OCDE,
                PEP,
                activityId,
                isUIF,
                isUSA,
                pepCharge,
                ssn,
            } = userInformationUploaded.personalInfo;

            setPreloadeData({
                PEP,
                isUIF,
                isUSA,
                OCDE,
                pepCharge,
                ssn,
                countryTaxResidence: countriesList.find(el => el.name === countryTaxResidence),
                taxCondition: VATConditionArticles.find(el => el.name === taxCondition),
                earningsRegistration: incomeRegistrationArticles.find(
                    el => el.name === earningsRegistration,
                ),
                activityId: workActivities.find(el => el.id === activityId),
            });
        }
    }, [userInformationUploaded, workActivities]);

    const childProps = {
        t,
        formik,
        enableButton:
            activityId &&
            taxCondition &&
            earningsRegistration &&
            (OCDE ? !!countryTaxResidence : true),
        itemsLi,
        VATConditionArticles,
        incomeRegistrationArticles,
        workActivities,
        countriesList,
        handleDownload,
        isLoadingSubmit,
        errorMessage,
        isLoading:
            isLoadingLegalData ||
            isLoadingSteps ||
            isLoadingWorkActivities ||
            isLoadingNationalities,
    };
    return <OnboardingComplementaryDataPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OnboardingComplementaryDataPageContainer.propTypes = propTypes;

export default OnboardingComplementaryDataPageContainer;
