import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { FormikContextType, FormikProvider } from 'formik';
import PasswordField from 'components/common/PasswordField';
import ActionButton from 'components/common/ActionButton';
import { tCommon } from 'constants/appConstants';
import St from './ProfileChangePasswordPage.styled';

const ProfileChangePasswordPage = ({ t, formik, isLoading, SnackBar, handleGoHome }: Props) => {
    const { errors, values, setFieldValue } = formik;
    return (
        <St.PageWrapper>
            <FormikProvider value={formik} data-testid="changePassword-form">
                <St.Form>
                    <St.Typography fontSize={24} my={1.5}>
                        {t('change_password_title')}
                    </St.Typography>
                    <St.FieldWrapper>
                        <PasswordField
                            fullWidth
                            name="oldPassword"
                            error={errors?.oldPassword as string}
                            label={t('old_password')}
                            placeholder={t('old_password')}
                            onChange={({ target }: any) => {
                                setFieldValue('oldPassword', target.value);
                            }}
                            value={values?.oldPassword || ''}
                            helperText={errors?.oldPassword as string}
                            autoFocus
                        />
                    </St.FieldWrapper>
                    <St.FieldWrapper>
                        <PasswordField
                            fullWidth
                            name="newPassword"
                            error={errors?.newPassword as string}
                            label={t('new_password')}
                            placeholder={t('enter_new_password')}
                            onChange={({ target }: any) => {
                                setFieldValue('newPassword', target.value);
                            }}
                            value={values?.newPassword || ''}
                            helperText={errors?.newPassword as string}
                        />
                    </St.FieldWrapper>
                    <St.FieldWrapper>
                        <PasswordField
                            fullWidth
                            name="newPasswordConfirmation"
                            error={errors?.newPasswordConfirmation as string}
                            helperText={errors?.newPasswordConfirmation as string}
                            label={t('change_password_repeat')}
                            placeholder={t('enter_new_password')}
                            onChange={({ target }: any) => {
                                setFieldValue('newPasswordConfirmation', target.value);
                            }}
                            value={values?.newPasswordConfirmation || ''}
                        />
                    </St.FieldWrapper>
                    <St.ButtonWrapper>
                        <ActionButton
                            fullWidth
                            variant="outlined"
                            color="primary"
                            disabled={isLoading}
                            onClick={handleGoHome}>
                            {t('back', tCommon)}
                        </ActionButton>
                        <ActionButton
                            fullWidth
                            type="submit"
                            variant="contained"
                            loading={isLoading}>
                            {t('save', tCommon)}
                        </ActionButton>
                    </St.ButtonWrapper>
                    <SnackBar />
                </St.Form>
            </FormikProvider>
        </St.PageWrapper>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    formik: FormikContextType<any>;
    isLoading: boolean;
    SnackBar: any;
    handleSubmit(values: any): void;
    handleGoHome(): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ProfileChangePasswordPage.propTypes = propTypes;

export default ProfileChangePasswordPage;
