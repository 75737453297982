import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './DepositModal.styled';
import { Box } from '@mui/material';
import { CONSULTATIO_SOURCE_ACCOUNTS } from 'constants/appConstants';
import CustomAutoComplete from 'components/common/CustomAutoComplete';
import { Form, FormikContextType, FormikProvider } from 'formik';
import FileUploader from 'components/common/FileUploader';
import CurrencyInput from 'components/common/CurrencyInput';
import ActionButton from 'components/common/ActionButton';
import { GetBankAccountsItem, GetBankAccountsResponse } from 'types/api/cashflow.types';
import CustomLoader from 'components/common/CustomLoader';

const DepositModal = (props: Props) => {
    const {
        t,
        handleSend,
        handleSourceAccount,
        formik,
        bankAccounts,
        isBankAccountsLoading,
        createIsLoading,
        close,
    } = props;
    const { errors, values, setFieldValue } = formik;

    return (
        <FormikProvider value={formik}>
            <Form>
                <St.BodyText variant="body2" paragraph>
                    {t('source_account')}
                </St.BodyText>
                {isBankAccountsLoading ? (
                    <CustomLoader />
                ) : (
                    bankAccounts?.items && (
                        <>
                            <Box flex={1} marginBottom={'25px'}>
                                <CustomAutoComplete
                                    id="sourceAccount"
                                    value={values.sourceAccount || null}
                                    onChange={(event, newValue) => {
                                        handleSourceAccount(newValue);
                                    }}
                                    options={bankAccounts.items
                                        .filter(account => account.status === 'ACTIVE')
                                        .map((account: GetBankAccountsItem) => ({
                                            id: account.id,
                                            name: account.description
                                                ? `(${account.description}) ${account.accountNumber} ${account.bankName} (${account.currency})`
                                                : `${account.accountNumber} ${account.bankName} (${account.currency})`,
                                        }))}
                                    label={(t('source_account') as string) + '*'}
                                    placeholder={t('source_account') as string}
                                    renderOption={(props, option) => (
                                        <Box {...props} key={option.id}>
                                            {option.name}
                                        </Box>
                                    )}
                                />
                            </Box>
                        </>
                    )
                )}
                <St.BodyText variant="body2" paragraph>
                    {t('destination_account')}
                </St.BodyText>
                <Box flex={1} marginBottom={'30px'}>
                    <St.CustomAutoComplete
                        id="destinationAccount"
                        value={values.destinationAccount || null}
                        onChange={(event, value) => setFieldValue('destinationAccount', value)}
                        options={CONSULTATIO_SOURCE_ACCOUNTS ?? []}
                        label={(t('destination_account') as string) + '*'}
                        placeholder={t('destination_account') as string}
                        renderOption={(props, option) => (
                            <Box {...props} key={option.id}>
                                {option.name}
                            </Box>
                        )}
                    />
                </Box>
                <St.BodyText variant="body2" paragraph>
                    {t('amount')}
                </St.BodyText>
                <St.InputContainer flex={1} marginBottom={'30px'}>
                    <CurrencyInput
                        currency={
                            values.sourceAccount
                                ? (values.sourceAccount?.name.match(/\((USD|USD-C|ARS)\)$/)?.[1] ??
                                  'ARS')
                                : 'USD'
                        }
                        handleChange={value => setFieldValue('amount', value)}
                        sx={{ width: '100%' }}
                        value={values.amount}
                        decimalScale={2}
                        disabled={!values.destinationAccount || !values.sourceAccount}
                        error={errors.amount as string | null}
                        helperText={errors.amount && errors.amount.toString()}
                    />
                </St.InputContainer>
                <Box flex={1} marginTop={'25px'} textAlign={'center'}>
                    <St.BodyText variant="body2" paragraph>
                        {t('receipt')}
                    </St.BodyText>
                    <Box width="100%" display="flex" justifyContent="center">
                        <St.AttachButtonContainer>
                            <FileUploader
                                values={values}
                                errors={errors}
                                setFieldValue={setFieldValue as any}
                                label={''}
                                field="document"
                            />
                        </St.AttachButtonContainer>
                    </Box>
                </Box>
            </Form>
            <Box display="flex" justifyContent="end" gap={2}>
                <ActionButton size="small" color="primary" variant="outlined" onClick={close}>
                    {t('cancel')}
                </ActionButton>
                <ActionButton
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={handleSend}
                    loading={createIsLoading}
                    disabled={createIsLoading}>
                    {t('sent')}
                </ActionButton>
            </Box>
        </FormikProvider>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    handleSend: PropTypes.func.isRequired,
    handleSourceAccount: PropTypes.func.isRequired,
};

interface extraProps {
    bankAccounts: GetBankAccountsResponse | undefined;
    formik: FormikContextType<any>;
    isBankAccountsLoading: boolean;
    createIsLoading: boolean;
    close(): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
DepositModal.propTypes = propTypes;

export default DepositModal;
