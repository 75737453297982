import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './InstrumentTypeTable.styled';
import { InstrumentType, InstrumentFormatted } from 'types/api/instruments.types';
import AlertNoData from 'components/common/AlertNoData';
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from '@mui/material';
import { tCommon } from 'constants/appConstants';
import { convertCurrencyFormat, formatNumber } from 'utils/helpers/commonHelper';
import EyeIcon from 'assets/icons/eye-icon.svg?react';
import CustomIconCreator from 'components/common/CustomIconCreator';

const InstrumentTypeTable = (props: Props) => {
    const { t, instruments, handleInstrumentClick, instrumentType } = props;
    const isFund = ['EXTERIOR_FUND', 'FUND'].includes(instrumentType);
    const noPercentage = ['EXTERIOR', 'ADR', 'EXTERIOR_FUND', 'EXTERIOR_BOND', 'OTHER'].includes(
        instrumentType,
    );
    const noDescription = [
        'EQUITY',
        'CERTIFICATE',
        'BOND',
        'EXTERIOR',
        'ADR',
        'EXTERIOR_BOND',
        'OTHER',
        'FUND',
        'EXTERIOR_FUND',
    ].includes(instrumentType);
    return instruments?.length > 0 ? (
        <TableContainer component={Paper}>
            <Table aria-label="wallet-instrument-table">
                <TableHead>
                    <TableRow>
                        <TableCell>{t(`${isFund ? 'name' : 'ticker'}_column`)}</TableCell>
                        {!noDescription && (
                            <St.DescriptionCell>{t('description_column')}</St.DescriptionCell>
                        )}
                        <TableCell>{t('price', tCommon)}</TableCell>
                        <TableCell>{t('quantity', tCommon)}</TableCell>
                        <TableCell>{t('quantity_available_column')}</TableCell>
                        <St.PercentageCell>{t('available_in_wallet')}</St.PercentageCell>
                        <TableCell>{t('instrument_valuation')}</TableCell>
                        {!noPercentage && (
                            <>
                                <TableCell>{t('performance_column')}</TableCell>
                                <St.PercentageCell>
                                    {t('performance_percentage_column')}
                                </St.PercentageCell>
                            </>
                        )}
                        <TableCell>{t('ppc_column')}</TableCell>
                        <St.PercentageCell>{t('ppc_percentage_column')}</St.PercentageCell>
                        <TableCell>{t('result_column')}</TableCell>
                        <St.ActionCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {instruments.map((instrument, index: number) => {
                        const {
                            available,
                            description,
                            holdingPercentage,
                            ppc,
                            ppcPercentage,
                            price,
                            profitability,
                            profitabilityPercentage,
                            quantity,
                            result,
                            ticker,
                            valuation,
                        } = instrument as InstrumentFormatted;

                        return (
                            <TableRow key={index}>
                                <St.TickerCell
                                    onClick={() =>
                                        handleInstrumentClick({
                                            ...instrument,
                                            type: instrumentType,
                                        })
                                    }>
                                    {isFund ? `${description}` : ticker}
                                </St.TickerCell>
                                {!noDescription && (
                                    <St.DescriptionCell>{description}</St.DescriptionCell>
                                )}
                                <TableCell>
                                    {convertCurrencyFormat(price?.value, price.currency)}
                                </TableCell>
                                <TableCell>
                                    {formatNumber(
                                        quantity,
                                        4,
                                        instrumentType === 'FUND' ||
                                            instrumentType === 'EXTERIOR_FUND',
                                    )}
                                </TableCell>
                                <TableCell>
                                    {formatNumber(
                                        available,
                                        4,
                                        instrumentType === 'FUND' ||
                                            instrumentType === 'EXTERIOR_FUND',
                                    )}
                                </TableCell>
                                <St.PercentageCell>{holdingPercentage}</St.PercentageCell>
                                <TableCell>
                                    {convertCurrencyFormat(valuation, price.currency)}
                                </TableCell>
                                {!noPercentage && (
                                    <>
                                        <St.ColorCell
                                            $positive={profitability ? profitability > 0 : null}>
                                            {profitability
                                                ? convertCurrencyFormat(
                                                      profitability,
                                                      price.currency,
                                                  )
                                                : '-'}
                                        </St.ColorCell>
                                        <St.ColorCell
                                            $isPercentage
                                            $positive={
                                                !profitabilityPercentage ||
                                                profitabilityPercentage === 0
                                                    ? null
                                                    : profitabilityPercentage > 0
                                            }>
                                            {profitabilityPercentage
                                                ? formatNumber(profitabilityPercentage, 2)
                                                : '-'}
                                        </St.ColorCell>
                                    </>
                                )}
                                <St.ColorCell
                                    $positive={
                                        !ppc || ppc === 0 || ppc === price.value ? null : ppc > 0
                                    }>
                                    {ppc ? convertCurrencyFormat(ppc, price.currency) : '-'}
                                </St.ColorCell>
                                <St.ColorCell
                                    $isPercentage
                                    $positive={
                                        !ppcPercentage || ppcPercentage === 0
                                            ? null
                                            : ppcPercentage > 0
                                    }>
                                    {ppcPercentage ? formatNumber(ppcPercentage ?? 0) : '-'}
                                </St.ColorCell>
                                <St.ColorCell
                                    $positive={!result || result === 0 ? null : result > 0}>
                                    {instrumentType !== 'BOND' ||
                                    (instrumentType === 'BOND' && !!ppcPercentage && !!ppc)
                                        ? convertCurrencyFormat(result ?? 0, price.currency)
                                        : '-'}
                                </St.ColorCell>

                                <St.ActionCell>
                                    <Tooltip title={t('detail', tCommon)}>
                                        <IconButton
                                            onClick={() =>
                                                handleInstrumentClick({
                                                    ...instrument,
                                                    type: instrumentType,
                                                })
                                            }>
                                            <CustomIconCreator icon={EyeIcon} />
                                        </IconButton>
                                    </Tooltip>
                                </St.ActionCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    ) : (
        <AlertNoData title={t('no_data_was_found')} />
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    instruments: InstrumentFormatted[];
    // instruments: InstrumentInfo[];
    instrumentType: InstrumentType;
    handleInstrumentClick: (instrument: any) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
InstrumentTypeTable.propTypes = propTypes;

export default InstrumentTypeTable;
