import { Box, styled, Typography } from '@mui/material';
import { pxToRem } from 'utils/helpers/stylesHelper';

export default {
    Box: styled(Box)(({ theme }) => ({})),
    RecommendedWalletWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            position: 'relative',
            width: '100%',
        }),
    ),
    RecommendedWalletContainer: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            width: '100%',
            backgroundColor: theme.palette.custom.vibrantblue10,
            padding: { xs: '2rem', sm: '3.5rem', md: '2.5rem', lg: '4.5rem' },
            margin: { xs: 'auto', md: '-1.5rem', lg: 'auto' },
            borderTopLeftRadius: pxToRem(40),
            borderBottomLeftRadius: pxToRem(40),
            borderBottomRightRadius: { xl: pxToRem(40), xs: pxToRem(40), lg: 0 },
            borderTopRightRadius: { xl: pxToRem(40), xs: pxToRem(40), lg: 0 },
            overflow: 'hidden',
            [theme.breakpoints.down(450)]: {
                overflow: 'scroll',
            },
        }),
    ),
    CarouselItemContainer: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            height: { xs: '100%', md: '320px' },
            textAlign: 'left',
        }),
    ),
    PieChartContainer: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            width: '100%',
            height: { xs: '450px', md: '200px' },
            marginLeft: '-10px',
            marginTop: '40px',
        }),
    ),
    AvailabeByText: styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            color: theme.palette.custom.darkblue,
            marginTop: '10px',
        }),
    ),

    SwiperCustomDots: styled(Box)(() => ({
        '& .swiper-pagination-bullet': {
            width: '14px',
            height: '14px',
        },
        '& .swiper-pagination-bullet-active': {},
    })) as typeof Box,
};
