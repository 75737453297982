import { useContext, useState } from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import OperationsFCIChannelPage from './OperationsFCIChannelPage';
import { useTranslation } from 'react-i18next';
import { GetFundOrderItem } from 'types/api/orders.types';
import { useGetFundsOrders } from 'hooks/api/orders.hooks';
import useCustomFetch from 'hooks/common/CommonGrid/fetch.hooks';
import { Box, Chip, Tooltip, Typography } from '@mui/material';
import { INSTRUMENTS_BASE_URL, MARKET_ACCOUNT_BASE_URL } from 'constants/appConstants';
import { CommonColumn, CommonFilter } from 'types/common/tableLayout.types';
import {
    convertCurrencyFormat,
    fundDirectaOptions,
    fundMesaOptions,
    getColorByStatus,
    getFullName,
} from 'utils/helpers/commonHelper';
import { formatDateWithHour, formatSingleDate } from 'utils/helpers/dateHelper';
import moment from 'moment';

const OperationsFCIChannelPageContainer = (props: Props) => {
    const [currentTab, setCurrentTab] = useState('directa');
    const { t } = useTranslation();

    const defaultFilters = [
        { query: 'dateFrom', queryValue: moment().format('YYYY-MM-DD') },
        { query: 'dateTo', queryValue: moment().format('YYYY-MM-DD') },
    ];

    const {
        data,
        loading,
        sortInfo,
        handleSort,
        handlePageChange,
        filterHeaderString,
        setFilterHeaderString,
    } = useCustomFetch({
        useGetData: useGetFundsOrders,
        extraFilters: [{ query: 'isManual', queryValue: currentTab === 'mesa' }],
        defaultFilters,
        defaultSortFieldId: 'createdAt',
    });

    const handleTabChange = (newValue: string) => setCurrentTab(newValue);

    const directColumns: CommonColumn<GetFundOrderItem>[] = [
        {
            id: 'createdAt',
            name: t('date_created'),
            selector: row => formatDateWithHour(row.createdAt),
            sortable: true,
        },
        {
            id: 'customer_code',
            name: t('customer_code'),
            selector: ({ customerCode, user }) => (
                <Tooltip title={getFullName(user)} placement="right">
                    <Box sx={{ width: 'fit-content' }}>{customerCode || '-'}</Box>
                </Tooltip>
            ),
            sortable: true,
        },
        {
            id: 'name',
            name: t('client'),
            selector: ({ user }) => `${user.name} ${user.lastName}`,
        },
        {
            id: 'code',
            name: t('code'),
            selector: row => row.code || '-',
            sortable: true,
        },
        {
            id: 'buySell',
            name: t('type_filter'),
            selector: row => (
                <Chip color="secondary" variant="outlined" label={t(`funds_${row.buySell}`)} />
            ),
            sortable: true,
        },
        {
            id: 'isSimple',
            name: t('type'),
            selector: row => (row.isSimple ? 'SIMPLE' : 'ACDY'),
        },
        {
            id: 'instrument',
            name: t('fund_instrument'),
            selector: row => (
                <Typography fontSize={14} fontWeight={700}>
                    {row.instrument.name || '-'}
                </Typography>
            ),
        },
        {
            id: 'currency',
            name: t('currency'),
            selector: row => row.ticker.currency,
        },
        {
            id: 'quantity',
            name: t('quantity'),
            sortable: true,
        },
        {
            id: 'price',
            name: t('fund_price'),
            selector: row =>
                convertCurrencyFormat(row.amount, row.ticker.currency).replace('-', ''),
            sortable: true,
        },
        {
            id: 'isTotal',
            name: t('is_total'),
            selector: row => (row.isTotal ? 'SI' : 'NO'),
        },
        {
            id: 'agreementDatetime',
            name: t('agreement_date'),
            selector: row => formatSingleDate(row.agreementDatetime),
            sortable: true,
        },
        {
            id: 'liquidationDatetime',
            name: t('liquidation_date'),
            selector: row => formatSingleDate(row.liquidationDatetime),
            sortable: true,
        },
        {
            id: 'status',
            name: t('status'),
            selector: row => (
                <Chip sx={{ ...getColorByStatus(row.status) }} label={t(row.status)} />
            ),
            sortable: true,
        },
    ];

    const mesaColumns: CommonColumn<GetFundOrderItem>[] = [
        {
            id: 'createdAt',
            name: t('date_created'),
            selector: row => formatDateWithHour(row.createdAt),
            sortable: true,
        },
        {
            id: 'customer_code',
            name: t('customer_code'),
            selector: ({ customerCode, user }) => (
                <Tooltip title={getFullName(user)} placement="right">
                    <Box sx={{ width: 'fit-content' }}>{customerCode || '-'}</Box>
                </Tooltip>
            ),
            sortable: true,
        },
        {
            id: 'name',
            name: t('client'),
            selector: ({ user }) => `${user.name} ${user.lastName}`,
        },
        {
            id: 'buySell',
            name: t('type_filter'),
            selector: row => (
                <Chip color="secondary" variant="outlined" label={t(`funds_${row.buySell}`)} />
            ),
            sortable: true,
        },
        {
            id: 'isSimple',
            name: t('type'),
            selector: row => (row.isSimple ? 'SIMPLE' : 'ACDY'),
        },
        {
            id: 'instrument',
            name: t('fund_instrument'),
            selector: row => (
                <Typography fontSize={14} fontWeight={700}>
                    {row.instrument.name || '-'}
                </Typography>
            ),
        },
        {
            id: 'currency',
            name: t('currency'),
            selector: row => row.ticker.currency,
        },
        {
            id: 'quantity',
            name: t('quantity'),
            sortable: true,
        },
        {
            id: 'price',
            name: t('fund_price'),
            selector: row => convertCurrencyFormat(row.amount, row.ticker.currency),
            sortable: true,
        },
        {
            id: 'isTotal',
            name: t('is_total'),
            selector: row => (row.isTotal ? 'SI' : 'NO'),
        },
        {
            id: 'agreementDatetime',
            name: t('agreement_date'),
            selector: row => formatSingleDate(row.agreementDatetime),
            sortable: true,
        },
        {
            id: 'liquidationDatetime',
            name: t('liquidation_date'),
            selector: row => formatSingleDate(row.liquidationDatetime),
            sortable: true,
        },
        {
            id: 'status',
            name: t('status'),
            selector: row => (
                <Chip sx={{ ...getColorByStatus(row.status) }} label={t(row.status)} />
            ),
            sortable: true,
        },
        {
            id: 'operator',
            name: t('operator'),
            selector: row => row?.operator?.name || '-',
        },
    ];

    const filters: CommonFilter[] = [
        {
            id: 'dateFrom',
            label: t('from'),
            type: 'date',
            query: 'dateFrom',
        },
        {
            id: 'dateTo',
            label: t('to'),
            type: 'date',
            query: 'dateTo',
        },
        {
            id: 'buySell',
            label: t('type_filter'),
            type: 'select',
            query: 'buySell',
            options: [
                { id: 'BUY', name: t('funds_BUY') },
                { id: 'SELL', name: t('funds_SELL') },
            ],
        },
        {
            id: 'ticker',
            label: t('instrument'),
            type: 'requestAutocomplete',
            query: 'ticker',
            requestAutocomplete: {
                requestUrl: `${INSTRUMENTS_BASE_URL}/instruments/`,
                param: 'q',
                optionLabel: option => option.tickers[0].ticker,
                keyTofilter: 'tickers[0].ticker',
                extraFilter: '&type=FUND',
            },
        },
        {
            id: 'status',
            label: t('status'),
            type: 'select',
            query: 'status',
            options: currentTab === 'directa' ? fundDirectaOptions : fundMesaOptions,
        },
        {
            id: 'customerCode',
            label: t('customer_code'),
            query: 'customerCode',
            type: 'requestAutocomplete',
            requestAutocomplete: {
                requestUrl: `${MARKET_ACCOUNT_BASE_URL}/market-accounts`,
                param: 'customerCode',
                optionLabel: option => option.customerCode,
                keyTofilter: 'customerCode',
            },
        },
        {
            id: 'client',
            label: t('client'),
            type: 'select',
            query: 'customerFullName',
            requestAutocomplete: {
                requestUrl: `${MARKET_ACCOUNT_BASE_URL}/market-accounts`,
                param: 'customerFullName',
                optionLabel: option => `${option.name} ${option.last_name}`,
                keyTofilter: 'name',
                extraFilter: '&sortField=name&sort=ASC',
            },
        },
    ];

    const childProps = {
        ...props,
        columns: currentTab === 'directa' ? directColumns : mesaColumns,
        filters,
        rows: data,
        loading,
        sortInfo,
        filterHeaderString,
        currentTab,
        defaultFilters,
        handleTabChange,
        handleSort,
        handlePageChange,
        setFilterHeaderString,
        t,
    };
    return <OperationsFCIChannelPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OperationsFCIChannelPageContainer.propTypes = propTypes;

export default OperationsFCIChannelPageContainer;
