import { Alert, AlertTitle, Box, styled, Typography } from '@mui/material';
import CustomLoader from 'components/common/CustomLoader';
import { transientOptions } from 'utils/helpers/stylesHelper';

export default {
    Alert: styled(Alert)(({ theme }) =>
        theme.unstable_sx({
            width: '90%',
            maxWidth: '600px',
            '.MuiAlert-icon': { margin: 'auto 1rem auto 0' },
        }),
    ),
    AlertTitle: styled(AlertTitle)(() => ({ margin: '0' })),
    Container: styled(
        Box,
        transientOptions,
    )<{ $isMobile: boolean }>(({ theme, $isMobile }) =>
        theme.unstable_sx({
            padding: $isMobile ? '1rem 0rem' : '1rem 8rem',
            borderBottom: `1px dashed ${theme.palette.custom.darkblue10}`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '1rem',
            flexDirection: $isMobile ? 'column' : 'row',
        }),
    ),
    CustomerSelectWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            maxWidth: '600px',
            width: '100%',
        }),
    ),
    CustomerLabel: styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            border: `1px solid ${theme.palette.primary.main}`,
            padding: '8px 12px',
            display: 'inline-block',
            borderRadius: '20px',
            width: '90%',
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
        }),
    ),
    TitleInfo: styled(Typography)(({ theme, fontSize = '1rem' }) =>
        theme.unstable_sx({
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: theme.palette.primary.main,
            marginRight: 1,
            fontWeight: 'bold',
            fontSize,
            width: 'fit-content',
        }),
    ),
    ButtonContainer: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            alignItems: 'center',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: '1rem',
        }),
    ),
    CustomLoader: styled(CustomLoader)(() => ({
        margin: '0',
    })),
};
