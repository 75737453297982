import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import FavoriteButton from './FavoriteButton';
import { useAddFavorites, useRemoveFavorites } from 'hooks/api/instruments.hooks';
import useSnackBar from 'hooks/common/snackbar.hooks';
import { errorResponseHelper } from 'utils/helpers/errorHelper';

const FavoriteButtonContainer = (props: Props) => {
    const { favorite, ticker } = props;
    const { mutate: addFavorite, errorMessage: errorMessageAdd } = useAddFavorites();
    const { mutate: removeFavorite, errorMessage: errorMessageRemove } = useRemoveFavorites();
    const { setSnackBarMessage, SnackBar } = useSnackBar();
    const [actualFavorite, setActualFavorite] = React.useState(favorite);

    React.useEffect(() => {
        setActualFavorite(favorite);
    }, [favorite]);

    React.useEffect(() => {
        if (errorMessageAdd) {
            setSnackBarMessage(errorResponseHelper(errorMessageAdd), 'error');
        }
        if (errorMessageRemove) {
            setSnackBarMessage(errorResponseHelper(errorMessageRemove), 'error');
        }
    }, [errorMessageAdd, errorMessageRemove, setSnackBarMessage]);

    const handleFavorite = () => {
        if (actualFavorite)
            return removeFavorite(
                { tickers: [ticker] },
                { onSuccess: () => setActualFavorite(false) },
            );
        return addFavorite({ tickers: [ticker] }, { onSuccess: () => setActualFavorite(true) });
    };

    const childProps = {
        favorite: actualFavorite,
        handleFavorite,
        SnackBar,
    };

    return <FavoriteButton {...childProps} />;
};

const propTypes = {
    favorite: PropTypes.bool.isRequired,
    ticker: PropTypes.string.isRequired,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
FavoriteButtonContainer.propTypes = propTypes;

export default FavoriteButtonContainer;
