import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import TableLayout from 'components/layouts/TableLayout';
import { useGetReportHolding } from 'hooks/api/marketAccount.hooks';
import { tCommon } from 'constants/appConstants';
import { CommonColumn, CommonFilter } from 'types/common/tableLayout.types';
import { Holding } from 'types/api/marketAccount.types';
import { AuthContext } from 'context/auth.context';
import { useContext } from 'react';
import { ExtraQueryFiltersProps } from 'types/common/CommonGrid/CommonGridFormModal.types';
import { today } from 'utils/helpers/dateHelper';

const ReportsHistoricalHoldingPage = (props: Props) => {
    const { columns, filters, t, defaultFilters, filterDateValidation } = props;
    const { customerCode } = useContext(AuthContext);

    return (
        <TableLayout
            title={t('reports_historial_holding', tCommon)}
            fetchData={useGetReportHolding}
            columns={columns}
            filters={filters}
            canExport
            exportTitle={`${t('holding_doc_title')} ${customerCode} ${today}`}
            defaultFilters={defaultFilters}
            customFilterValidation={filterDateValidation}
        />
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    columns: CommonColumn<Holding>[];
    filters: CommonFilter[];
    defaultFilters: ExtraQueryFiltersProps[];
    filterDateValidation: (filters: string) => {
        result: boolean;
        error: string | undefined;
    };
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ReportsHistoricalHoldingPage.propTypes = propTypes;

export default ReportsHistoricalHoldingPage;
