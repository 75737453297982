import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import UserInfo from './UserInfo';
import { useTranslation } from 'react-i18next';
import { OnboardingContext } from 'context/onboarding.context';
import { AuthContext } from 'context/auth.context';
import { useNavigate } from 'react-router-dom';
import useBreakpoints from 'hooks/common/breakpoints.hooks';
import { useRouteToHome } from 'utils/helpers/routesHelper';

const UserInfoContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation();
    const textRef = React.useRef<HTMLParagraphElement | null>(null);
    const { matchesLg, matchesMd, matchesSm, matchesXl, matchesXs } = useBreakpoints();
    const [isOverflowed, setIsOverflowed] = React.useState(false);
    const [tooltipOpen, setTooltipOpen] = React.useState(false);
    const { legalData } = React.useContext(OnboardingContext);
    const { userData, userType, customerCode } = React.useContext(AuthContext);
    const navigate = useNavigate();
    const homeUrl = useRouteToHome();
    const navigateToHome = () => navigate(homeUrl);

    const containerWidth = React.useMemo(() => {
        if (matchesXs) return 150;
        if (matchesSm) return 300;
        if (matchesMd) return 200;
        if (matchesLg) return 400;
        if (matchesXl) return 500;
        return 200;
    }, [matchesLg, matchesMd, matchesSm, matchesXl, matchesXs]);

    const userFullName = React.useMemo(() => {
        if (userData?.onboardingPending) {
            if (!legalData) return '-';
            return `${legalData?.name} ${legalData?.lastName}`;
        }
        if (userType === 'APP_LEGAL') return userData?.name ?? '-';
        return `${userData?.name} ${userData?.lastName}`;
    }, [legalData, userData, userType]);

    React.useEffect(() => {
        const element = textRef.current;
        if (element) {
            setIsOverflowed(element.scrollWidth > containerWidth);
        }
    }, [containerWidth]);

    const handleMouseEnter = () => {
        if (isOverflowed) {
            setTooltipOpen(true);
        }
    };

    const handleMouseLeave = () => {
        setTooltipOpen(false);
    };

    const childProps = {
        ...props,
        t,
        userFullName,
        isUF:
            !userData?.onboardingPending &&
            (userType === 'APP_LEGAL' || userType === 'APP_PHYSICAL'),
        customerCode,
        textRef,
        tooltipOpen,
        handleMouseLeave,
        handleMouseEnter,
        navigateToHome,
    };

    return <UserInfo {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
UserInfoContainer.propTypes = propTypes;

export default UserInfoContainer;
