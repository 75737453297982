import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './OnboardingInvestmentTestResultPage.styled';
import TopHeader from 'components/common/TopHeader';
import { InvestmentTestValues } from 'types/common/users.types';
import ActionButton from 'components/common/ActionButton';
import { Typography } from '@mui/material';
import { mainRoutesPaths } from 'router/routesPaths';
import { formatDate } from 'utils/helpers/dateHelper';
import { UserData } from 'types/api/auth.types';
import HeadLogo from 'components/common/HeadLogo';

const OnboardingInvestmentTestResultPage = (props: Props) => {
    const {
        t,
        prevTest,
        userData,
        navigate,
        isOnboarding,
        isTestExpired,
        investorFeatures,
        investmentProfile,
        onClickCompleteInvestmentTest,
    } = props;
    return (
        <>
            {isTestExpired && <HeadLogo />}
            <St.SectionWrapper>
                <TopHeader
                    firstElement={t('investment_test_page_first_text') as string}
                    title={
                        t('investment_test_page_title_with_profile', {
                            profile: investmentProfile,
                        }) as string
                    }
                />
                <St.Subtitle>{t('investment_test_page_subtitle_with_profile')}</St.Subtitle>
                <St.Ul>
                    {investorFeatures?.map((el: string, index: number) => (
                        <St.Li key={index}>{el}</St.Li>
                    ))}
                </St.Ul>

                {prevTest ? (
                    <>
                        <St.ActionButtonWrapper>
                            <ActionButton
                                variant="contained"
                                type="button"
                                onClick={() => navigate(mainRoutesPaths.investmentTestQuestions)}>
                                {t('investment_test_page_do_another_test')}
                            </ActionButton>
                        </St.ActionButtonWrapper>
                        <Typography textAlign={'center'} marginTop={'2rem'}>
                            {
                                t('last_test_date', {
                                    date: formatDate(
                                        userData?.info?.investorProfileUpdatedAt || '',
                                    ),
                                }) as string
                            }
                        </Typography>
                    </>
                ) : (
                    <St.ActionButtonWrapper>
                        <ActionButton
                            variant="contained"
                            type="button"
                            onClick={onClickCompleteInvestmentTest}>
                            {t(
                                isOnboarding || isTestExpired
                                    ? 'investment_test_page_btn_finish'
                                    : 'investment_test_page_see__recommended_portfolio',
                            )}
                        </ActionButton>
                    </St.ActionButtonWrapper>
                )}
            </St.SectionWrapper>
        </>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    prevTest: PropTypes.bool,
    navigate: PropTypes.func.isRequired,
    onClickCompleteInvestmentTest: PropTypes.func.isRequired,
    isOnboarding: PropTypes.bool.isRequired,
    isTestExpired: PropTypes.bool.isRequired,
};

interface extraProps {
    userData: UserData | null;
    investorFeatures: string[];
    investmentProfile: InvestmentTestValues | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OnboardingInvestmentTestResultPage.propTypes = propTypes;

export default OnboardingInvestmentTestResultPage;
