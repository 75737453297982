import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import GoBackButton from './GoBackButton';

const GoBackButtonContainer = (props: Props) => {
    const { prevRoute } = props;

    const goBack = () => {
        prevRoute ? window.location.replace(prevRoute) : window.history.back();
    };

    const childProps = {
        ...props,
        goBack,
    };

    return <GoBackButton {...childProps} />;
};

const propTypes = {
    prevRoute: PropTypes.string,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
GoBackButtonContainer.propTypes = propTypes;

export default GoBackButtonContainer;
