import { useState } from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import OperationsOrdersChannelPage from './OperationsOrdersChannelPage';
import { useTranslation } from 'react-i18next';
import useCustomFetch from 'hooks/common/CommonGrid/fetch.hooks';
import { useGetOrders } from 'hooks/api/orders.hooks';
import { GetMarketOrderItem } from 'types/api/orders.types';
import { CommonAction, CommonColumn, CommonFilter } from 'types/common/tableLayout.types';
import { formatDateWithHour } from 'utils/helpers/dateHelper';
import { Box, Chip, Tooltip, Typography } from '@mui/material';
import {
    convertCurrencyFormat,
    getColorByStatus,
    getFullName,
    orderMesaOptions,
    orderStatusOptions,
    statusToShowDelete,
} from 'utils/helpers/commonHelper';
import ChannelsDDJJCellInfo from 'components/common/ChannelsDDJJCellInfo';
import { INSTRUMENTS_BASE_URL, MARKET_ACCOUNT_BASE_URL } from 'constants/appConstants';
import CustomIconCreator from 'components/common/CustomIconCreator';
import EyeIcon from 'assets/icons/eye-icon.svg?react';
import OrderDetail from '../OperationsReferralOrdersPage/OrderDetail';
import CloseIcon from 'assets/icons/close-line.svg?react';
import DeleteOrderModal from '../OperationsReferralOrdersPage/DeleteOrderModal';
import moment from 'moment';

const OperationsOrdersChannelPageContainer = (props: Props) => {
    const [currentTab, setCurrentTab] = useState('directa');
    const { t } = useTranslation();

    const defaultFilters = [
        { query: 'dateFrom', queryValue: moment().format('YYYY-MM-DD') },
        { query: 'dateTo', queryValue: moment().format('YYYY-MM-DD') },
    ];

    const {
        data,
        loading,
        sortInfo,
        handleSort,
        handlePageChange,
        filterHeaderString,
        setFilterHeaderString,
    } = useCustomFetch({
        useGetData: useGetOrders,
        extraFilters: [{ query: 'isManual', queryValue: currentTab === 'mesa' }],
        defaultFilters,
        defaultSortFieldId: 'createdAt',
    });

    const handleTabChange = (newValue: string) => setCurrentTab(newValue);

    const directColumns: CommonColumn<GetMarketOrderItem>[] = [
        {
            id: 'createdAt',
            name: t('date_created'),
            selector: row => formatDateWithHour(row.createdAt),
            sortable: true,
        },
        {
            id: 'customer_code',
            name: t('customer_code'),
            selector: ({ customerCode, user }) => (
                <Tooltip title={getFullName(user)} placement="right">
                    <Box sx={{ width: 'fit-content' }}>{customerCode || '-'}</Box>
                </Tooltip>
            ),
            sortable: true,
        },
        {
            id: 'code',
            name: t('code'),
            selector: row => row.code || '-',
            sortable: true,
        },
        {
            id: 'buySell',
            name: t('type_filter'),
            selector: row => <Chip color="secondary" variant="outlined" label={t(row.buySell)} />,
            sortable: true,
        },
        {
            id: 'instrument',
            name: t('instrument'),
            selector: row => (
                <Typography fontSize={14} fontWeight={700}>
                    {row?.ticker.ticker || '-'}
                </Typography>
            ),
        },
        {
            id: 'term',
            name: t('term'),
            selector: ({ term }) => t(`term_${term}`) as string,
            sortable: true,
        },
        {
            id: 'currency',
            name: t('currency'),
            selector: row => row.ticker.currency,
        },
        {
            id: 'quantity',
            name: t('quantity'),
            selector: row => row.quantity,
            sortable: true,
        },
        {
            id: 'executed',
            name: t('quantity_executed'),
            selector: row => row.executed || '-',
            sortable: true,
        },
        {
            id: 'price',
            name: t('price'),
            selector: row => convertCurrencyFormat(row.price, row.ticker.currency),
            sortable: true,
        },
        {
            id: 'ddjjRequired',
            name: t('ddjj'),
            selector: row => <ChannelsDDJJCellInfo uploadFile {...row} />,
        },
        {
            id: 'status',
            name: t('status'),
            selector: row => (
                <Chip sx={{ ...getColorByStatus(row.status) }} label={t(row.status)} />
            ),
            sortable: true,
        },
    ];

    const mesaColumns: CommonColumn<GetMarketOrderItem>[] = [
        {
            id: 'createdAt',
            name: t('created_at'),
            selector: row => formatDateWithHour(row.createdAt),
            sortable: true,
        },
        {
            id: 'customer_code',
            name: t('customer_code'),
            selector: row => row.customerCode || '-',
            sortable: true,
        },
        {
            id: 'buySell',
            name: t('type_filter'),
            selector: row => <Chip color="secondary" variant="outlined" label={t(row.buySell)} />,
            sortable: true,
        },
        {
            id: 'instrument',
            name: t('instrument'),
            selector: row => (
                <Typography fontSize={14} fontWeight={700}>
                    {row?.ticker.ticker || '-'}
                </Typography>
            ),
        },
        {
            id: 'term',
            name: t('term'),
            selector: ({ term }) => t(`term_${term}`) as string,
        },
        {
            id: 'currency',
            name: t('currency'),
            selector: row => row.ticker.currency,
        },
        {
            id: 'quantity',
            name: t('quantity'),
            sortable: true,
        },
        {
            id: 'operatedPrice',
            name: t('operated_price'),
            selector: row => (row.operatedPrice ? convertCurrencyFormat(row.operatedPrice) : '-'),
        },
        {
            id: 'operatedAmount',
            name: t('operated_amount'),
            selector: row => (row.operatedAmount ? convertCurrencyFormat(row.operatedAmount) : '-'),
        },
        {
            id: 'ddjjRequired',
            name: t('ddjj'),
            selector: row => <ChannelsDDJJCellInfo uploadFile {...row} />,
        },
        {
            id: 'status',
            name: t('status'),
            selector: row => (
                <Chip sx={{ ...getColorByStatus(row.status) }} label={t(row.status)} />
            ),
            sortable: true,
        },
    ];

    const filters: CommonFilter[] = [
        {
            id: 'dateFrom',
            label: t('from'),
            type: 'date',
            query: 'dateFrom',
        },
        {
            id: 'dateTo',
            label: t('to'),
            type: 'date',
            query: 'dateTo',
        },
        {
            id: 'buySell',
            label: t('type_filter'),
            type: 'select',
            query: 'buySell',
            options: [
                { id: 'BUY', name: t('BUY') },
                { id: 'SELL', name: t('SELL') },
            ],
        },
        {
            id: 'ticker',
            label: t('instrument'),
            type: 'requestAutocomplete',
            query: 'ticker',
            requestAutocomplete: {
                requestUrl: `${INSTRUMENTS_BASE_URL}/instruments/`,
                param: 'q',
                optionLabel: option => option.tickers[0].ticker,
                keyTofilter: 'tickers[0].ticker',
                extraFilter: '&type=BOND&type=CERTIFICATE&type=EQUITY',
            },
        },
        {
            id: 'status',
            label: t('status'),
            type: 'select',
            query: 'status',
            options: currentTab === 'directa' ? orderStatusOptions : orderMesaOptions,
        },
        {
            id: 'customerCode',
            label: t('customer_code'),
            type: 'requestAutocomplete',
            query: 'customerCode',
            requestAutocomplete: {
                requestUrl: `${MARKET_ACCOUNT_BASE_URL}/market-accounts`,
                param: 'customerCode',
                optionLabel: option => option.customerCode,
                keyTofilter: 'customerCode',
            },
        },
        {
            id: 'client',
            label: t('client'),
            type: 'requestAutocomplete',
            query: 'customerName',
            requestAutocomplete: {
                requestUrl: `${MARKET_ACCOUNT_BASE_URL}/market-accounts`,
                param: 'customerFullName',
                optionLabel: option => `${option.name} ${option.last_name}`,
                keyTofilter: 'name',
                extraFilter: '&sortField=name&sort=ASC',
            },
        },
    ];

    const actions: CommonAction<GetMarketOrderItem>[] = [
        {
            id: 'detail',
            type: 'modal',
            Icon: () => <CustomIconCreator icon={EyeIcon} />,
            onClick: props => <OrderDetail {...props} />,
        },
        {
            id: 'delete_order',
            Icon: () => <CustomIconCreator icon={CloseIcon} />,
            type: 'modal',
            isHidden: row => currentTab === 'mesa' || !statusToShowDelete.includes(row.status),
            onClick: props => <DeleteOrderModal {...props} />,
        },
    ];

    const childProps = {
        ...props,
        columns: currentTab === 'directa' ? directColumns : mesaColumns,
        filters,
        rows: data,
        loading,
        sortInfo,
        filterHeaderString,
        currentTab,
        actions,
        handleTabChange,
        handleSort,
        handlePageChange,
        setFilterHeaderString,
        t,
    };

    return <OperationsOrdersChannelPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OperationsOrdersChannelPageContainer.propTypes = propTypes;

export default OperationsOrdersChannelPageContainer;
