import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ErrorPage from './ErrorPage';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRouteToHome } from 'utils/helpers/routesHelper';

const ErrorPageContainer = (props: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const homeUrl = useRouteToHome();
    const backToHome = () => navigate(homeUrl);

    const childProps = {
        ...props,
        t,
        backToHome,
    };
    return <ErrorPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ErrorPageContainer.propTypes = propTypes;

export default ErrorPageContainer;
