import { RouteInfo, SubRouteInfo } from 'utils/helpers/routesHelper';
import MainLayout from 'components/layouts/MainLayout';
import HelmetLayout from 'components/layouts/HelmetLayout';
import OnboardingLayout from 'components/layouts/OnboardingLayout';
import ErrorPage from 'components/pages/ErrorPage';
import i18n from 'utils/i18n';
import LogoutIcon from 'assets/icons/logout-box-r-line.svg?react';
import { extraRoutes, mainRoutes } from './subRoutes/mainLayout';
import { loggedOutSubroutes } from './subRoutes/loggedOut';
import { extraOnboardingRoutes, onboardingRoutes } from './subRoutes/onboardingLayout';
import LoginPage from 'components/pages/LoginPage';
import HelpPage from 'components/pages/HelpPage';
import { publicSubroutesPaths } from './routesPaths';
import NoPermissionsPage from 'components/pages/NoPermissionsPage';
import { channelRoutes, extraChannelRoutes } from './subRoutes/channelLayout';

export const publicSubRoutes: Array<SubRouteInfo> = [
    {
        name: 'errorPage',
        path: '*',
        title: 'Error',
        component: ErrorPage,
        availability: 'public',
    },
    {
        name: 'helpPage',
        path: publicSubroutesPaths.help,
        title: 'Help',
        component: HelpPage,
        availability: 'public',
    },
    {
        name: 'noPermissions',
        path: publicSubroutesPaths.noPermissions,
        title: 'No Permissions',
        component: NoPermissionsPage,
        availability: 'public',
    },
];

export const routes: Array<RouteInfo> = [
    {
        name: 'public_layout',
        component: HelmetLayout,
        subRoutes: publicSubRoutes,
        availability: 'public',
    },
    {
        name: 'main_layout',
        component: MainLayout,
        subRoutes: [...mainRoutes, ...extraRoutes],
        availability: 'logged_in',
    },
    {
        name: 'main_layout',
        component: () => <MainLayout isChannel />,
        subRoutes: [...channelRoutes, ...extraChannelRoutes],
        availability: 'channel_logged_in',
    },
    {
        name: 'onboarding_logged_in',
        component: OnboardingLayout,
        subRoutes: [...onboardingRoutes, ...extraOnboardingRoutes],
        availability: 'onboarding_logged_in',
    },
    {
        name: 'logged_out_layout',
        component: HelmetLayout,
        subRoutes: loggedOutSubroutes,
        availability: 'logged_out',
    },
];

export const logoutItemData = () =>
    ({
        name: 'logout',
        path: '/login',
        icon: LogoutIcon,
        title: i18n.t('layout_main_navigation_logout') as string,
        component: LoginPage,
        availability: 'logged_in',
    }) as SubRouteInfo;

export default routes;
