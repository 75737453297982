import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { CircularProgress, Grid, Tab } from '@mui/material';
import {
    CommonAction,
    CommonColumn,
    CommonFilter,
    SortInformation,
} from 'types/common/tableLayout.types';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import CommonTable from 'components/common/CommonTable';
import { GetMarketOrderItem, GetMarketOrdersResponse } from 'types/api/orders.types';
import TableLayoutHeader from 'components/common/TableLayoutHeader';

const OperationsOrdersChannelPage = ({
    rows,
    columns,
    filters,
    sortInfo,
    filterHeaderString,
    currentTab,
    loading,
    actions,
    handleTabChange,
    setFilterHeaderString,
    handlePageChange,
    handleSort,
    t,
}: Props) => (
    <Grid container display="flex" flexDirection="column">
        <TableLayoutHeader
            title={t('layout_main_navigation_operations_orders')}
            filters={filters}
            filterHeaderString={filterHeaderString}
            setFilterHeaderString={setFilterHeaderString}
            columns={columns}
        />
        <TabContext value={currentTab}>
            <Grid container>
                <Grid item xs={6}>
                    <TabList
                        sx={{
                            p: 0,
                            borderBottom: 1,
                            borderColor: 'divider',
                            width: 'fit-content',
                        }}
                        onChange={(_, newValue) => handleTabChange(newValue)}>
                        <Tab
                            value="directa"
                            label={t('directa')}
                            sx={{
                                textTransform: 'none',
                                fontSize: 18,
                                color: 'primary.main',
                                paddingInline: 4,
                            }}
                        />
                        <Tab
                            value="mesa"
                            label={t('mesa')}
                            sx={{
                                textTransform: 'none',
                                fontSize: 18,
                                color: 'primary.main',
                                paddingInline: 4,
                            }}
                        />
                    </TabList>
                </Grid>

                {loading ? (
                    <Grid
                        item
                        xs={12}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        mt={5}>
                        <CircularProgress color="primary" size="6rem" />
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <TabPanel value="directa">
                            <CommonTable
                                columns={columns}
                                rows={rows}
                                sortInfo={sortInfo}
                                handlePageChange={handlePageChange}
                                handleSort={handleSort}
                                actions={actions}
                            />
                        </TabPanel>

                        <TabPanel value="mesa">
                            <CommonTable
                                columns={columns}
                                rows={rows}
                                sortInfo={sortInfo}
                                handlePageChange={handlePageChange}
                                handleSort={handleSort}
                                actions={actions}
                            />
                        </TabPanel>
                    </Grid>
                )}
            </Grid>
        </TabContext>
    </Grid>
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    rows: GetMarketOrdersResponse;
    columns: CommonColumn<GetMarketOrderItem>[];
    actions: CommonAction<GetMarketOrderItem>[];
    filters: CommonFilter[];
    sortInfo: SortInformation;
    filterHeaderString: string;
    currentTab: string;
    loading: boolean;
    setFilterHeaderString(filterHeaderString: string): void;
    handlePageChange(pag: number): void;
    handleSort(sortInfo: SortInformation): void;
    handleTabChange(newValue: string): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OperationsOrdersChannelPage.propTypes = propTypes;

export default OperationsOrdersChannelPage;
