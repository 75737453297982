import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ChannelLayout from './ChannelLayout';
import { AuthContext } from 'context/auth.context';
import { useNavigate } from 'react-router-dom';
import { channelRoutesPaths } from 'router/routesPaths';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import useDialog from 'hooks/common/CommonGrid/dialog.hooks';

const ChannelLayoutContainer = (props: Props) => {
    // const {} = props;
    const { customerCode, selectedCustomer, isLoadingMarketAccounts, userData } =
        React.useContext(AuthContext);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleGoToWallet = () => {
        navigate(channelRoutesPaths.wallet);
    };
    const { isOpen, open, close } = useDialog();

    const childProps = {
        ...props,
        t,
        customerCode,
        handleGoToWallet,
        isLoadingMarketAccounts,
        userFullName: userData ? `${userData?.name} ${userData?.lastName}` : '-',
        isOpen,
        open,
        close,
        account: selectedCustomer,
    };

    return <ChannelLayout {...childProps} />;
};

const propTypes = {
    isMobile: PropTypes.bool.isRequired,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelLayoutContainer.propTypes = propTypes;

export default ChannelLayoutContainer;
