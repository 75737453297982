import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ReplacePasswordPage from './ReplacePasswordPage';
import { useLocation } from 'react-router-dom';
import { isMobile } from 'utils/helpers/commonHelper';

const ReplacePasswordPageContainer = (props: Props) => {
    const { isSigningUp } = props;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token') ?? '';

    React.useEffect(() => {
        if (isMobile()) {
            const route = isSigningUp
                ? `com.consultatio.plus://mail_confirmation_code?action=EMAIL_VERIFICATION&token=${location?.search?.split('=')[1]}`
                : `com.consultatio.plus://mail_confirmation_code?action=INTERNAL_RESET_PASSWORD&token=${location?.search?.split('=')[1]}`;
            window.location.href = route;
        }
    }, [location?.search, isSigningUp]);

    const childProps = {
        isSigningUp,
        token,
    };

    return <ReplacePasswordPage {...childProps} />;
};

const propTypes = { isSigningUp: PropTypes.bool };

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ReplacePasswordPageContainer.propTypes = propTypes;

export default ReplacePasswordPageContainer;
