import { SubRouteInfo } from 'utils/helpers/routesHelper';
import i18n from 'utils/i18n';
import BlogPage from 'components/pages/BlogPage';
import MarketPreferredPage from 'components/pages/MarketPreferredPage';
import MarketEquitiesPage from 'components/pages/MarketEquitiesPage';
import MarketBondsPage from 'components/pages/MarketBondsPage';
import MarketFundsPage from 'components/pages/MarketFundsPage';
import MarketCedearsPage from 'components/pages/MarketCedearsPage';
import ClientsWalletPage from 'components/pages/ClientsWalletPage';
import TreasuryBankAccountsPage from 'components/pages/TreasuryBankAccountsPage';
import MarketCollateralsPage from 'components/pages/MarketCollateralsPage';
import MarketFundDetailPage from 'components/pages/MarketFundDetailPage';
import ChannelHomePage from 'components/pages/ChannelHomePage';
import OperationsOrdersChannelPage from 'components/pages/OperationsOrdersChannelPage';
import OperationsFCIChannelPage from 'components/pages/OperationsFCIChannelPage';
import MarketInstrumentDetailPage from 'components/pages/MarketInstrumentDetailPage';
import ClientPage from 'components/pages/ClientsPage';
import { channelRoutesPaths } from '../routesPaths';
import MarketExchangePage from 'components/pages/MarketExchangePage';
import ChannelProfilePersonalDataPage from 'components/pages/ChannelProfilePersonalDataPage';
import ReportsTicketsPage from 'components/pages/ReportsTicketsPage';
import TreasuryOrdersChannelPage from 'components/pages/TreasuryOrdersChannelPage';
import ProfileChangePasswordPage from 'components/pages/ProfileChangePasswordPage';
import ReportsCurrencyPositionPage from 'components/pages/ReportsCurrencyPositionPage';
import ReportsReceiptsInvoicesPage from 'components/pages/ReportsReceiptsInvoicesPage';
import ReportsConsolidatedAccountPage from 'components/pages/ReportsConsolidatedAccountPage';
import ReportsHistoricalHoldingPage from 'components/pages/ReportsHistoricalHoldingPage';
import CommercialMaterialPage from 'components/pages/CommercialMaterialPage';
import CommercialDetailPage from 'components/pages/CommercialDetailPage';
import CommercialCorporatePage from 'components/pages/CommercialCorporatePage';
import CommercialInternationalPage from 'components/pages/CommercialInternationalPage';
import CommercialDocumentationPage from 'components/pages/CommercialDocumentationPage';
import ReportsDailyOperationsPage from 'components/pages/ReportsDailyOperationsPage';
import ReportsCollateralsPage from 'components/pages/ReportsCollateralsPage';

export const channelRoutes: Array<SubRouteInfo> = [
    {
        name: 'home',
        path: channelRoutesPaths.home,
        title: i18n.t('layout_main_navigation_home') as string,
        component: ChannelHomePage,
    },
    {
        name: 'clients',
        path: channelRoutesPaths.clients,
        title: i18n.t('layout_main_navigation_clients') as string,
        component: ClientPage,
    },

    {
        name: 'market',
        title: i18n.t('layout_main_navigation_market') as string,

        subRoutes: [
            {
                name: 'equity',
                path: channelRoutesPaths.equity,
                title: i18n.t('layout_main_navigation_market_equity') as string,
                component: MarketEquitiesPage,
            },
            {
                name: 'bond',
                path: channelRoutesPaths.bonds,
                title: i18n.t('layout_main_navigation_market_bonuses') as string,
                component: MarketBondsPage,
            },
            {
                name: 'funds',
                path: channelRoutesPaths.funds,
                title: i18n.t('layout_main_navigation_market_funds') as string,
                component: MarketFundsPage,
            },
            {
                name: 'cedears',
                path: channelRoutesPaths.cedears,
                title: i18n.t('layout_main_navigation_market_cedears') as string,
                component: MarketCedearsPage,
            },
            {
                name: 'exchange',
                path: channelRoutesPaths.exchange,
                title: i18n.t('layout_main_navigation_money_change') as string,
                component: MarketExchangePage,
            },
            {
                name: 'collateral',
                path: channelRoutesPaths.collaterals,
                title: i18n.t('layout_main_navigation_collaterals') as string,
                component: MarketCollateralsPage,
            },
            {
                name: 'preferred',
                path: channelRoutesPaths.preferred,
                title: i18n.t('layout_main_navigation_market_preferred') as string,
                component: MarketPreferredPage,
            },
        ],
    },
    {
        name: 'operations',
        title: i18n.t('layout_main_navigation_operations') as string,

        subRoutes: [
            {
                name: 'orders',
                path: channelRoutesPaths.orders,
                title: i18n.t('layout_main_navigation_operations_orders') as string,
                component: OperationsOrdersChannelPage,
            },
            {
                name: 'fci_request',
                path: channelRoutesPaths.referralFciRequest,
                title: i18n.t('layout_main_navigation_operations_referral_fci_request') as string,
                component: OperationsFCIChannelPage,
            },
        ],
    },
    {
        name: 'treasury',
        title: i18n.t('layout_main_navigation_treasury') as string,
        subRoutes: [
            {
                name: 'treasury-orders',
                path: channelRoutesPaths.treasuryOrders,
                title: i18n.t('layout_main_navigation_treasury_order') as string,
                component: TreasuryOrdersChannelPage,
            },
            {
                name: 'bank-accounts',
                path: channelRoutesPaths.treasuryBankAccounts,
                title: i18n.t('layout_main_navigation_treasury_register_bank_account') as string,
                component: TreasuryBankAccountsPage,
            },
        ],
    },
    {
        name: 'reports',
        title: i18n.t('layout_main_navigation_reports_ap') as string,
        subRoutes: [
            {
                name: 'consolidated_account',
                path: channelRoutesPaths.consolidatedAccount,
                title: i18n.t('layout_main_navigation_reports_consolidated_account') as string,
                component: ReportsConsolidatedAccountPage,
            },
            {
                name: 'receipts_and_invoices',
                path: channelRoutesPaths.receiptsInvoices,
                title: i18n.t('layout_main_navigation_reports_receipts_and_invoices') as string,
                component: ReportsReceiptsInvoicesPage,
            },
            {
                name: 'tickets',
                path: channelRoutesPaths.tickets,
                title: i18n.t('layout_main_navigation_reports_tickets') as string,
                component: ReportsTicketsPage,
            },
            {
                name: 'historical_holding',
                path: channelRoutesPaths.historicalHolding,
                title: i18n.t('layout_main_navigation_reports_historical_holding') as string,
                component: ReportsHistoricalHoldingPage,
            },
        ],
    },
    {
        name: 'reports-clients',
        title: i18n.t('layout_main_navigation_reports_clients') as string,
        subRoutes: [
            {
                name: 'reports_collaterals',
                path: channelRoutesPaths.reportsCollaterals,
                title: i18n.t('layout_main_navigation_client_reports_collaterals') as string,
                component: ReportsCollateralsPage,
            },
            {
                name: 'daily_reports',
                path: channelRoutesPaths.dailyReports,
                title: i18n.t('layout_main_navigation_client_reports_daily') as string,
                component: ReportsDailyOperationsPage,
            },
            {
                name: 'coin_position',
                path: channelRoutesPaths.coinPosition,
                title: i18n.t('layout_main_navigation_reports_coin_position') as string,
                component: ReportsCurrencyPositionPage,
            },
        ],
    },
    {
        name: 'commercial',
        title: i18n.t('layout_main_navigation_commercial') as string,
        subRoutes: [
            {
                name: 'commercial_material',
                path: channelRoutesPaths.commercialMaterial,
                title: i18n.t('layout_main_navigation_commercial_material') as string,
                component: CommercialMaterialPage,
            },
            {
                name: 'commercial_corporate',
                path: channelRoutesPaths.commercialCorporate,
                title: i18n.t('layout_main_navigation_commercial_corporate') as string,
                component: CommercialCorporatePage,
            },
            {
                name: 'commercial_international',
                path: channelRoutesPaths.commercialInternational,
                title: i18n.t('layout_main_navigation_commercial_international') as string,
                component: CommercialInternationalPage,
            },
            {
                name: 'commercial_documentation',
                path: channelRoutesPaths.commercialDocumentation,
                title: i18n.t('layout_main_navigation_commercial_documentation') as string,
                component: CommercialDocumentationPage,
            },
        ],
    },
    {
        name: 'blog',
        path: channelRoutesPaths.blog,
        title: i18n.t('layout_main_navigation_blog') as string,
        component: BlogPage,
    },

    {
        name: 'profile',
        title: i18n.t('layout_main_navigation_profile') as string,
        subRoutes: [
            {
                name: 'personalData',
                path: channelRoutesPaths.profilePersonalData,
                title: i18n.t('layout_main_navigation_personal_data') as string,
                component: ChannelProfilePersonalDataPage,
            },
            {
                name: 'replacePassword',
                path: channelRoutesPaths.replacePassword,
                title: i18n.t('layout_main_navigation_replace_password') as string,
                component: ProfileChangePasswordPage,
            },
        ],
    },
];

export const extraChannelRoutes: Array<SubRouteInfo> = [
    {
        name: 'wallet',
        path: channelRoutesPaths.wallet,
        title: i18n.t('layout_main_navigation_clients') as string,
        component: ClientsWalletPage,
    },
    {
        name: 'instrument-detail',
        path: channelRoutesPaths.instrumentDetail,
        component: MarketInstrumentDetailPage,
    },
    {
        name: 'fund-detail',
        path: channelRoutesPaths.fundDetail,
        component: MarketFundDetailPage,
    },
    {
        name: 'commercial-detail',
        path: channelRoutesPaths.commercialDetail,
        component: CommercialDetailPage,
    },
    {
        name: 'channel_base_route',
        path: '/',
        title: i18n.t('layout_main_navigation_home') as string,
        component: ChannelHomePage,
    },
];
