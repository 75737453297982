import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './UserInfo.styled';
import desktopLogo from 'assets/img/general/main_logo.svg';
import { Tooltip } from '@mui/material';

const UserInfo = (props: Props) => {
    const {
        isUF,
        textRef,
        navigateToHome,
        tooltipOpen,
        userFullName,
        customerCode,
        handleMouseEnter,
        handleMouseLeave,
    } = props;
    return (
        <St.Container>
            <St.HeaderDesktopLogo onClick={() => navigateToHome()}>
                <img src={desktopLogo} alt="logo" />
            </St.HeaderDesktopLogo>
            {isUF && customerCode && (
                <div>
                    <Tooltip title={userFullName} open={tooltipOpen}>
                        <St.AccountInfo
                            ref={textRef}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}>
                            {userFullName}
                        </St.AccountInfo>
                    </Tooltip>
                    <St.AccountInfoSubtitile>N° {customerCode}</St.AccountInfoSubtitile>
                </div>
            )}
        </St.Container>
    );
};

const propTypes = {
    userFullName: PropTypes.string.isRequired,
    isUF: PropTypes.bool.isRequired,
    tooltipOpen: PropTypes.bool.isRequired,
    handleMouseEnter: PropTypes.func.isRequired,
    handleMouseLeave: PropTypes.func.isRequired,
};

interface extraProps {
    customerCode: string | null;
    textRef: React.MutableRefObject<HTMLParagraphElement | null>;
    navigateToHome: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
UserInfo.propTypes = propTypes;

export default UserInfo;
