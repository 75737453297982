import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { CommonAction, CommonColumn, CommonFilter } from 'types/common/tableLayout.types';
import { GetCollateralOrderItem } from 'types/api/orders.types';
import { tCommon } from 'constants/appConstants';
import TableLayout from 'components/layouts/TableLayout';
import { useGetCollateralOrders } from 'hooks/api/orders.hooks';
import CloseMarketModal from 'components/common/CloseMarketModal';
import { GetMarketTimesResponse } from 'types/api/instruments.types';

const MarketCollateralsPage = (props: Props) => {
    const {
        columns,
        filters,
        actions,
        headAction,
        collateralTimes,
        t,
        openCloseModal,
        setOpenCloseModal,
        isMarketClosed,
    } = props;

    return (
        <>
            <TableLayout
                title={t('layout_main_navigation_collaterals', tCommon)}
                filters={filters}
                columns={columns}
                actions={actions}
                createAction={headAction}
                fetchData={useGetCollateralOrders}
                defaultSortFieldId="createdAt"
            />
            {collateralTimes != null &&
                isMarketClosed(
                    collateralTimes?.t0OpenTime,
                    collateralTimes?.t0CloseTime,
                    collateralTimes.isMarketOpen,
                ) && (
                    <CloseMarketModal
                        open={openCloseModal}
                        close={() => setOpenCloseModal(false)}
                        from={collateralTimes?.t0OpenTime}
                        to={collateralTimes?.t0CloseTime}
                    />
                )}
        </>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    openCloseModal: PropTypes.bool.isRequired,
    setOpenCloseModal: PropTypes.func.isRequired,
};

interface extraProps {
    columns: CommonColumn<GetCollateralOrderItem>[];
    filters: CommonFilter[];
    actions: CommonAction<GetCollateralOrderItem>[];
    headAction: CommonAction<GetCollateralOrderItem>;
    collateralTimes: GetMarketTimesResponse | null;
    isMarketClosed: (openTime: string, closeTime: string, isMarketOpen: boolean) => boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MarketCollateralsPage.propTypes = propTypes;

export default MarketCollateralsPage;
