import * as React from 'react';
import { Box, DialogContent, Typography } from '@mui/material';
import CloseIcon from 'assets/icons/close-line.svg?react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './InstrumentModal.styled';
import CustomIconCreator from 'components/common/CustomIconCreator';
import ActionButton from 'components/common/ActionButton';
import CustomAutoComplete from 'components/common/CustomAutoComplete';
import TopHeader from 'components/common/TopHeader';
import { CURRENCIES, TERMS, VALID_INSTRUMENT_TYPES } from 'constants/appConstants';
import { LockedFunctionalityNames } from 'types/api/rules.types';
import CustomLoader from 'components/common/CustomLoader';

const InstrumentModal = (props: Props) => {
    const {
        t,
        openModal,
        handleClose,
        selectedInstrument,
        term,
        isLoading,
        currency,
        handleTermChange,
        handleCurrencyChange,
        showUSDFields,
        handleUSDButtonClick,
        handleInstrumentViewSheet,
        isLockedByUserType,
    } = props;

    const renderUSDButton = (
        currencyType: string,
        showUSDFieldsCondition: boolean,
        USDFieldsContent: JSX.Element,
    ) => {
        const showText =
            currencyType === 'USD'
                ? t('instrument_show_valuation_usd').toUpperCase()
                : t('instrument_show_valuation_usd_cable').toUpperCase();

        const hideText =
            currencyType === 'USD'
                ? t('instrument_no_show_valuation_usd').toUpperCase()
                : t('instrument_no_show_valuation_usd_cable').toUpperCase();

        return (
            <>
                <St.ButtonContainer>
                    <ActionButton
                        variant="contained"
                        sx={{ width: ' fit-content', fontSize: '0.90rem' }}
                        onClick={handleUSDButtonClick}>
                        {showUSDFieldsCondition ? hideText : showText}
                    </ActionButton>
                </St.ButtonContainer>
                {showUSDFieldsCondition && USDFieldsContent}
            </>
        );
    };

    const USDFieldsContent = (
        <St.ConteinerTextUSD mt={2}>
            <St.BlueText fontSize={'1.5rem'}>{selectedInstrument?.ticker} 24hs</St.BlueText>
            {selectedInstrument?.price?.value !== 0 &&
                selectedInstrument?.price?.value !== undefined && (
                    <>
                        <Typography fontSize="1rem">
                            {t('instrument_price')}: {selectedInstrument?.price?.value}
                        </Typography>
                    </>
                )}
            {selectedInstrument?.valuation !== 0 && selectedInstrument?.valuation !== undefined && (
                <>
                    <Typography fontSize="1rem">
                        {t('instrument_valuation')}: {selectedInstrument?.valuation}
                    </Typography>
                </>
            )}
        </St.ConteinerTextUSD>
    );

    return (
        <St.Dialog open={openModal} onClose={handleClose} maxWidth={'sm'} disableScrollLock={true}>
            <St.DialogTitle>
                <TopHeader title={selectedInstrument?.type.toUpperCase()} />
                <Typography fontSize={'0.95rem'}>
                    {t('instrument_portfolio_holdings')}: {selectedInstrument?.holdingPercentage}%
                </Typography>
                <St.IconButton aria-label="close" onClick={handleClose}>
                    <CustomIconCreator icon={CloseIcon} alt="close-icon" />
                </St.IconButton>
            </St.DialogTitle>
            <St.Divider />
            <DialogContent>
                {isLoading ? (
                    <CustomLoader />
                ) : (
                    <>
                        {selectedInstrument ? (
                            <div>
                                <St.BlueText variant="h5">
                                    {selectedInstrument.currency || selectedInstrument.description}
                                </St.BlueText>
                                <Typography marginBottom="15px" variant="h6">
                                    {selectedInstrument.title || selectedInstrument.ticker}
                                </Typography>
                                <St.BlueText variant="h5">
                                    {selectedInstrument.quantity}
                                </St.BlueText>
                                <Typography fontSize={'0.95rem'}>
                                    {t('instrument_modal_total_quantity')}
                                </Typography>
                                {selectedInstrument?.type !== 'CURRENCY' && (
                                    <>
                                        {selectedInstrument.profitability !== 0 &&
                                            selectedInstrument.profitability !== undefined && (
                                                <>
                                                    <St.ConteinerText>
                                                        <St.BlueText variant="h5">
                                                            {selectedInstrument.profitability || ''}{' '}
                                                            (
                                                            {selectedInstrument.profitabilityPercentage ||
                                                                ''}
                                                            %)
                                                        </St.BlueText>
                                                        <Typography fontSize={'0.95rem'}>
                                                            {t('instrument_profitability')}
                                                        </Typography>
                                                    </St.ConteinerText>
                                                </>
                                            )}

                                        {selectedInstrument.ppc !== 0 &&
                                            selectedInstrument.ppc !== undefined && (
                                                <>
                                                    <St.ConteinerText>
                                                        <St.BlueText variant="h5">
                                                            ${selectedInstrument.ppc || ''} (
                                                            {selectedInstrument.ppcPercentage || ''}
                                                            %)
                                                        </St.BlueText>
                                                        <Typography fontSize={'0.95rem'}>
                                                            {t('instrument_ppc_description')}
                                                        </Typography>
                                                    </St.ConteinerText>
                                                </>
                                            )}

                                        {selectedInstrument.result !== 0 &&
                                            selectedInstrument.result !== undefined && (
                                                <>
                                                    <St.ConteinerText>
                                                        <St.BlueText variant="h5">
                                                            ${selectedInstrument.result || ''}
                                                        </St.BlueText>
                                                        <Typography fontSize={'0.95rem'}>
                                                            {t('instrument_result')}
                                                        </Typography>
                                                    </St.ConteinerText>
                                                </>
                                            )}

                                        {selectedInstrument?.price?.currency === 'USD CABLE' &&
                                            renderUSDButton(
                                                selectedInstrument?.price?.currency,
                                                showUSDFields,
                                                USDFieldsContent,
                                            )}
                                        {selectedInstrument?.price?.currency === 'USD' &&
                                            renderUSDButton(
                                                selectedInstrument?.price?.currency,
                                                showUSDFields,
                                                USDFieldsContent,
                                            )}
                                        {!isLockedByUserType('MARKET') &&
                                            VALID_INSTRUMENT_TYPES.includes(
                                                selectedInstrument.type,
                                            ) && (
                                                <>
                                                    <St.DashedDivider />
                                                    {selectedInstrument.type !== 'FUND' && (
                                                        <>
                                                            <Typography
                                                                fontSize={'0.95rem'}
                                                                paddingBottom={'20px'}>
                                                                {t('instrument_select_instrument')}
                                                            </Typography>
                                                            <Box
                                                                display="flex"
                                                                justifyContent="center"
                                                                gap={2}>
                                                                <Box flex={1}>
                                                                    <CustomAutoComplete
                                                                        id="term"
                                                                        value={term}
                                                                        onChange={handleTermChange}
                                                                        options={TERMS ?? []}
                                                                        label={
                                                                            t(
                                                                                'instrument_term',
                                                                            ) as string
                                                                        }
                                                                        placeholder={
                                                                            t(
                                                                                'term_placeholder',
                                                                            ) as string
                                                                        }
                                                                        renderOption={(
                                                                            props,
                                                                            option,
                                                                        ) => (
                                                                            <Box
                                                                                {...props}
                                                                                key={option.id}>
                                                                                {option.name}
                                                                            </Box>
                                                                        )}
                                                                    />
                                                                </Box>
                                                                <Box flex={1}>
                                                                    <CustomAutoComplete
                                                                        id="currency"
                                                                        value={currency}
                                                                        onChange={
                                                                            handleCurrencyChange
                                                                        }
                                                                        options={
                                                                            CURRENCIES
                                                                                ? selectedInstrument.type ===
                                                                                  'EQUITY'
                                                                                    ? CURRENCIES.slice(
                                                                                          0,
                                                                                          1,
                                                                                      )
                                                                                    : CURRENCIES
                                                                                : []
                                                                        }
                                                                        label={
                                                                            t(
                                                                                'instrument_currency',
                                                                            ) as string
                                                                        }
                                                                        placeholder={
                                                                            t(
                                                                                'currency_placeholder',
                                                                            ) as string
                                                                        }
                                                                        renderOption={(
                                                                            props,
                                                                            option,
                                                                        ) => (
                                                                            <Box
                                                                                {...props}
                                                                                key={option.id}>
                                                                                {option.name}
                                                                            </Box>
                                                                        )}
                                                                    />
                                                                </Box>
                                                            </Box>
                                                        </>
                                                    )}
                                                    <Box display="flex" justifyContent="center">
                                                        <ActionButton
                                                            variant="contained"
                                                            sx={{ cursor: 'pointer' }}
                                                            disabled={!currency || !term}
                                                            onClick={handleInstrumentViewSheet}>
                                                            {t(
                                                                'instrument_view_sheet',
                                                            ).toUpperCase()}
                                                        </ActionButton>
                                                    </Box>
                                                </>
                                            )}
                                    </>
                                )}
                            </div>
                        ) : (
                            <></>
                        )}
                    </>
                )}
            </DialogContent>
        </St.Dialog>
    );
};
const propTypes = {
    t: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

interface extraProps {
    openModal: boolean;
    handleClose: () => void;
    selectedInstrument: any;
    setOpenModal: (state: boolean) => void;
    term: any;
    currency: any;
    handleTermChange: (event: React.SyntheticEvent, newValue: any) => void;
    handleCurrencyChange: (event: React.SyntheticEvent, newValue: any) => void;
    showUSDFields: boolean;
    handleUSDButtonClick: () => void;
    handleInstrumentViewSheet: () => void;
    isLockedByUserType: (lockedType: LockedFunctionalityNames) => boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
InstrumentModal.prototype = propTypes;

export default InstrumentModal;
