import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import WalletInstrument from './WalletInstrument';
import { GetAccountStatusResponse } from 'types/api/marketAccount.types';
import { useTranslation } from 'react-i18next';
import { convertCurrencyFormat, formatNumber } from 'utils/helpers/commonHelper';
import {
    CurrencyFormatted,
    InstrumentInfo,
    InstrumentPrice,
    InstrumentType,
    InstrumentFormatted,
    ProcessedData,
} from 'types/api/instruments.types';
import { INSTRUMENTS_ORDERS } from 'constants/appConstants';

const WalletInstrumentContainer = (props: Props) => {
    const { selectedInstrumentType, accountStatusResponse, isLoadingAccountStatusResponse } = props;
    const { t } = useTranslation('home');
    const [openInstrumentModal, setOpenInstrumentModal] = React.useState<boolean>(false);
    const [selectedInstrument, setSelectedInstrument] = React.useState<any>(null);
    const [processedData, setProcessedData] = React.useState<ProcessedData[]>([]);

    const handleInstrumentClick = (instrument: any) => {
        setSelectedInstrument(instrument);
        setOpenInstrumentModal(true);
    };

    const instrumentsAmount =
        accountStatusResponse?.instruments.reduce((total, instrumentType) => {
            return total + (instrumentType.instruments?.length || 0);
        }, 0) || 0;

    const formatCurrenciesDetails = (data: InstrumentInfo[]): CurrencyFormatted[] => {
        const newValues = data.map(currency => {
            const currencyKey = currency.price?.currency.toUpperCase();

            return {
                currency: currencyKey,
                description: currency.description,
                quantity: currency.quantity,
                available: currency.quantityAvailable,
                notAvailable: formatNumber(currency.quantityNotAvailable),
                holdingPercentage: formatNumber(currency.percentageInWallet, 2),
                valuation: convertCurrencyFormat(
                    currency.value,
                    currencyKey === 'ARS' ? 'ARS' : 'USD',
                    2,
                ),
            };
        });

        return newValues;
    };

    const formatOtherInstrumentsDetails = (
        data: InstrumentInfo[],
        instrumentType: InstrumentType,
    ): InstrumentFormatted[] => {
        const newValues = data.map(instrument => {
            const price = instrument.price?.value;
            const valuation = instrument.value;
            const profitability = instrument.performance ? instrument.performance.value : undefined;
            const profitabilityPercentage = instrument.performance
                ? instrument.performance.percentage.toFixed(2)
                : undefined;
            const ppc = instrument.pppc ? instrument.pppc.value : undefined;
            const ppcPercentage = instrument.pppc
                ? instrument.pppc.percentage.toFixed(2)
                : undefined;
            const result = instrument.returns ?? undefined;
            const tricker = instrument.ticker;

            const currencyPrice = instrument.price?.currency;

            const priceInstrument: InstrumentPrice = {
                value: price,
                currency: currencyPrice,
            };

            return {
                description: instrument.description,
                ticker: tricker,
                price: priceInstrument,
                quantity: instrument.quantity,
                available: instrument.quantityAvailable,
                notAvailable: formatNumber(instrument.quantityNotAvailable),
                holdingPercentage: formatNumber(instrument.percentageInWallet, 2),
                valuation,
                profitability: profitability ?? undefined,
                profitabilityPercentage: Number(profitabilityPercentage) ?? undefined,
                ppc: ppc ?? undefined,
                ppcPercentage: Number(ppcPercentage) ?? undefined,
                result,
            };
        });

        return newValues;
    };

    const processInstrumentsData = (
        data: GetAccountStatusResponse | undefined,
        selectedInstrumentType: string,
    ) => {
        return (
            data?.instruments
                .filter(instrument =>
                    selectedInstrumentType !== ''
                        ? instrument.instrumentType === selectedInstrumentType
                        : instrument,
                )
                .sort((a, b) => {
                    const indexA = INSTRUMENTS_ORDERS.indexOf(a.instrumentType);
                    const indexB = INSTRUMENTS_ORDERS.indexOf(b.instrumentType);
                    return indexA - indexB;
                })
                .map(instrumentCategory => {
                    const instruments = instrumentCategory.instruments;

                    const infoFormatted =
                        instrumentCategory.instrumentType === 'CURRENCY'
                            ? formatCurrenciesDetails(instruments)
                            : formatOtherInstrumentsDetails(
                                  instruments,
                                  instrumentCategory.instrumentType,
                              );

                    return {
                        type: instrumentCategory.instrumentType,
                        quantity: instrumentCategory.instruments.length,
                        info: infoFormatted,
                    };
                }) || []
        );
    };

    React.useEffect(() => {
        setProcessedData(processInstrumentsData(accountStatusResponse, selectedInstrumentType));
    }, [selectedInstrumentType]);

    React.useEffect(() => {
        if (!isLoadingAccountStatusResponse && accountStatusResponse)
            setProcessedData(processInstrumentsData(accountStatusResponse, selectedInstrumentType));
    }, [isLoadingAccountStatusResponse]);

    const childProps = {
        ...props,
        t,
        instrumentsAmount,
        openInstrumentModal,
        setOpenInstrumentModal,
        selectedInstrument,
        handleInstrumentClick,
        processedData,
        isLoading: isLoadingAccountStatusResponse,
    };

    return <WalletInstrument {...childProps} />;
};

const propTypes = {};

interface extraProps {
    selectedInstrumentType: string;
    accountStatusResponse?: GetAccountStatusResponse;
    isLoadingAccountStatusResponse: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
WalletInstrumentContainer.propTypes = propTypes;

export default WalletInstrumentContainer;
