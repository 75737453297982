import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './ClientsWalletPage.styled';
import AccountStatus from 'components/common/AccountStatus';
import WalletInstrumentContainer from './WalletInstrument/WalletInstrument.container';
import { GetAccountStatusResponse } from 'types/api/marketAccount.types';

const ClientsWalletPage = (props: Props) => {
    const {
        selectedInstrumentType,
        setSelectedInstrumentType,
        accountStatusResponse,
        isLoadingAccountStatusResponse,
        liquidation,
        setLiquidation,
    } = props;
    return (
        <St.SectionWrapper>
            <AccountStatus
                {...{
                    accountStatusResponse,
                    isLoadingAccountStatusResponse,
                    setSelectedInstrumentType,
                    liquidation,
                    setLiquidation,
                }}
                isWallet
            />
            <WalletInstrumentContainer
                {...{
                    accountStatusResponse,
                    isLoadingAccountStatusResponse,
                    selectedInstrumentType,
                }}
            />
        </St.SectionWrapper>
    );
};

const propTypes = {};

interface extraProps {
    setSelectedInstrumentType: React.Dispatch<React.SetStateAction<string>>;
    selectedInstrumentType: string;
    accountStatusResponse?: GetAccountStatusResponse;
    isLoadingAccountStatusResponse: boolean;
    liquidation: boolean;
    setLiquidation: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientsWalletPage.propTypes = propTypes;

export default ClientsWalletPage;
