import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import OnboardingLayout from './OnboardingLayout';
import { onboardingRoutesPaths } from 'router/routesPaths';
import { useLocation } from 'react-router-dom';
import { onboardingRoutes } from 'router/subRoutes/onboardingLayout';
import useScreenWidth from 'hooks/common/screenWidth';
import { AuthContext } from 'context/auth.context';

const OnboardingLayoutContainer = (props: Props) => {
    // const {} = props;
    const { home, blog, replacePassword, deleteAccount } = onboardingRoutesPaths;
    const enableNavbar = ['/', home, blog, replacePassword, deleteAccount].includes(
        useLocation().pathname,
    );
    const screenWidth = useScreenWidth();
    const { alertNotification, isLoadingAlert, errorMessageAlert } = React.useContext(AuthContext);
    const [openAlertModal, setOpenAlertModal] = React.useState<boolean>(false);
    React.useMemo(() => {
        setOpenAlertModal(!!alertNotification);
    }, [alertNotification]);

    const childProps = {
        ...props,
        enableNavbar,
        routes: onboardingRoutes.slice(0, 2),
        isMobile: !(screenWidth > 950),
        alertNotification,
        setOpenAlertModal,
        openAlertModal,
        isLoadingAlert,
        errorMessageAlert,
    };

    return <OnboardingLayout {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OnboardingLayoutContainer.propTypes = propTypes;

export default OnboardingLayoutContainer;
