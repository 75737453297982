import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import {
    CommonAction,
    CommonColumn,
    CommonFilter,
    SortInformation,
} from 'types/common/tableLayout.types';
import { MarketAccount } from 'types/api/marketAccount.types';
import {
    CircularProgress,
    FormControlLabel,
    Grid,
    Switch,
    ToggleButton,
    ToggleButtonGroup,
} from '@mui/material';
import TableLayoutHeader from 'components/common/TableLayoutHeader';
import CommonTable from 'components/common/CommonTable';
import { tCommon } from 'constants/appConstants';

const ClientsPage = ({
    t,
    columns,
    actions,
    filters,
    loading,
    filterFavorite,
    filterHeaderString,
    rows,
    sortInfo,
    filterCurrency,
    setFilterCurrency,
    setFilterHeaderString,
    handlePageChange,
    handleSort,
    handleChangeFavoriteFilter,
}: Props) => (
    <Grid container>
        <Grid item xs={12}>
            <TableLayoutHeader
                title={t('clients_title')}
                filters={filters}
                filterHeaderString={filterHeaderString}
                setFilterHeaderString={setFilterHeaderString}
                columns={columns}
            />
        </Grid>
        {loading ? (
            <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" mt={5}>
                <CircularProgress color="primary" size="6rem" />
            </Grid>
        ) : (
            <>
                {!filterHeaderString.includes('ticker') && (
                    <Grid container>
                        <Grid item xs={6} display="flex" justifyContent="center">
                            <FormControlLabel
                                sx={{
                                    border: '1px solid rgba(0, 0, 0, 0.12)',
                                    borderRadius: '50px',
                                    p: '8px',
                                }}
                                control={
                                    <Switch
                                        checked={filterFavorite}
                                        onClick={handleChangeFavoriteFilter}
                                    />
                                }
                                label={t('only_favorites')}
                                labelPlacement="start"
                            />
                        </Grid>

                        {filterHeaderString && filterFavorite && (
                            <Grid item xs={6} display="flex" justifyContent="center">
                                <ToggleButtonGroup
                                    color="primary"
                                    value={filterCurrency}
                                    exclusive
                                    onChange={(_, newValue) => setFilterCurrency(newValue)}>
                                    <ToggleButton
                                        sx={{
                                            borderRadius: '50px',
                                        }}
                                        value="ars">
                                        {t('ars', tCommon)}
                                    </ToggleButton>
                                    <ToggleButton value="usd">{t('usd', tCommon)}</ToggleButton>
                                    <ToggleButton sx={{ borderRadius: '50px' }} value="usdc">
                                        {t('usdc', tCommon).toUpperCase()}
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                        )}
                    </Grid>
                )}
                <Grid item xs={12}>
                    <CommonTable
                        columns={columns}
                        actions={actions}
                        rows={rows}
                        sortInfo={sortInfo}
                        handlePageChange={handlePageChange}
                        handleSort={handleSort}
                    />
                </Grid>
            </>
        )}
    </Grid>
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    rows: any;
    columns: CommonColumn<MarketAccount>[];
    actions: CommonAction<MarketAccount>[];
    filters: CommonFilter[];
    filterFavorite: boolean;
    sortInfo: SortInformation;
    loading: boolean;
    filterHeaderString: string;
    filterCurrency: string;
    setFilterCurrency(filterCurrency: string): void;
    setFilterHeaderString(filterHeaderString: string): void;
    handlePageChange(pag: number): void;
    handleSort(sortInfo: SortInformation): void;
    handleChangeFavoriteFilter(): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientsPage.propTypes = propTypes;

export default ClientsPage;
