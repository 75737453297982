import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './RecommendedWallet.styled';
import { Box, Typography } from '@mui/material';
import CustomPieChart from 'components/common/CustomPieChart';
import { RecommendedWalletValues } from 'types/pages/home.types';
import CircularSpinner from 'components/common/CircularSpinner';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';

const RecommendedWallet = (props: Props) => {
    const { t, values, isLoading } = props;

    return (
        <St.RecommendedWalletWrapper>
            <St.RecommendedWalletContainer>
                <Typography variant="h4" color="primary">
                    {t('recommended_wallet_title')}
                </Typography>
                {isLoading ? (
                    <Box sx={{ marginTop: '10px' }}>
                        <CircularSpinner />
                    </Box>
                ) : (
                    <St.SwiperCustomDots>
                        <Swiper
                            modules={[Pagination, Autoplay]}
                            pagination={{ clickable: true }}
                            autoplay={{ delay: 3000 }}
                            spaceBetween={30}
                            slidesPerView={1}
                            style={{ width: '700px' }}>
                            {values &&
                                Object.keys(values).map((item, key) => {
                                    const { colors, labels, series } =
                                        values[item as 'funds' | 'coins' | 'credits'];
                                    return (
                                        <SwiperSlide key={key}>
                                            <St.CarouselItemContainer>
                                                <St.AvailabeByText variant="h5">
                                                    {t(`recommended_wallet_${item}_subtitle`)}
                                                </St.AvailabeByText>
                                                <St.PieChartContainer>
                                                    <CustomPieChart
                                                        id="recommended-wallet-chart"
                                                        width={'100%'}
                                                        height={'100%'}
                                                        labels={labels ?? []}
                                                        series={series ?? []}
                                                        colors={colors}
                                                    />
                                                </St.PieChartContainer>
                                            </St.CarouselItemContainer>
                                        </SwiperSlide>
                                    );
                                })}
                        </Swiper>
                    </St.SwiperCustomDots>
                )}
            </St.RecommendedWalletContainer>
        </St.RecommendedWalletWrapper>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

interface extraProps {
    values?: RecommendedWalletValues;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
RecommendedWallet.propTypes = propTypes;

export default RecommendedWallet;
