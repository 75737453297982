import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CustomDatePicker from './CustomDatePicker';
import { useTranslation } from 'react-i18next';
import { getLanguage } from 'storage/auth.storage';
import { Typography } from '@mui/material';
import St from './CustomDatePicker.styled';
import moment from 'moment';

const CustomDatePickerContainer = (props: Props) => {
    const {
        value,
        setFieldValue,
        fieldValue,
        disableCurrentDay,
        disabledDates,
        disableWeekends = false,
        disablePastDaysFromN,
        disableFutureDaysFromN,
        disableYears,
    } = props;
    const { t } = useTranslation();
    const locale = React.useMemo(() => new Date(value), [value]);
    const currentDate = new Date();
    const disablePastIsString = typeof disablePastDaysFromN === 'string';
    const disableFutureIsString = typeof disableFutureDaysFromN === 'string';
    const lang = getLanguage()?.split('-')[0];

    const handlerDateSelector = (newValue: any): void => {
        let getMilis = null;
        if (newValue && newValue._d) {
            getMilis = new Date(newValue._d);
            const offset = getMilis.getTimezoneOffset();
            if (offset < 0) {
                getMilis.setMinutes(Math.abs(offset));
            }
        }
        setFieldValue(fieldValue, getMilis ? getMilis.getTime() : '');
    };

    const CustomToolbar = (value: Date) => {
        let format: null | string = null;
        if (value instanceof Date && value.toString() !== 'Invalid Date') {
            const arrOfVals = String(value).split(' ');
            format = `${t(`${arrOfVals[0]}`, { ns: 'date' })}, ${t(`${arrOfVals[1]}`, {
                ns: 'date',
            })} ${arrOfVals[2]} - ${arrOfVals[3]}`;
        }

        return (
            <St.Box>
                <Typography variant="h5">{format ?? t('select_date')}</Typography>
            </St.Box>
        );
    };

    const dateValue = React.useMemo(() => {
        return value === null
            ? ''
            : new Date(locale.getUTCFullYear(), locale.getUTCMonth(), locale.getUTCDate());
    }, [value, locale]);

    const validDate = moment(dateValue).isValid();

    const handleClearDate = () => {
        handlerDateSelector('');
    };

    const handleDisable = (day: number | Date) => {
        const date = moment(day).format('YYYY/MM/DD').replaceAll('/', '-');

        if (disablePastDaysFromN) {
            const iteratedDate = new Date(date.replaceAll('/', '-'));
            if (disablePastIsString) {
                const b = new Date(disablePastDaysFromN.replaceAll('/', '-'));
                if (iteratedDate <= b) return true;
            } else {
                if (iteratedDate <= currentDate) return true;
            }
        }
        if (disableFutureDaysFromN) {
            const iteratedDate = new Date(date.replaceAll('/', '-'));
            if (disableFutureIsString) {
                const b = new Date(disableFutureDaysFromN.replaceAll('/', '-'));
                if (iteratedDate >= b) return true;
            } else {
                if (iteratedDate >= currentDate) return true;
            }
        }

        if (
            disableCurrentDay &&
            moment(currentDate).format('YYYY/MM/DD').replaceAll('/', '-') === date
        )
            return true;
        if (disableWeekends) {
            const dayOfWeek = new Date(day).getDay();
            if ([0, 6].includes(dayOfWeek)) return true;
        }
        return (disabledDates || []).includes(`${date}`);
    };

    const childProps = {
        ...props,
        t,
        handlerDateSelector,
        lang,
        locale,
        CustomToolbar,
        handleClearDate,
        validDate,
        dateValue,
        activateRenderDay:
            !!disabledDates ||
            disableWeekends ||
            !!disableCurrentDay ||
            disablePastIsString ||
            disableFutureIsString,
        handleDisable,
        disablePast: disablePastIsString ? false : disablePastDaysFromN,
        disableFuture: disableFutureIsString ? false : disableFutureDaysFromN,
        disableYears: disableYears ?? false,
    };

    return <CustomDatePicker {...childProps} />;
};

const propTypes = {};

interface extraProps {
    value: number | string;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    fieldValue: string;
    isReadOnly?: boolean;
    minDate?: number | Date;
    maxDate?: number | Date;
    errorMessage?: string;
    placeholder?: string;
    required?: boolean;
    width?: string;
    minWidth?: string;
    disablePastDaysFromN?: boolean | string; // if true disables all days, if is string from n
    disableFutureDaysFromN?: boolean | string; // if true disables all days, if is string from n
    disableCurrentDay?: boolean;
    disabledDates?: string[]; // 'YYYY/MM/DD'
    disableWeekends?: boolean;
    disableClear?: boolean;
    disableYears?: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CustomDatePickerContainer.propTypes = propTypes;

export default CustomDatePickerContainer;
