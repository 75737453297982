import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './ExportButton.styled';
import { LoadingButton } from '@mui/lab';

const ExportButton = (props: Props) => {
    const { t, exportLoading, handleExportData } = props;

    return (
        <LoadingButton
            variant="outlined"
            disabled={exportLoading}
            loading={exportLoading}
            onClick={handleExportData}
            sx={{ px: 2 }}>
            {t('export_button')}
        </LoadingButton>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    exportLoading: PropTypes.bool.isRequired,
    handleExportData: PropTypes.func.isRequired,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ExportButton.propTypes = propTypes;

export default ExportButton;
