import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import VideoModal from './VideoModal';
import { useTranslation } from 'react-i18next';

const VideoModalContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation();

    const childProps = {
        ...props,
        t,
    };

    return <VideoModal {...childProps} />;
};

const propTypes = {
    title: PropTypes.string.isRequired,
};

interface extraProps {
    open: boolean;
    url: string;
    close: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
VideoModalContainer.propTypes = propTypes;

export default VideoModalContainer;
