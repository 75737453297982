import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Box, IconButton, Tooltip } from '@mui/material';
import { CANCELLED, ICON_CELL_SIZE, PENDING, REJECTED } from 'constants/appConstants';
import { AffidavitObject, OrderStatusTypes } from 'types/api/orders.types';
import CustomIconCreator from '../CustomIconCreator';
import UploadIcon from 'assets/icons/upload-line.svg?react';
import InfoIcon from 'assets/icons/information-line.svg?react';
import DDJJModal from 'components/pages/HomePage/DDJJModal';
import UploadDDJJ from '../UploadDDJJ';

const ChannelsDDJJCellInfo = ({
    declaration,
    uploadFile,
    isOpen,
    open,
    close,
    t,
    isChannel,
    orderStatus,
}: Props) => (
    <Box display="flex" alignItems="center">
        {t(`declaration_${declaration?.status}`)}
        {(declaration?.status === PENDING ||
            (declaration?.status === REJECTED && isChannel && orderStatus !== CANCELLED)) && (
            <>
                {uploadFile ? (
                    <Tooltip title={t('upload_declaration')}>
                        <IconButton onClick={open}>
                            <CustomIconCreator icon={UploadIcon} sx={ICON_CELL_SIZE} />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <Tooltip title={t('accept_declaration')}>
                        <IconButton onClick={open}>
                            <CustomIconCreator icon={InfoIcon} sx={ICON_CELL_SIZE} />
                        </IconButton>
                    </Tooltip>
                )}
            </>
        )}
        {isOpen && (
            <>
                {uploadFile ? (
                    <UploadDDJJ isOpen={isOpen} close={close} id={declaration?.id} />
                ) : (
                    <DDJJModal isOpen={isOpen} close={close} />
                )}
            </>
        )}
    </Box>
);

const propTypes = {
    t: PropTypes.func.isRequired,
    isChannel: PropTypes.bool.isRequired,
};

interface extraProps {
    declaration?: AffidavitObject | null;
    uploadFile?: boolean;
    isOpen: boolean;
    open(): void;
    close(): void;
    orderStatus?: OrderStatusTypes;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsDDJJCellInfo.propTypes = propTypes;

export default ChannelsDDJJCellInfo;
