import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Box, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import { Form, FormikContextType, FormikProvider } from 'formik';
import CustomInput from 'components/common/CustomInput';
import ActionButton from 'components/common/ActionButton';
import { ExteriorBankAccount } from 'types/api/cashflow.types';
import FileUploader from 'components/common/FileUploader';

const CreateExteriorBankAccount = ({
    formik,
    isLoading,
    type,
    intermediary,
    showIntermediary,
    setShowIntermediary,
    handleIntermediary,
    close,
    t,
}: Props) => {
    const { errors, values, setFieldValue } = formik;
    return (
        <FormikProvider value={formik}>
            <Form>
                <Grid container gap={2} mt={2}>
                    <Grid item xs={12}>
                        <CustomInput
                            field="appellation"
                            label={t('appellation')}
                            errors={errors}
                            placeholder={t('appellation')}
                            setFieldValue={setFieldValue}
                            values={values}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomInput
                            field="aba"
                            label={t('aba')}
                            errors={errors}
                            placeholder={t('aba')}
                            setFieldValue={setFieldValue}
                            values={values}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomInput
                            field="swift"
                            label={t('swift')}
                            errors={errors}
                            placeholder={t('swift')}
                            setFieldValue={setFieldValue}
                            values={values}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomInput
                            field="iban"
                            label={t('iban')}
                            errors={errors}
                            placeholder={t('iban')}
                            setFieldValue={setFieldValue}
                            values={values}
                        />
                    </Grid>

                    {type === 'BROKER' && (
                        <>
                            <Grid item xs={12}>
                                <CustomInput
                                    field="broker"
                                    errors={errors}
                                    label={t('broker_name')}
                                    placeholder={t('broker_name')}
                                    setFieldValue={setFieldValue}
                                    values={values}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomInput
                                    field="brokerCustomerCode"
                                    errors={errors}
                                    label={t('broker_customer_code')}
                                    placeholder={t('broker_customer_code')}
                                    setFieldValue={setFieldValue}
                                    values={values}
                                />
                            </Grid>
                        </>
                    )}
                    <Typography>{t('statement_alert')}</Typography>
                    <Grid item xs={12} m={1.5}>
                        <FileUploader
                            field="statementOne"
                            label={t('statement_one')}
                            values={values}
                            setFieldValue={setFieldValue as any}
                            errors={errors}
                        />
                    </Grid>
                    <Grid item xs={12} m={1.5}>
                        <FileUploader
                            field="statementTwo"
                            label={t('statement_two')}
                            values={values}
                            setFieldValue={setFieldValue as any}
                            errors={errors}
                        />
                    </Grid>
                    <Grid item xs={12} m={1.5}>
                        <FileUploader
                            field="statementThree"
                            label={t('statement_three')}
                            values={values}
                            setFieldValue={setFieldValue as any}
                            errors={errors}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            value={showIntermediary}
                            checked={showIntermediary}
                            onClick={() => setShowIntermediary((prevValue: boolean) => !prevValue)}
                            control={<Checkbox />}
                            label={t('intermediary_text')}
                        />
                    </Grid>

                    {showIntermediary && (
                        <>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    name="appellation"
                                    label={t('appellation')}
                                    placeholder={t('appellation')}
                                    onChange={({ target: { name, value } }) =>
                                        handleIntermediary(name, value)
                                    }
                                    value={intermediary.appellation}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    name="aba"
                                    label={t('aba')}
                                    placeholder={t('aba')}
                                    onChange={({ target: { name, value } }) =>
                                        handleIntermediary(name, value)
                                    }
                                    value={intermediary.aba}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    name="swift"
                                    label={t('swift')}
                                    placeholder={t('swift')}
                                    onChange={({ target: { name, value } }) =>
                                        handleIntermediary(name, value)
                                    }
                                    value={intermediary.swift}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    name="iban"
                                    label={t('iban')}
                                    placeholder={t('iban')}
                                    onChange={({ target: { name, value } }) =>
                                        handleIntermediary(name, value)
                                    }
                                    value={intermediary.iban}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
                <Box display="flex" gap="10px">
                    <ActionButton fullWidth variant="outlined" onClick={close} disabled={isLoading}>
                        {t('cancel')}
                    </ActionButton>
                    <ActionButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        loading={isLoading}
                        disabled={isLoading}>
                        {t('create')}
                    </ActionButton>
                </Box>
            </Form>
        </FormikProvider>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    formik: FormikContextType<any>;
    isLoading: boolean;
    type: string;
    showIntermediary: boolean;
    setShowIntermediary: any;
    intermediary: ExteriorBankAccount;
    handleIntermediary(name: string, value: string): void;
    close(): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CreateExteriorBankAccount.propTypes = propTypes;

export default CreateExteriorBankAccount;
