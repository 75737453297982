import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './HeaderFilters.styled';
import { Grid, TextField } from '@mui/material';
import ActionButton from 'components/common/ActionButton';
import HeaderFilterDate from './HeaderFilterDate';
import HeadersSelect from './HeadersSelect';
import HeaderFilterText from './HeaderFilterText';
import RequestAutocomplete from 'components/common/RequestAutocomplete';
import { CommonFilter } from 'types/common/tableLayout.types';

const FilterComponents: { [key: string]: any } = {
    text: HeaderFilterText,
    number: HeaderFilterText,
    date: HeaderFilterDate,
    select: HeadersSelect,
    multiple: HeadersSelect,
    requestAutocomplete: RequestAutocomplete,
    requestAutocompleteMultiple: RequestAutocomplete,
    default: TextField,
};

const HeaderFilters = (props: Props) => {
    const {
        t,
        filterString,
        filters,
        onSubmit,
        setFilterString,
        onClear,
        customFilterValidation,
        validationError,
    } = props;

    return (
        <St.Container>
            <Grid container spacing={1}>
                {filters.map(filter => {
                    const {
                        label,
                        placeholder,
                        query,
                        type,
                        id,
                        options,
                        regex,
                        requestAutocomplete,
                        disablePastDaysFromN,
                        disableFutureDaysFromN,
                        disableYears,
                    } = filter;
                    const Component = FilterComponents[type] || FilterComponents.default;

                    return (
                        <Grid key={id} item xs={12} sm={6} md={4} lg={3} xl={2}>
                            <Component
                                type={type}
                                regex={regex}
                                query={query}
                                label={label}
                                placeholder={placeholder}
                                filterstring={filterString}
                                setfilterstring={setFilterString}
                                options={options || []}
                                requestAutocomplete={requestAutocomplete}
                                disablePastDaysFromN={disablePastDaysFromN}
                                disableFutureDaysFromN={disableFutureDaysFromN}
                                disableYears={disableYears}
                            />
                        </Grid>
                    );
                })}
            </Grid>
            {customFilterValidation && !customFilterValidation(filterString).result && (
                <St.NoDateData>{validationError}</St.NoDateData>
            )}
            <St.FooterButtons direction={'row'} spacing={1}>
                <ActionButton variant="outlined" onClick={onClear} size="small" type="button">
                    {t('clear')}
                </ActionButton>
                <ActionButton onClick={onSubmit} size="small" variant="contained" type="button">
                    {t('filter')}
                </ActionButton>
            </St.FooterButtons>
        </St.Container>
    );
};

const propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

interface extraProps {
    filters: CommonFilter[];
    filterString: string;
    setFilterString(filterString: string): void;
    customFilterValidation?: (value: string) => { result: boolean; error: string | undefined };
    validationError: string | undefined;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
HeaderFilters.propTypes = propTypes;

export default HeaderFilters;
