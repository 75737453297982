import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ClientsWalletPage from './ClientsWalletPage';
import { useGetAccountStatus } from 'hooks/api/marketAccount.hooks';

const ClientsWalletPageContainer = (props: Props) => {
    const [selectedInstrumentType, setSelectedInstrumentType] = React.useState<string>('');
    const [liquidation, setLiquidation] = React.useState<boolean>(false);
    const { data: accountStatusResponse, isLoading: isLoadingAccountStatusResponse } =
        useGetAccountStatus(liquidation);

    const childProps = {
        ...props,
        setSelectedInstrumentType,
        selectedInstrumentType,
        accountStatusResponse,
        isLoadingAccountStatusResponse,
        liquidation,
        setLiquidation,
    };

    return <ClientsWalletPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientsWalletPageContainer.propTypes = propTypes;

export default ClientsWalletPageContainer;
