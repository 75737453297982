import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import TableLayout from 'components/layouts/TableLayout';
import { CommonColumn, CommonFilter } from 'types/common/tableLayout.types';
import { useGetFundsOrdersByCustomerCode } from 'hooks/api/orders.hooks';
import { ExtraQueryFiltersProps } from 'types/common/CommonGrid/CommonGridFormModal.types';

const OperationsFCIRequestsPage = ({ columns, filters, defaultFilters, t }: Props) => (
    <TableLayout
        title={t('layout_main_navigation_operations_referral_fci_request')}
        columns={columns}
        filters={filters}
        fetchData={useGetFundsOrdersByCustomerCode}
        defaultFilters={defaultFilters}
        defaultSortFieldId="createdAt"
    />
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    columns: CommonColumn<any>[];
    defaultFilters: ExtraQueryFiltersProps[];
    filters: CommonFilter[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OperationsFCIRequestsPage.propTypes = propTypes;

export default OperationsFCIRequestsPage;
