import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Typography,
} from '@mui/material';
import { LOCAL_ACCOUNTS } from 'constants/appConstants';
import InfoLabel from 'components/common/InfoLabel';
import { formatDateWithHour } from 'utils/helpers/dateHelper';
import { GetBankAccountsItem, SortedBankAccount } from 'types/api/cashflow.types';
import FileDownloader from 'components/common/FileDownloader';
import ActionButton from 'components/common/ActionButton';
import CustomIconCreator from 'components/common/CustomIconCreator';
import CloseIcon from 'assets/icons/close-line.svg?react';

const BankAccountDetail = ({
    row: { accountNumber, currency, description, type, reason, createdAt, decisionAt },
    accountsInfo,
    t,
    open,
    close,
}: Props) => (
    <Dialog open={open} onClose={close} maxWidth="md" fullWidth>
        <DialogTitle>{t('bank_account_details')}</DialogTitle>
        <IconButton
            aria-label="close"
            onClick={close}
            sx={{
                color: theme => theme.palette.grey[500],
                position: 'absolute',
                right: 8,
                top: 8,
            }}>
            <CustomIconCreator icon={CloseIcon} alt="close-icon" />
        </IconButton>
        <DialogContent>
            <Grid container spacing={2} alignItems="baseline">
                <Grid container item xs={accountsInfo?.INTERMEDIARY ? 7 : 12}>
                    <Typography fontWeight={300} fontSize={18} mb={1.5} color={'primary'}>
                        {t('bank_account_data')}
                    </Typography>
                    {LOCAL_ACCOUNTS.includes(type) && (
                        <>
                            <InfoLabel label={t('account_number')} text={accountNumber} />
                            <InfoLabel label={t('currency')} text={currency} />
                            <InfoLabel label={t('description')} text={description} />
                            <InfoLabel
                                label={t('created_at')}
                                text={formatDateWithHour(createdAt)}
                            />
                        </>
                    )}
                    {accountsInfo?.BROKER && (
                        <>
                            <InfoLabel
                                label={t('broker_name')}
                                text={accountsInfo.BROKER?.broker}
                            />
                            <InfoLabel
                                label={t('broker_customer_code')}
                                text={accountsInfo.BROKER?.brokerCustomerCode}
                            />
                            <InfoLabel
                                label={t('appellation')}
                                text={accountsInfo.BROKER?.appellation}
                            />
                            <InfoLabel label={t('aba')} text={accountsInfo.BROKER?.aba} />
                            <InfoLabel label={t('swift')} text={accountsInfo.BROKER?.swift} />
                            <InfoLabel label={t('iban')} text={accountsInfo.BROKER?.iban} />
                            <InfoLabel
                                label={t('created_at')}
                                text={formatDateWithHour(createdAt)}
                            />

                            {accountsInfo.BROKER?.exteriorBankAccountStatements?.map(
                                ({ statementPath }, index: number) => (
                                    <Grid item xs={12} mt={1.5} key={index}>
                                        <FileDownloader
                                            label={t(`statement_${index + 1}_detail`)}
                                            fileUrl={statementPath}
                                        />
                                    </Grid>
                                ),
                            )}
                        </>
                    )}

                    {accountsInfo?.DESTINATION && (
                        <>
                            <InfoLabel
                                label={t('appellation')}
                                text={accountsInfo.DESTINATION?.appellation}
                            />
                            <InfoLabel label={t('aba')} text={accountsInfo.DESTINATION?.aba} />
                            <InfoLabel label={t('swift')} text={accountsInfo.DESTINATION?.swift} />
                            <InfoLabel label={t('iban')} text={accountsInfo.DESTINATION?.iban} />
                            <InfoLabel
                                label={t('created_at')}
                                text={formatDateWithHour(createdAt)}
                            />

                            {accountsInfo.DESTINATION?.exteriorBankAccountStatements?.map(
                                ({ statementPath }, index: number) => (
                                    <Grid item xs={12} mt={1.5} key={index}>
                                        <FileDownloader
                                            label={t(`statement_${index + 1}_detail`)}
                                            fileUrl={statementPath}
                                        />
                                    </Grid>
                                ),
                            )}
                        </>
                    )}
                </Grid>

                {accountsInfo?.INTERMEDIARY && (
                    <Grid container item xs={5}>
                        <Typography fontWeight={300} fontSize={18} mb={1.5} color={'primary'}>
                            {t('bank_account_intermediary_detail')}
                        </Typography>
                        {accountsInfo.INTERMEDIARY.map(
                            ({ appellation, aba, swift, iban, exteriorBankAccountStatements }) => (
                                <>
                                    <InfoLabel label={t('appellation')} text={appellation} />
                                    <InfoLabel label={t('aba')} text={aba} />
                                    <InfoLabel label={t('swift')} text={swift} />
                                    <InfoLabel label={t('iban')} text={iban} />
                                    <InfoLabel
                                        label={t('created_at')}
                                        text={formatDateWithHour(createdAt)}
                                    />

                                    {exteriorBankAccountStatements?.map(
                                        ({ statementPath }, index: number) => (
                                            <Grid item xs={12} mt={1.5} key={index}>
                                                <FileDownloader
                                                    label={t(`statement_${index + 1}_detail`)}
                                                    fileUrl={statementPath}
                                                />
                                            </Grid>
                                        ),
                                    )}
                                </>
                            ),
                        )}
                    </Grid>
                )}

                {reason && (
                    <>
                        <Divider sx={{ width: '100%', mt: 1.25 }} />
                        <InfoLabel column={12} label={t('rejected_reason')} text={reason} />
                    </>
                )}
            </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', marginBottom: '10px' }}>
            <ActionButton onClick={close} variant="contained">
                {t('back')}
            </ActionButton>
        </DialogActions>
    </Dialog>
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    row: GetBankAccountsItem;
    accountsInfo?: SortedBankAccount;
    open: boolean;
    close: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
BankAccountDetail.propTypes = propTypes;

export default BankAccountDetail;
