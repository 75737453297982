import * as React from 'react';
import { AlertColor } from '@mui/material';
import { createContext, useContext, useMemo, useState, ReactNode } from 'react';

interface CommonGridContextProps {
    needRefresh: boolean;
    setNeedRefresh: (value: boolean) => void;
    openSnackBar: boolean;
    setOpenSnackBar: (value: boolean) => void;
    severity: AlertColor;
    setSeverity: (value: AlertColor) => void;
    message: string;
    setMessage: (value: string) => void;
    openCreateModal: boolean;
    setOpenCreateModal: (value: boolean) => void;
    showDisabled: boolean;
    setShowDisabled: (value: boolean) => void;
    filterQueryString: string | undefined;
    updateFilterQueryString: (newString: string) => void;
}

export const CommonGridContext = createContext<CommonGridContextProps>(
    {} as CommonGridContextProps,
);

export const useCommonGrid = () => useContext(CommonGridContext);

interface CommonGridProviderProps {
    children: ReactNode;
}

export const CommonGridProvider = ({ children }: CommonGridProviderProps) => {
    // Estados originales del CommonGridContext
    const [needRefresh, setNeedRefresh] = useState<boolean>(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [severity, setSeverity] = useState<AlertColor>('success');
    const [message, setMessage] = useState('');
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [showDisabled, setShowDisabled] = useState(false);

    // Estados del FilterProvider
    const [filterQueryString, setFilterQueryString] = useState<string | undefined>(undefined);

    const updateFilterQueryString = (newString: string) => {
        setFilterQueryString(newString);
    };

    // Memorizar el valor combinado
    const MemoValue = useMemo(() => {
        return {
            severity,
            message,
            needRefresh,
            openCreateModal,
            openSnackBar,
            setSeverity,
            setMessage,
            setNeedRefresh,
            setOpenCreateModal,
            setOpenSnackBar,
            setShowDisabled,
            showDisabled,
            filterQueryString,
            updateFilterQueryString,
        };
    }, [
        severity,
        message,
        needRefresh,
        openCreateModal,
        openSnackBar,
        setSeverity,
        setMessage,
        setNeedRefresh,
        setOpenCreateModal,
        setOpenSnackBar,
        setShowDisabled,
        showDisabled,
        filterQueryString,
    ]);

    return <CommonGridContext.Provider value={MemoValue}>{children}</CommonGridContext.Provider>;
};
