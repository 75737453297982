import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './CommercialItemButton.styled';
import VideoModal from './VideoModal';

const CommercialItemButton = (props: Props) => {
    const { name, handleSubmit, videoUrl, setVideoUrl } = props;

    return (
        <>
            <St.ComercialItemButton onClick={handleSubmit} variant="outlined">
                {name}
            </St.ComercialItemButton>
            {videoUrl && (
                <VideoModal
                    title={name}
                    close={() => setVideoUrl(null)}
                    open={!!videoUrl}
                    url={videoUrl}
                />
            )}
        </>
    );
};

const propTypes = {
    name: PropTypes.string.isRequired,
    setVideoUrl: PropTypes.func.isRequired,
};

interface extraProps {
    handleSubmit: () => void;
    videoUrl: string | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CommercialItemButton.propTypes = propTypes;

export default CommercialItemButton;
