import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import InstrumentBuySell from './InstrumentBuySell';
import {
    InstrumentPricesDetail,
    LimitPriceStateInfo,
} from 'types/pages/marketInstrumentDetail.types';
import { AccountInstrument, GetAccountStatusResponse } from 'types/api/marketAccount.types';
import { useTranslation } from 'react-i18next';
import { CommonSelectType, Currency } from 'types/common/general.types';
import {
    GetInstrumentsItem,
    InstrumentRule,
    TickerRequieresDeclaration,
} from 'types/api/instruments.types';
import { convertToNumber, formatNumber } from 'utils/helpers/commonHelper';
import { useGetMarketPreview } from 'hooks/api/orders.hooks';
import { BUYSELL, GetMarketPreviewRequest } from 'types/api/orders.types';
import { AuthContext } from 'context/auth.context';
import { RuleConditions } from 'types/api/rules.types';
import { useGetProfileInfo } from 'hooks/api/auth.hooks';
import { TERMS } from 'constants/appConstants';
import { SourceInfo } from 'react-number-format';

const InstrumentBuySellContainer = (props: Props) => {
    const {
        term: currentTerm,
        rules,
        buySell,
        currency: currentCurrency,
        setBuySell,
        instrument,
        limitIndex,
        accountStatus,
        tickerPriceInfo,
        isBond,
        currencies,
        setTerm: setCurrentTerm,
    } = props;

    const { t } = useTranslation('market');
    const { customerCode, selectedCustomer, userType } = React.useContext(AuthContext);
    const { isLoading: isLoadingProfile, errorMessage: errorProfileInfo } = useGetProfileInfo(
        userType === 'APP_LEGAL' || userType === 'APP_PHYSICAL',
    );
    const [quantity, setQuantity] = React.useState<number | null>(0);
    const [amount, setAmount] = React.useState<number | null>(0);
    const [limitPrice, setLimitPrice] = React.useState(
        tickerPriceInfo?.offers ? tickerPriceInfo?.offers[0].price : 0,
    );
    const [isManualChange, setIsManualChange] = React.useState(false);

    const [openPrevisualizeModal, setOpenPrevisualizeModal] = React.useState<boolean>(false);
    const [isMarketPrice, setIsMarketPrice] = React.useState<boolean>(true);
    const [currency, setCurrency] = React.useState(
        currencies?.find(c => c.name === currentCurrency) ?? null,
    );
    const [term, setTerm] = React.useState(TERMS.find(t => t.name === currentTerm) ?? null);
    const {
        errorMessage,
        getMarketPreview,
        isLoading: isLoadingSubmit,
        marketPreview,
        reset,
    } = useGetMarketPreview(setOpenPrevisualizeModal);

    React.useEffect(() => {
        reset();
        setAmount(0);
        setQuantity(0);
    }, [buySell]);

    React.useEffect(() => {
        if (tickerPriceInfo) {
            if (tickerPriceInfo?.offers && buySell === 'BUY' && !isManualChange) {
                setLimitPrice(tickerPriceInfo?.offers[0].price);
            } else if (tickerPriceInfo?.bids && buySell === 'SELL')
                setLimitPrice(tickerPriceInfo?.bids[0].price);
        }
    }, [localStorage.getItem('term'), tickerPriceInfo, buySell]);

    const handleChangeLimitPrice = (value: number) => {
        if (quantity && buySell === 'BUY') {
            const priceToMultiply = isBond ? value / 100 : value;
            setAmount(priceToMultiply * quantity);
        }
        setLimitPrice(value);
        setIsMarketPrice(false);
        const selectedArray = buySell === 'BUY' ? tickerPriceInfo?.offers : tickerPriceInfo?.bids;
        selectedArray?.forEach(element => {
            if (element.price === value) {
                setIsMarketPrice(true);
            }
        });
    };

    const handleChangeQuantity = (value: number | null) => {
        setQuantity(value);
        if (value) {
            const priceToMultiply = isBond ? limitPrice / 100 : limitPrice;
            setAmount(value * priceToMultiply);
        }
    };

    const handleChangePrice = (value: number | undefined) => {
        setAmount(value ?? null);
        if (value && limitPrice) {
            const priceToMultiply = isBond ? limitPrice / 100 : limitPrice;

            setQuantity(Math.floor(value / priceToMultiply));
        }
    };

    const getInstrumentByAccountStatus = React.useCallback(
        (instruments: AccountInstrument[]) => {
            let res = 0;
            const instrumentsBySelectedType = instruments.find(
                i => i.instrumentType === instrument?.type,
            );
            const instrumentValues = instrumentsBySelectedType?.instruments.filter(
                i => !!instrument?.tickers.find(t => t.ticker === i.ticker),
            );
            if (!instrumentValues) return 0;
            instrumentValues?.forEach(element => {
                res += element.quantityAvailable;
            });
            return res;
        },
        [instrument],
    );

    const available = React.useMemo(() => {
        if (!accountStatus) return '0';
        const { availableBalance, instruments: accountStatusInstruments } = accountStatus;
        const termBalance = currentTerm === 'CI' ? 't0' : 't1';
        const currencyBalance =
            currentCurrency === 'USD-C' ? 'usdc' : (currentCurrency.toLowerCase() as 'ars' | 'usd');
        if (buySell === 'SELL') {
            const quantityAvailable = getInstrumentByAccountStatus(accountStatusInstruments);
            if (!quantityAvailable) return '0';
            return `${quantityAvailable}u`;
        }
        return formatNumber(
            availableBalance?.operate[termBalance][currencyBalance] ?? 0,
            isBond ? 3 : 2,
        );
    }, [buySell, term, accountStatus, currency, isBond, getInstrumentByAccountStatus]);

    React.useEffect(() => {
        if (limitIndex) {
            setIsMarketPrice(true);
            const selectedIndex = limitIndex ? limitIndex?.value : 0;
            if (buySell === 'SELL' && tickerPriceInfo?.bids) {
                if (tickerPriceInfo?.bids[selectedIndex]?.price)
                    setLimitPrice(tickerPriceInfo?.bids[selectedIndex]?.price);
                else setLimitPrice(tickerPriceInfo?.bids ? tickerPriceInfo?.bids[0]?.price : 0);
            } else if (buySell === 'BUY' && tickerPriceInfo?.offers) {
                if (tickerPriceInfo?.offers[selectedIndex]?.price) {
                    setLimitPrice(tickerPriceInfo?.offers[selectedIndex]?.price);
                    if (limitIndex.isManualChange) setIsManualChange(true);
                } else
                    setLimitPrice(tickerPriceInfo?.offers ? tickerPriceInfo?.offers[0]?.price : 0);
            }
        }
    }, [limitIndex, buySell, isBond]);

    const estimatedPrice = React.useMemo(() => {
        if (!limitPrice || !quantity) return formatNumber(0, isBond ? 3 : 2);
        const valueToMultiply = isBond ? limitPrice / 100 : limitPrice;
        return formatNumber(quantity * valueToMultiply, isBond ? 3 : 2);
    }, [quantity, limitPrice, isBond]);

    const isHigherThanAvailable = React.useMemo(() => {
        if (buySell === 'BUY') return convertToNumber(available) < convertToNumber(estimatedPrice);
        if (!accountStatus) return true;
        const quantityAvailable = getInstrumentByAccountStatus(accountStatus?.instruments);
        if (!quantityAvailable) return true;
        return quantityAvailable < (quantity ?? 0);
    }, [accountStatus, available, buySell, estimatedPrice, quantity, getInstrumentByAccountStatus]);

    const handleOpenPrevisualizeModal = () => {
        const req: GetMarketPreviewRequest = {
            amount: amount !== 0 ? Number(amount) : null,
            buySell,
            customerCode: customerCode ?? '',
            price: limitPrice,
            quantity: quantity !== 0 ? Number(quantity) : null,
            term: currentTerm === 'CI' ? 0 : 1,
            tickerId: instrument?.tickers.filter(t => t.currency === currentCurrency)[0].id ?? 0,
        };
        getMarketPreview(req);
    };

    const handleBuySell = (value: BUYSELL) => {
        setBuySell(value);
        handleChangeLimitPrice(0);
    };

    const hasValidCurrencyAndTerm = React.useCallback(
        (conditions: RuleConditions[]) => {
            const validConditions = conditions.filter(c => {
                const { currencies, terms } = c;
                return currencies.includes(currentCurrency) && terms.includes(currentTerm);
            });
            return validConditions.length > 0;
        },
        [term, currentCurrency],
    );

    const disableButton = React.useMemo(
        () => currency?.name === currentCurrency && term?.name === currentTerm,
        [currency, currentCurrency, term, currentTerm],
    );

    const changeLimitPrice = (value: number, sourceInfo: SourceInfo) => {
        handleChangeLimitPrice(value);
        if (sourceInfo.event && !isManualChange) setIsManualChange(true);
    };

    const childProps = {
        ...props,
        t,
        buySell,
        setBuySell: handleBuySell,
        available,
        limitPrice,
        changeLimitPrice,
        quantity,
        setQuantity: handleChangeQuantity,
        amount,
        setAmount: handleChangePrice,
        estimatedPrice,
        handleOpenPrevisualizeModal,
        isLoadingSubmit,
        errorMessage,
        marketPreview,
        openPrevisualizeModal,
        setOpenPrevisualizeModal,
        isMarketPrice,
        isHigherThanAvailable,
        minimum: instrument && buySell === 'BUY' ? instrument?.minimum : 0,
        errorProfileInfo,
        dontHaveExteriorAccount:
            !selectedCustomer?.hasExteriorAccount &&
            buySell === 'SELL' &&
            currentCurrency === 'USD-C',
        isLoadingProfile:
            isLoadingProfile && (userType === 'APP_LEGAL' || userType === 'APP_PHYSICAL'),
        blockBuyRules:
            buySell === 'BUY'
                ? rules?.filter(
                      r => r.action === 'BLOCK BUY' && hasValidCurrencyAndTerm(r.conditions),
                  )
                : undefined,
        blockSellRules:
            buySell === 'SELL'
                ? rules?.filter(
                      r => r.action === 'BLOCK SELL' && hasValidCurrencyAndTerm(r.conditions),
                  )
                : undefined,
        currency,
        setCurrency,
        term,
        setTerm,
        setCurrentTerm,
        disableButton,
        currentCurrency,
        currentTerm,
    };

    return <InstrumentBuySell {...childProps} />;
};

const propTypes = {
    isBond: PropTypes.bool.isRequired,
    setTerm: PropTypes.func.isRequired,
    setBuySell: PropTypes.func.isRequired,
};

interface extraProps {
    rules: InstrumentRule[] | null;
    tickerPriceInfo?: InstrumentPricesDetail;
    accountStatus?: GetAccountStatusResponse;
    currency: Currency;
    term: 'CI' | '24hs';
    buySell: BUYSELL;
    instrument: GetInstrumentsItem | null;
    currencies: CommonSelectType[] | null;
    limitIndex: LimitPriceStateInfo | null;
    requiresDeclaration: TickerRequieresDeclaration;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
InstrumentBuySellContainer.propTypes = propTypes;

export default InstrumentBuySellContainer;
