import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CustomDatePicker from 'components/common/CustomDatePicker';

const HeaderFilterDate = (props: Props) => {
    const {
        onChange,
        query,
        value,
        label,
        disableFutureDaysFromN,
        disablePastDaysFromN,
        disableYears,
    } = props;

    return (
        <CustomDatePicker
            placeholder={label}
            fieldValue={query}
            setFieldValue={(field, value) => onChange(field, value)}
            value={value as number | string}
            disableFutureDaysFromN={disableFutureDaysFromN}
            disablePastDaysFromN={disablePastDaysFromN}
            disableYears={disableYears}
        />
    );
};

const propTypes = {
    onChange: PropTypes.func.isRequired,
    query: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

interface extraProps {
    value: number | string | null;
    disableFutureDaysFromN?: boolean | string;
    disablePastDaysFromN?: boolean | string;
    disableYears?: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
HeaderFilterDate.propTypes = propTypes;

export default HeaderFilterDate;
