import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './HomePage.styled';
import AccountStatus from 'components/common/AccountStatus';
import LearnToInvestCards from 'components/common/LearnToInvestCards';
import CurrencyQuotes from '../../common/CurrencyQuotes';
import RecommendedWallet from './RecommendedWallet';
import { Grid } from '@mui/material';
import HomeInvestmentProfile from './HomeInvestmentProfile';
import { pxToRem } from 'utils/helpers/stylesHelper';
import DdjjAction from './DDJJAction';
import { GetBankAccountsResponse } from 'types/api/cashflow.types';
import HomeWelcome from './HomeWelcome';
import { GetAccountStatusResponse } from 'types/api/marketAccount.types';

const HomePage = (props: Props) => {
    const {
        recommendedWalletRef,
        instrumentsAmount,
        bankAccounts,
        isLoadingBankAccounts,
        isUserApAc,
        accountStatusResponse,
        isLoadingAccountStatusResponse,
    } = props;
    return (
        <St.SectionWrapper>
            <DdjjAction />
            {!isUserApAc &&
            !isLoadingBankAccounts &&
            instrumentsAmount == 0 &&
            bankAccounts?.total == 0 ? (
                <HomeWelcome />
            ) : (
                <AccountStatus {...{ accountStatusResponse, isLoadingAccountStatusResponse }} />
            )}
            <CurrencyQuotes />
            <LearnToInvestCards />
            <Grid container gap={pxToRem(80)}>
                <Grid item xs={12} lg={4}>
                    <HomeInvestmentProfile />
                </Grid>
                <Grid item xs={12} lg={7}>
                    <div ref={recommendedWalletRef}>
                        <RecommendedWallet />
                    </div>
                </Grid>
            </Grid>
        </St.SectionWrapper>
    );
};

const propTypes = {
    isLoadingBankAccounts: PropTypes.bool.isRequired,
};

interface extraProps {
    recommendedWalletRef: React.MutableRefObject<HTMLDivElement | null>;
    instrumentsAmount: number;
    bankAccounts: GetBankAccountsResponse | undefined;
    isUserApAc: boolean;
    accountStatusResponse?: GetAccountStatusResponse;
    isLoadingAccountStatusResponse: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
HomePage.propTypes = propTypes;

export default HomePage;
