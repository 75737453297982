import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Autocomplete, TextField, debounce } from '@mui/material';

const RequestAutocomplete = ({
    options,
    optionLabel,
    isLoading,
    isError,
    label,
    type,
    selectedValues,
    handleInputChange,
    handleChange,
    inputValue,
    t,
    getNestedValue,
}: Props) => (
    <Autocomplete
        size="small"
        value={selectedValues || (type === 'requestAutocompleteMultiple' ? [] : null)}
        options={options}
        multiple={type === 'requestAutocompleteMultiple'}
        // groupBy={options => options[optionLabel]}
        getOptionLabel={option => (optionLabel ? optionLabel(option) : option.name)}
        loading={isLoading}
        onChange={(_, value) => handleChange(value)}
        onInputChange={debounce(handleInputChange, 2000)}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={params => (
            <TextField
                {...params}
                label={label}
                error={isError}
                helperText={
                    isError ? t('error_generic') : !inputValue && t('autocomplete_helper_text')
                }
            />
        )}
    />
);

const propTypes = {
    t: PropTypes.func.isRequired,
    inputValue: PropTypes.string.isRequired,
    getNestedValue: PropTypes.func.isRequired,
};

interface extraProps {
    options: any[];
    optionLabel?: (option: any) => string;
    isLoading: boolean;
    isError: boolean;
    label: string;
    type: string;
    selectedValues: any | any[];
    handleChange(selectedOptions: any): void;
    handleInputChange(_: any, value: any): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
RequestAutocomplete.propTypes = propTypes;

export default RequestAutocomplete;
