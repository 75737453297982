import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import NoPermissionsPage from './NoPermissionsPage';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { HELP_SEND_EMAIL_ANCHOR } from 'constants/appConstants';
import { useRouteToHome } from 'utils/helpers/routesHelper';

const NoPermissionsPageContainer = (props: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const homeUrl = useRouteToHome();
    const backToHome = () => navigate(homeUrl);
    const goToMail = () => (window.location.href = HELP_SEND_EMAIL_ANCHOR);

    const childProps = {
        ...props,
        t,
        backToHome,
        goToMail,
    };

    return <NoPermissionsPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
NoPermissionsPageContainer.propTypes = propTypes;

export default NoPermissionsPageContainer;
