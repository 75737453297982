import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import DeleteAccountForm from './DeleteAccountForm';
import * as Yup from 'yup';
import { FormikValues, useFormik } from 'formik';
import { tRequiredFieldError } from 'constants/appConstants';
import { useSendRemoveAccountRequest } from 'hooks/api/auth.hooks';
import { AlertColor } from '@mui/material';

const options = [
    {
        id: 1,
        name: 'Yo no quiero invertir',
        value: 'DONT_WANT_TO_INVEST',
    },
    {
        id: 2,
        name: 'Los rendimientos de mis inversiones no resultaron como esperaba',
        value: 'INVESTMENT_PERFORMANCE_NOT_AS_EXPECTED',
    },
    {
        id: 3,
        name: 'No recibí el asesoramiento adecuado',
        value: 'DID_NOT_RECEIVE_PROPER_ADVICE',
    },
    {
        id: 4,
        name: 'Tuve problemas con la aplicación / web',
        value: 'HAD_PROBLEMS_APP_OR_WEB',
    },
    {
        id: 5,
        name: 'Otros',
        value: 'OTHER',
    },
];

// ---------------------------------------------//
// ------------------- FORMIK ------------------//
// ---------------------------------------------//

const getInitialValues = () => ({
    selectedOption: null,
    comment: '',
});

const getValidationSchema = () =>
    Yup.object().shape({
        selectedOption: Yup.object().nullable().required(tRequiredFieldError),
    });

// ---------------------------------------------//
// ---------------------------------------------//
// ---------------------------------------------//

const DeleteAccountFormContainer = (props: Props) => {
    const { setSnackBar } = props;

    const { mutate, isLoading: isLoadingSubmit } = useSendRemoveAccountRequest(setSnackBar);

    const handleSubmit = React.useCallback(async (values: FormikValues) => {
        const body = {
            motive: values.selectedOption.value,
            comment: values.comment,
        };
        mutate(body);
    }, []);

    const formikInitProps = React.useMemo(
        () => ({
            initialValues: getInitialValues(),
            validateOnChange: true,
            validateOnBlur: true,
            validateOnMount: false,
            validationSchema: getValidationSchema(),
            onSubmit: handleSubmit,
        }),
        [handleSubmit],
    );

    const formik = useFormik(formikInitProps);

    const childProps = {
        ...props,
        formik,
        options,
        isLoadingSubmit,
    };

    return <DeleteAccountForm {...childProps} />;
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    hasPendingRequest: boolean;
    setSnackBar: (msj: string, sever?: AlertColor) => void;
    isLoading: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
DeleteAccountFormContainer.propTypes = propTypes;

export default DeleteAccountFormContainer;
