import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './Dropdown.styled';
import { TagType } from 'types/api/instruments.types';

const Dropdown = (props: Props) => {
    const { options, selectedValue, onSelect, label, minWidth, disableClearable } = props;
    return (
        <St.CustomAutoCompleteWrapper $minWidth={minWidth}>
            <St.CustomAutoComplete
                id={label}
                value={
                    selectedValue
                        ? { id: selectedValue.name, name: selectedValue.description }
                        : null
                }
                onChange={(e, value) =>
                    onSelect({
                        description: value.name,
                        name: value.id,
                        order: value.order,
                    })
                }
                options={
                    options
                        .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
                        .map(el => ({ id: el.name, name: el.description, order: el.order })) ?? []
                }
                isLoading={false}
                label={label}
                disableClearable={disableClearable}
                renderOption={(props, option) => (
                    <St.NameWrapper {...props} key={option.id}>
                        {option.name}
                    </St.NameWrapper>
                )}
            />
        </St.CustomAutoCompleteWrapper>
    );
};

const propTypes = {};

interface extraProps {
    options: Array<TagType>;
    selectedValue: TagType | null;
    onSelect: (value: TagType) => void;
    label: string;
    minWidth: string;
    disableClearable?: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
Dropdown.propTypes = propTypes;

export default Dropdown;
