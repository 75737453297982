import { axiosCmsInstance } from 'api/axiosInstances';
import { withFakeData } from 'api/fakedata';
import { CMSGetCategoriesResponse, CMSGetRedirectsResponse } from 'types/api/cms.types';

const apiName = 'cms';

export default {
    getCmsCategoriesByName: () =>
        withFakeData<CMSGetCategoriesResponse>(
            () =>
                axiosCmsInstance.get(
                    `/categories?populate=children&populate=parent&sort=name&pagination[page]=1&pagination[pageSize]=100000`,
                ),
            apiName,
            'cms-categories',
            false,
            500,
        ),
    getCmsCategoriesByDate: () =>
        withFakeData<CMSGetCategoriesResponse>(
            () =>
                axiosCmsInstance.get(
                    `/categories?populate=children&populate=parent&sort=createdAt:desc&pagination[page]=1&pagination[pageSize]=100000`,
                ),
            apiName,
            'cms-categories-by-date',
            false,
            500,
        ),
    getCmsRedirects: () =>
        withFakeData<CMSGetRedirectsResponse>(
            () =>
                axiosCmsInstance.get(
                    `/redirects?populate=category&populate=file&pagination[page]=1&pagination[pageSize]=100000`,
                ),
            apiName,
            'cms-redirects',
            false,
            500,
        ),
};
