import { styled, Box, Typography } from '@mui/material';
import { pxToRem } from 'utils/helpers/stylesHelper';

export default {
    SectionWrapper: styled(Box)(() => ({
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    })),
    Title: styled(Typography)(({ theme }) => ({
        fontSize: '4rem !important',
        textAlign: 'center',
        fontWeight: 'bold',
        color: theme.palette.text.primary,
        marginTop: pxToRem(30),
        marginBottom: pxToRem(20),
    })),
    Subtitle: styled(Typography)(({ theme }) => ({
        fontSize: '2rem !important',
        textAlign: 'center',
        fontWeight: 'medium',
        color: theme.palette.text.secondary,
        marginBottom: pxToRem(20),
    })),
    Message: styled(Typography)(({ theme }) => ({
        fontSize: '1.2rem !important',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom: pxToRem(40),
        padding: '1rem',
    })),
    ActionButtons: styled(Box)(() => ({
        display: 'flex',
        justifyContent: 'center',
    })),
    HomeLink: styled(Box)(({ theme }) => ({
        padding: `${pxToRem(10)} ${pxToRem(20)}`,
        textDecoration: 'none !important',
        width: pxToRem(150),
        textAlign: 'center',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        borderRadius: '3rem',
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
            cursor: 'pointer',
        },
    })),
};
