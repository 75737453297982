import * as React from 'react';
import * as Yup from 'yup';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { FormikConfig, FormikValues, useFormik } from 'formik';
import OrderForm from './OrderForm';
import { useTranslation } from 'react-i18next';
import i18n from 'utils/i18n';
import { tCommon, tErrorsContext, tRequiredFieldError } from 'constants/appConstants';
import { useCreateCollateralOrder, useGetCollateralsAvailable } from 'hooks/api/orders.hooks';
import { useGetHolidayForCollaterals } from 'hooks/api/dates.hooks';
import { AuthContext } from 'context/auth.context';
import { OrderFormCurrencyType } from 'types/api/marketAccount.types';
import { formatNumber } from 'utils/helpers/commonHelper';
import { CreateCollateralOrderRequest } from 'types/api/orders.types';
import { AlertColor } from '@mui/material';
import moment from 'moment';

// ---------------------------------------------//
// ------------------- FORMIK ------------------//
// ---------------------------------------------//

const getInitialValues = () => ({
    email: '',
    isMarketRate: true,
    term: '1',
    date: '',
    amount: '0',
    percentage: '0',
    calculatedAmount: '0',
    tickers: {},
});

const getValidationSchema = (operationType: string | undefined, availableOperate: number) =>
    Yup.lazy(() =>
        Yup.object().shape({
            amount: Yup.number()
                .required(tRequiredFieldError)
                .test(
                    'invalid-amount',
                    String(i18n.t('invalid_amount', tErrorsContext)),
                    function (value = 0) {
                        return value != 0;
                    },
                )
                .test(
                    'is-greater-than-calculated',
                    String(
                        i18n.t(
                            operationType === 't'
                                ? 'lower_calculated_amount'
                                : 'error_amount_available',
                            tErrorsContext,
                        ),
                    ),
                    function (value = 0) {
                        const { calculatedAmount } = this.parent;
                        return operationType === 't'
                            ? parseFloat(calculatedAmount) >= value
                            : availableOperate >= value;
                    },
                ),
            tickers: Yup.object().test(
                'no-error-in-tickers',
                String(i18n.t('there_are_instruments_with_errors', tErrorsContext)),
                function (tickers) {
                    if (tickers && Object.keys(tickers).length > 0) {
                        for (let key in tickers) {
                            const ticker: any = tickers[key];
                            if (ticker.error === true) {
                                return false;
                            }
                        }
                    }
                    return true;
                },
            ),
        }),
    );

// ---------------------------------------------//
// ---------------------------------------------//
// ---------------------------------------------//

const OrderFormContainer = (props: Props) => {
    const { close, setSnackBarMessage } = props;
    const { t } = useTranslation('market');
    const tBase = 'market_collaterals_';
    const { selectedCustomer } = React.useContext(AuthContext);
    const { data, isLoading } = useGetHolidayForCollaterals();
    const { data: collateralsAvailable, isLoading: isLoadingCollateralsAvailable } =
        useGetCollateralsAvailable();
    const currentDate = new Date();
    const aMonth = moment().add(180, 'days').format('YYYY-MM-DD');

    const autocompleteOptions = [
        { id: 't', name: t(`${tBase}order_taker`) },
        { id: 'c', name: t(`${tBase}order_placer`) },
    ];
    const [operationType, setOperationType] = React.useState<{ id: string; name: string } | null>(
        null,
    );

    const currencies: Array<OrderFormCurrencyType> = [
        { id: 'ars', name: t('ars', tCommon) },
        { id: 'usd', name: t('usd', tCommon) },
        { id: 'usdc', name: 'USD-C' },
    ];
    const [currency, setCurrency] = React.useState<OrderFormCurrencyType>(currencies[0]);

    const {
        mutate,
        isLoading: isLoadingSubmit,
        errorMessage,
    } = useCreateCollateralOrder(setSnackBarMessage, close);

    const handleSubmit = React.useCallback(
        async (values: FormikValues) => {
            const { isMarketRate, percentage, amount, term, tickers } = values;
            if (!operationType) return;
            const formData = {
                customerCode: selectedCustomer?.customerCode || '',
                buySell: operationType.id === 't' ? 'SELL' : 'BUY',
                amount,
                userUuid: selectedCustomer?.uuid || '',
                isMarketRate,
                rate: percentage,
                currency: currency.name,
                term,
                isManual: true,
                collaterals:
                    operationType.id === 't'
                        ? Object.values(tickers)
                              .map((ticket: any) => ({
                                  tickerId: ticket.id,
                                  quantity: ticket.warranty,
                                  price: ticket.aforadoPrice,
                              }))
                              .filter(el => ![0, '0'].includes(el.quantity))
                        : null,
            } as CreateCollateralOrderRequest;
            mutate(formData);
        },
        [operationType, selectedCustomer, currency, currentDate],
    );

    const formikInitProps = React.useMemo(
        () =>
            ({
                initialValues: getInitialValues(),
                validateOnChange: false,
                validateOnBlur: false,
                validateOnMount: false,
                validationSchema: getValidationSchema(
                    operationType?.id,
                    selectedCustomer?.[`available_operate_${currency.id}_term_0`] || 0,
                ),
                enableReinitialize: true,
                onSubmit: handleSubmit,
            }) as FormikConfig<FormikValues>,
        [handleSubmit, operationType?.id, selectedCustomer],
    );

    const formik = useFormik(formikInitProps);

    const isHoliday = (date: string) => data?.items.includes(date);

    const isWeekend = (timestamp: number) => {
        const date = new Date(timestamp);
        const day = date.getDay();
        return [0, 6].includes(day);
    };

    const getValidDate = () => {
        let date = Date.now() + 24 * 60 * 60 * 1000;

        while (isWeekend(date) || isHoliday(new Date(date).toISOString().split('T')[0])) {
            date += 24 * 60 * 60 * 1000;
        }
        return date;
    };

    const handleChangeTerm = (date: number) => {
        const selectedDate = new Date(date);
        const differenceBetweenMilliseconds = currentDate.getTime() - selectedDate.getTime();
        const term = Math.floor(differenceBetweenMilliseconds / (1000 * 60 * 60 * 24));
        formik.setFieldValue('term', Math.abs(term));
    };

    const addDefaultDate = () => {
        const validDate = getValidDate();
        const forTerm = new Date(validDate).toISOString().split('T')[0];
        formik.setFieldValue('date', validDate);
        handleChangeTerm(new Date(forTerm).getTime());
    };

    const handleChangeAutocomplete = (val: { id: string; name: string }) => {
        formik.resetForm();
        setOperationType(val);
        addDefaultDate();
    };
    const handleCurrencyChange = (currency: OrderFormCurrencyType) => {
        formik.setFieldValue('amount', 0);
        setCurrency(currency);
    };

    const availableOperate = formatNumber(
        selectedCustomer?.[`available_operate_${currency.id}_term_0`] || 0,
        2,
        true,
    );

    React.useEffect(() => {
        addDefaultDate();
    }, [data]);

    const childProps = {
        ...props,
        t,
        isLoading: isLoading || isLoadingCollateralsAvailable,
        isLoadingSubmit,
        formik,
        errorMessage,
        tBase,
        operationType,
        autocompleteOptions,
        handleChangeAutocomplete,
        currency,
        currencies,
        handleCurrencyChange,
        availableOperate,
        handleChangeTerm,
        insufficientBalance: !availableOperate || availableOperate === '0,00',
        disabledDates: data?.items ?? [],
        collateralsAvailable,
        aMonth,
    };

    return <OrderForm {...childProps} />;
};

const propTypes = {};

interface extraProps {
    close: () => void;
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OrderFormContainer.propTypes = propTypes;

export default OrderFormContainer;
