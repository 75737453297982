import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { CommonColumn, CommonFilter } from 'types/common/tableLayout.types';
import { ReportTicket } from 'types/pages/reports.types';
import TableLayout from 'components/layouts/TableLayout';
import { useGetReportTickets } from 'hooks/api/marketAccount.hooks';
import { tCommon } from 'constants/appConstants';
import { ExtraQueryFiltersProps } from 'types/common/CommonGrid/CommonGridFormModal.types';
import { today } from 'utils/helpers/dateHelper';

const ReportsTicketsPage = ({
    columns,
    filters,
    defaultFilters,
    customerCode,
    filterDateValidation,
    t,
}: Props) => (
    <TableLayout
        {...{
            title: t('layout_main_navigation_reports_tickets', tCommon),
            exportTitle: `${customerCode || 'Comitente'} - ${today}`,
            filters,
            defaultFilters,
            columns,
            fetchData: useGetReportTickets,
            customFilterValidation: filterDateValidation,
        }}
        canExport
    />
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    columns: CommonColumn<ReportTicket>[];
    customerCode: string | null;
    filters: CommonFilter[];
    defaultFilters: ExtraQueryFiltersProps[];
    filterDateValidation: (value: string) => { result: boolean; error: string | undefined };
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ReportsTicketsPage.propTypes = propTypes;

export default ReportsTicketsPage;
