import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CustomIconCreator from 'components/common/CustomIconCreator';
import CloseIcon from 'assets/icons/close-line.svg?react';
import { VALID_VIDEO_EXTENSIONS } from 'constants/appConstants';

const VideoModal = (props: Props) => {
    const { open, close, url, title } = props;
    return (
        <Dialog maxWidth="xl" open={open} onClose={close}>
            <DialogTitle>
                {title}
                <IconButton
                    aria-label="close"
                    onClick={close}
                    sx={{
                        color: theme => theme.palette.grey[500],
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}>
                    <CustomIconCreator icon={CloseIcon} alt="close-icon" />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <video src={url} id={url} width="500" height="auto" controls>
                    {VALID_VIDEO_EXTENSIONS.map((m, index) => {
                        return <source key={index} src={m} type={`video/${m}`} />;
                    })}
                </video>
            </DialogContent>
        </Dialog>
    );
};

const propTypes = {
    title: PropTypes.string.isRequired,
};

interface extraProps {
    open: boolean;
    url: string;
    close: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
VideoModal.propTypes = propTypes;

export default VideoModal;
