import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CommercialItemButton from './CommercialItemButton';
import { CMSCategoryItem, CMSRedirects } from 'types/api/cms.types';
import { download } from 'utils/helpers/files.helper';
import { useNavigate } from 'react-router-dom';
import { V_CMS_URL } from 'constants/envVarConstants';
import { VALID_VIDEO_EXTENSIONS } from 'constants/appConstants';

const CommercialItemButtonContainer = (props: Props) => {
    const { id, redirects, subcategories, name } = props;
    const [videoUrl, setVideoUrl] = React.useState<string | null>(null);
    const navigate = useNavigate();

    const handleSubmit = React.useCallback(() => {
        if (subcategories?.length) {
            navigate(`/commercial/${id}`);
            return;
        }
        const redirect = redirects?.find(r => r.category.name === name);
        if (!redirect) return;
        if (redirect.file) {
            if (!VALID_VIDEO_EXTENSIONS.includes(redirect.file.ext))
                return download(`${V_CMS_URL}${redirect.file?.url}`);
            setVideoUrl(`${V_CMS_URL}${redirect.file?.url}`);
            return;
        }
        if (redirect.url) window.open(redirect.url, '_blank');
    }, [redirects, name, subcategories, id, navigate]);

    const childProps = {
        ...props,
        handleSubmit,
        videoUrl,
        setVideoUrl,
    };

    return <CommercialItemButton {...childProps} />;
};

const propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
};

interface extraProps {
    redirects?: CMSRedirects[];
    subcategories: CMSCategoryItem[] | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CommercialItemButtonContainer.propTypes = propTypes;

export default CommercialItemButtonContainer;
